import React from 'react';
import * as MUI from '@material-ui/core';

import { ProductPrices } from './ResellersPortal';
import SkatterOrderForm from './SkatterOrderForm';
import SkatterUpgradeOrderForm from './SkatterUpgradeOrderForm';
import TransmutrArtistOrderForm from './TransmutrArtistOrderForm';
import TransmutrStudioOrderForm from './TransmutrStudioOrderForm';
import { Product, NotifyFunction } from '../Common/utils';

interface Props {
  loading: boolean;
  jwt: string;
  territory: string;
  currency: string;
  productPrices: ProductPrices;
  discountList: { [key: number]: number };
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Order(props: Props) {
  const [productTab, setProductTab] = React.useState('skatter2');

  return (
    <MUI.Container maxWidth='md'>
      <MUI.Typography variant='h6'>
        You are allowed to resell Lindalë products in this territory only: <b>{props.territory}</b>
      </MUI.Typography>

      <MUI.Box mt='32px'>
        <MUI.Tabs value={productTab} onChange={(e, value) => setProductTab(value)}>
          <MUI.Tab label='Skatter v2' value={Product.SKATTER2} />
          <MUI.Tab label='Skatter v1 to v2 Upgrade' value={Product.SKATTER2_UPGRADE} />
          <MUI.Tab label='Transmutr Artist' value={Product.TRANSMUTR_ARTIST} />
          <MUI.Tab label='Transmutr Studio' value={Product.TRANSMUTR_STUDIO} />
        </MUI.Tabs>

        {productTab === Product.SKATTER2 && (
          <SkatterOrderForm
            loading={props.loading}
            jwt={props.jwt}
            currency={props.currency}
            prices={props.productPrices[Product.SKATTER2]}
            discountList={props.discountList}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}

        {productTab === Product.SKATTER2_UPGRADE && (
          <SkatterUpgradeOrderForm
            loading={props.loading}
            jwt={props.jwt}
            currency={props.currency}
            prices={props.productPrices[Product.SKATTER2_UPGRADE]}
            recurringPrices={props.productPrices[Product.SKATTER2]}
            discountList={props.discountList}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}

        {productTab === Product.TRANSMUTR_ARTIST && (
          <TransmutrArtistOrderForm
            loading={props.loading}
            jwt={props.jwt}
            currency={props.currency}
            prices={props.productPrices[Product.TRANSMUTR_ARTIST]}
            discountList={props.discountList}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}

        {productTab === Product.TRANSMUTR_STUDIO && (
          <TransmutrStudioOrderForm
            loading={props.loading}
            jwt={props.jwt}
            currency={props.currency}
            prices={props.productPrices[Product.TRANSMUTR_STUDIO]}
            discountList={props.discountList}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
      </MUI.Box>
    </MUI.Container>
  );
}
