import axios, { AxiosResponse } from 'axios';
import { OptionsObject, SnackbarKey } from 'notistack';
import React from 'react';
import dayjs from 'dayjs';

export enum NotifVariant {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export type NotifyFunction = (
  message: string,
  variant: NotifVariant,
  options?: OptionsObject
) => SnackbarKey;

export enum Product {
  SKATTER1 = 'skatter1',
  SKATTER2 = 'skatter2',
  SKATTER2_UPGRADE = 'skatter2upgrade',
  TRANSMUTR_ARTIST = 'transmutrArtist',
  TRANSMUTR_STUDIO = 'transmutrStudio'
}

export const productNames = {
  [Product.SKATTER1]: 'Skatter v1',
  [Product.SKATTER2]: 'Skatter v2',
  [Product.SKATTER2_UPGRADE]: 'Skatter v1 to v2 upgrade',
  [Product.TRANSMUTR_ARTIST]: 'Transmutr Artist',
  [Product.TRANSMUTR_STUDIO]: 'Transmutr Studio'
};

export enum LicenseType {
  NODELOCKED = 'nodelocked',
  FLOATING = 'floating'
}

export const licenseTypeNames = {
  [LicenseType.NODELOCKED]: 'Fixed-seat',
  [LicenseType.FLOATING]: 'Floating'
};

export enum LicenseStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
  SUSPENDED = 'suspended'
}

export const licenseStatusNames = {
  [LicenseStatus.ACTIVE]: 'Active',
  [LicenseStatus.EXPIRED]: 'Expired',
  [LicenseStatus.REVOKED]: 'Revoked',
  [LicenseStatus.SUSPENDED]: 'Suspended'
};

export const apiBaseUrl = 'https://api.lindale.io/';

let webApiCallIndex = 0; // Index to track API requests & responses

export function webApiCall({
  method,
  endpoint,
  jwt,
  data,
  suppressWebApiErrors,
  notify,
  setMaintenanceMode,
  successCallback,
  errorCallback,
  ensureCallback
}: {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete';
  endpoint: string;
  jwt?: string;
  data?: any;
  suppressWebApiErrors?: boolean;
  notify: NotifyFunction;
  setMaintenanceMode: (maintenanceMode: boolean) => void;
  successCallback: (response: AxiosResponse<any>, message?: string) => void;
  errorCallback?: (response: AxiosResponse<any>, message?: string) => void;
  ensureCallback?: () => void;
}) {
  const callIndex = webApiCallIndex++;

  console.log(`web API call ${callIndex}:`, endpoint, data);

  if (data) {
    if (data instanceof FormData) {
      data.append('fromApp', 'website');
    } else {
      data['fromApp'] = 'website';
    }
  }

  axios({
    method: method,
    url: apiBaseUrl + endpoint,
    headers: jwt ? { Authorization: 'Bearer ' + jwt } : undefined,
    data: data
  })
    .then((response) => {
      console.log(`web API response ${callIndex}:`, response);

      if (ensureCallback) {
        ensureCallback();
      }

      if (response.data?.status === 'success') {
        const successMessage =
          response.data.message || (response.data.data && response.data.data.message);
        successCallback(response, successMessage);
      } else {
        if (errorCallback) {
          const errorMessage =
            response.data.message || (response.data.data && response.data.data.message);
          errorCallback(response, errorMessage);
        }

        if (!suppressWebApiErrors) {
          printWebApiError(response, notify);
        }
      }
    })
    .catch((error) => {
      if (ensureCallback) {
        ensureCallback();
      }

      const response = error.response;

      if (response) {
        if (response.status === 503) {
          setMaintenanceMode(true);
        } else {
          if (errorCallback) {
            const errorMessage =
              response.data.message || (response.data.data && response.data.data.message);
            errorCallback(error.response, errorMessage);
          }

          if (!suppressWebApiErrors) {
            printWebApiError(response, notify);
          }
        }
      } else {
        console.error(error);
        notify(error, NotifVariant.ERROR);
      }
    });
}

export function printWebApiError(response: AxiosResponse, notify: NotifyFunction) {
  if (response.data.message) {
    console.error(response.data.message);
    notify(response.data.message, NotifVariant.ERROR);
  } else if (response.data.data) {
    if (response.data.data.message) {
      console.error(response.data.data.message);
      notify(response.data.data.message, NotifVariant.ERROR);
    } else {
      console.error(response.data.data);
      notify(response.data.data, NotifVariant.ERROR);
    }
  } else {
    console.error(response.data);
    notify(response.data, NotifVariant.ERROR);
  }
}

export function parseJwt(jwt: string) {
  return JSON.parse(atob(jwt.split('.')[1]));
}

// Triggers downloading the given URL
export function downloadUrl(url: string) {
  const a = document.createElement('a');
  a.href = url;
  a.target = 'blank';

  const fileName = url.split('/').pop();
  if (fileName) {
    a.download = fileName;
  }

  document.body.appendChild(a);
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
}

export function setCookie(name: string, value: string, topDomain = false) {
  let d = new Date();
  d.setTime(d.getTime() + 10 * 365 * 24 * 60 * 60 * 1000); // 10 years
  const expires = d.toUTCString();
  const domain = topDomain
    ? window.location.hostname.split('.').slice(-2).join('.')
    : window.location.hostname;
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; domain=${domain}; path=/`;
}

export function getCookie(name: string) {
  name = name + '=';
  const allCookies = decodeURIComponent(document.cookie);
  const cookieArray = allCookies.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

export function deleteCookie(name: string, topDomain = false) {
  const domain = topDomain
    ? window.location.hostname.split('.').slice(-2).join('.')
    : window.location.hostname;
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
}

// React hook that scrolls to the top when the page location changes
export function useScrollToTop(pathname: string) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

export function toISODate(date: string | number | Date) {
  return dayjs(date).format('YYYY-MM-DD');
}
