import React from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SnackbarProvider, useSnackbar, OptionsObject, SnackbarKey } from 'notistack';
import useInterval from 'use-interval';
import SimpleReactLightbox from 'simple-react-lightbox';

import * as MUI from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Account from './Components/Account';
import Bazaar from './Components/Bazaar';
import Skatter from './Components/Skatter';
import Transmutr from './Components/Transmutr';
import Support from './Components/Support';
import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import Resellers from './Components/Resellers';
import VendorDashboard from './Components/VendorDashboard';
import Feedback from './Components/Feedback';
import {
  NotifVariant,
  parseJwt,
  webApiCall,
  setCookie,
  getCookie,
  deleteCookie
} from './Components/Common/utils';

import '@fontsource/roboto';
import RoomBox from './Components/RoomBox';
import Students from './Components/Common/Students';

const theme = MUI.createTheme({
  palette: {
    primary: {
      light: '#404040',
      main: '#111111',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#404040',
      main: '#111111',
      dark: '#000000',
      contrastText: '#ffffff'
    }
  }
});

function LindaleWebsite() {
  const [maintenanceMode, setMaintenanceMode] = React.useState(false);

  const location = useLocation();

  // Google Analytics
  React.useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash
    });
  }, [location]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = React.useCallback(
    (message: any, variant: NotifVariant, options: OptionsObject = {}) => {
      // Try to extract a meaningful text message from the passed message object

      let text: string;

      if (typeof message === 'string') {
        // string = easy case
        text = message;
      } else if (message['message']) {
        // Some errors are stored in the 'message' field
        text = message['message'];
      } // Last resort: stringify object
      else {
        text = JSON.stringify(message);
      }

      return enqueueSnackbar(text, {
        variant: variant,
        action: (key: SnackbarKey) => (
          <MUI.IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon />
          </MUI.IconButton>
        ),
        persist: variant === NotifVariant.ERROR || variant === NotifVariant.WARNING,
        ...options
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const [jwt, setJwt] = React.useState<string>();
  const [isRefreshingJwt, setIsRefreshingJwt] = React.useState(false);

  const saveRefreshToken = React.useCallback(
    (refreshToken: string | undefined, jwt: string | undefined) => {
      if (refreshToken) {
        setCookie('refreshToken', refreshToken, true);
      } else {
        // Revoke the refresh token on the server.
        // We don't wait for a response, as it's only a notification.
        webApiCall({
          method: 'post',
          endpoint: 'auth/v1/logout',
          jwt: jwt,
          data: { refreshToken: getCookie('refreshToken') },
          notify,
          setMaintenanceMode: setMaintenanceMode,
          successCallback: (response) => {}
        });

        deleteCookie('refreshToken');
      }
    },
    [notify]
  );

  const refreshJwt = React.useCallback(
    (isRefreshingJwt: boolean) => {
      const refreshToken = getCookie('refreshToken');

      if (!isRefreshingJwt && refreshToken) {
        setIsRefreshingJwt(true);

        webApiCall({
          method: 'post',
          endpoint: 'auth/v1/refreshtoken',
          data: { refreshToken: refreshToken },
          notify,
          setMaintenanceMode,
          successCallback: (response) => {
            setIsRefreshingJwt(false);
            setJwt(response.data.data.jwt);
            saveRefreshToken(response.data.data.refreshToken, jwt);
          },
          errorCallback: (response) => {
            setIsRefreshingJwt(false);
            setJwt(undefined);

            // 401 = invalid token
            if (response.status === 401) {
              deleteCookie('refreshToken');
            }
          }
        });
      }
    },
    [notify, saveRefreshToken] // Do not add 'jwt' despite the warnings. It creates an endless loop. We need to properly redo the whole thing.
  );

  // Fetch a fresh jwt on mount
  React.useEffect(() => {
    refreshJwt(isRefreshingJwt);
  }, [notify, refreshJwt]); // Do not add 'isRefreshingJwt' despite the warnings. It creates an endless loop. We need to properly redo the whole thing.

  // Refresh jwt when it's close to expire
  useInterval(() => {
    if (jwt) {
      const exp = parseJwt(jwt).exp;
      if (exp - 2 * 60 < Math.floor(Date.now() / 1000)) {
        // 2 minutes before expiry
        refreshJwt(isRefreshingJwt);
      }
    }
  }, 1000);

  return (
    <MUI.Box height='100%' display='flex' flexDirection='column'>
      <MUI.CssBaseline />

      <Helmet>
        <title>Lindalë</title>
      </Helmet>

      <Header />

      <MUI.Box flex={1}>
        <Switch>
          <Route path='/skatter'>
            <Skatter notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route path='/transmutr'>
            <Transmutr notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route path='/roombox'>
            <RoomBox notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route path='/3dbazaar'>
            <Bazaar notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route path='/support'>
            <Support notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route path='/account'>
            <Account
              jwt={jwt}
              setJwt={setJwt}
              saveRefreshToken={(refreshToken: string | undefined) =>
                saveRefreshToken(refreshToken, jwt)
              }
              notify={notify}
              setMaintenanceMode={setMaintenanceMode}
            />
          </Route>

          <Route path='/resellers'>
            <Resellers jwt={jwt} notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route path='/students'>
            <Students notify={notify} setMaintenanceMode={setMaintenanceMode} />
          </Route>

          <Route
            path={[
              '/vendordashboard',
              '/bazaarvendordashboard', // Legacy
              '/marketplacedashboard' // Legacy
            ]}
          >
            <VendorDashboard
              jwt={jwt}
              refreshJwt={refreshJwt}
              notify={notify}
              setMaintenanceMode={setMaintenanceMode}
            />
          </Route>

          <Route path='/feedback'>
            <Feedback />
          </Route>

          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </MUI.Box>

      <Footer />
    </MUI.Box>
  );
}

export default function WrappedLindaleWebsite() {
  return (
    <Router>
      <MUI.MuiThemeProvider theme={theme}>
        <SnackbarProvider>
          {' '}
          {/* SnackbarProvider must be a child of MuiThemeProvider */}
          <SimpleReactLightbox>
            <LindaleWebsite />
          </SimpleReactLightbox>
        </SnackbarProvider>
      </MUI.MuiThemeProvider>
    </Router>
  );
}
