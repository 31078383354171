import React from 'react';
import * as MUI from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { NotifyFunction, webApiCall, Product } from './utils';

import lindaleLogoCircle from '../../media/logos/lindale_logo_circle.svg';

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Students(props: Props) {
  const [product, setProduct] = React.useState<Product | ''>('');
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [school, setSchool] = React.useState('');
  const [idFile, setIdFile] = React.useState<File | undefined>(undefined);
  const [studentIdFile, setStudentIdFile] = React.useState<File | undefined>(undefined);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [waiting, setWaiting] = React.useState(false);

  const handleIdFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdFile(event.target.files?.[0]);
  };
  const handleStudentIdFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudentIdFile(event.target.files?.[0]);
  };

  const submit = React.useCallback(() => {
    setWaiting(true);
    setErrors({});
    setSuccessMessage('');

    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        product: product ?? '',
        email: email,
        name: name,
        school: school
      })
    );
    formData.append('idFile', idFile ?? '');
    formData.append('studentIdFile', studentIdFile ?? '');

    webApiCall({
      method: 'post',
      endpoint: 'licenses/v1/educational/request',
      data: formData,
      suppressWebApiErrors: true,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        setSuccessMessage(response.data?.data?.message);
      },
      errorCallback: (response, message) => {
        if (message) {
          setErrors({ message: message });
        } else if (response.data?.data) {
          setErrors(response.data?.data);
        }
      },
      ensureCallback: () => {
        setWaiting(false);
      }
    });
  }, [product, email, name, school, idFile, studentIdFile, props.notify, props.setMaintenanceMode]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const formIsSubmittable = !preventFormSubmit();
    if (e.key === 'Enter' && formIsSubmittable) {
      submit();
    }
  };

  const preventFormSubmit = () => {
    return (
      waiting ||
      !product ||
      email === '' ||
      name === '' ||
      school === '' ||
      !idFile ||
      !studentIdFile
    );
  };

  return (
    <MUI.Box pb={8}>
      <MUI.Container
        maxWidth='xs'
        style={{
          marginTop: '64px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <MUI.Avatar
          src={lindaleLogoCircle}
          style={{
            margin: '8px',
            height: '64px',
            width: '64px'
          }}
        />

        <MUI.Typography component='h1' variant='h5'>
          Students
        </MUI.Typography>

        <MUI.Typography variant='body1' style={{ marginTop: '24px' }}>
          If you are a student currently enroled in a university or a similar institution, you can
          apply for a student license using the form below.
          <br />
          <br />
          A Student License is a full-featured license valid for one year. It can be installed on
          both your laptop and desktop computers.
          <br />
          <br />
          It costs only 5€ for a full year, instead of the regular 79€.
          <br />
          <br />
          Student Licenses cannot be used for any kind of commercial work.
          <br />
          <br />
          You need to provide proof that you are currently a student (student ID, faculty ID, or
          similar document)
          <br />
          <br />
        </MUI.Typography>

        <form noValidate onKeyDown={onKeyDown}>
          {'message' in errors && (
            <Alert severity='error' style={{ marginBottom: '16px' }}>
              {errors.message}
            </Alert>
          )}

          {successMessage !== '' ? (
            <Alert severity='success' style={{ marginBottom: '16px' }}>
              {successMessage}
            </Alert>
          ) : (
            <>
              <MUI.TextField
                select
                onChange={(e) => setProduct(e.target.value as unknown as Product)}
                value={product}
                error={'product' in errors}
                helperText={errors.product}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='product'
                label='Product'
                name='product'
              >
                <MUI.MenuItem value=''>{'-'}</MUI.MenuItem>
                <MUI.MenuItem value={Product.SKATTER2}>Skatter</MUI.MenuItem>
                <MUI.MenuItem value={Product.TRANSMUTR_ARTIST}>Transmutr</MUI.MenuItem>
              </MUI.TextField>
              <MUI.TextField
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                error={'email' in errors}
                helperText={errors.email}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
              />
              <MUI.TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                error={'name' in errors}
                helperText={errors.name}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='name'
                label='Name'
                name='name'
                autoComplete='name'
              />
              <MUI.TextField
                onChange={(e) => setSchool(e.target.value)}
                value={school}
                error={'school' in errors}
                helperText={errors.school}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='school'
                label='Institution/School name'
                name='school'
                autoComplete='school'
              />
              <MUI.TextField
                type='file'
                onChange={handleIdFileSelect}
                error={'idFile' in errors}
                helperText={errors.idFile}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='idFile'
                label='ID (ID Card, Passport, etc)'
                name='idFile'
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: '.jpg,.png,.tif,.tiff,.gif,.pdf' }}
              />
              <MUI.TextField
                type='file'
                onChange={handleStudentIdFileSelect}
                error={'studentIdFile' in errors}
                helperText={errors.studentIdFile}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='studentIdFile'
                label='Student ID (Student Card, Enrollement Contract, etc)'
                name='studentIdFile'
                InputLabelProps={{ shrink: true }}
                inputProps={{ accept: '.jpg,.png,.tif,.tiff,.gif,.pdf' }}
              />

              <MUI.Box margin='24px 0' position='relative'>
                <MUI.Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    submit();
                  }}
                  disabled={preventFormSubmit()}
                >
                  Submit
                </MUI.Button>
                {waiting && (
                  <MUI.CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12
                    }}
                  />
                )}
              </MUI.Box>
            </>
          )}
        </form>
      </MUI.Container>
    </MUI.Box>
  );
}
