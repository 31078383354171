import React from 'react';
import * as MUI from '@material-ui/core';

import { Product, NotifyFunction, webApiCall, parseJwt } from '../Common/utils';

interface Props {
  loading: boolean;
  jwt: string;
  licenseKey: string;
  onClose: () => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function LicenseDetails(props: Props) {
  const [license, setLicense] = React.useState<any | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  const fetchLicense = React.useCallback(() => {
    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `resellers/v1/byuserid/${jwtUserId}/licenses/${props.licenseKey}`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setLicense(response.data.data.license);
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt]);

  const handleResetActivations = () => {
    if (props.jwt) {
      setLoading(true);
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `resellers/v1/byuserid/${jwtUserId}/licenses/${props.licenseKey}/reset`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          fetchLicense();
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  };

  React.useEffect(() => {
    fetchLicense();
  }, [fetchLicense]);

  return (
    <MUI.Dialog maxWidth='lg' fullWidth open onClose={props.onClose}>
      {props.loading || loading || !license ? (
        <MUI.CircularProgress />
      ) : (
        <>
          <MUI.DialogTitle>License details</MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.DialogContentText>
              <MUI.Table size='small'>
                <MUI.TableBody>
                  <MUI.TableRow>
                    <MUI.TableCell>Key</MUI.TableCell>
                    <MUI.TableCell>{license.key}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Product</MUI.TableCell>
                    <MUI.TableCell>{license.product}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Status</MUI.TableCell>
                    <MUI.TableCell>{license.status}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Type</MUI.TableCell>
                    <MUI.TableCell>{license.type}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Activations</MUI.TableCell>
                    <MUI.TableCell>
                      {license.product === Product.SKATTER1 ? (
                        <i>
                          Skatter v1 license activations are not available here. Contact us at
                          contact@lindale.io
                        </i>
                      ) : (
                        <>
                          {license.activations} / {license.seats} |{' '}
                          <a href='#' onClick={handleResetActivations}>
                            Reset the license activations
                          </a>
                        </>
                      )}
                    </MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Purchase Date</MUI.TableCell>
                    <MUI.TableCell>
                      {new Date(license.creationDate).toISOString().slice(0, 10)}
                    </MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Expiration Date</MUI.TableCell>
                    <MUI.TableCell>
                      {license.expirationDate && !license.expirationDate.startsWith('0000')
                        ? new Date(license.expirationDate).toISOString().slice(0, 10)
                        : '-'}
                    </MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Email</MUI.TableCell>
                    <MUI.TableCell>{license.email}</MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableBody>
              </MUI.Table>

              {license.subscriptionStatus && (
                <>
                  <MUI.Typography variant='h6' style={{ marginTop: '32px' }}>
                    Subscription
                  </MUI.Typography>
                  <MUI.Table size='small'>
                    <MUI.TableBody>
                      <MUI.TableRow>
                        <MUI.TableCell>Subscription status</MUI.TableCell>
                        <MUI.TableCell>{license.subscriptionStatus}</MUI.TableCell>
                      </MUI.TableRow>
                      <MUI.TableRow>
                        <MUI.TableCell>Last payment</MUI.TableCell>
                        <MUI.TableCell>
                          {license.subscriptionLastPayment && (
                            <>
                              {new Date(license.subscriptionLastPayment.date)
                                .toISOString()
                                .slice(0, 10)}{' '}
                              ({license.subscriptionLastPayment.amount}{' '}
                              {license.subscriptionLastPayment.currency})
                            </>
                          )}
                        </MUI.TableCell>
                      </MUI.TableRow>
                      <MUI.TableRow>
                        <MUI.TableCell>Next payment</MUI.TableCell>
                        <MUI.TableCell>
                          {license.subscriptionNextPayment && (
                            <>
                              {new Date(license.subscriptionNextPayment.date)
                                .toISOString()
                                .slice(0, 10)}{' '}
                              ({license.subscriptionNextPayment.amount}{' '}
                              {license.subscriptionNextPayment.currency})
                            </>
                          )}
                        </MUI.TableCell>
                      </MUI.TableRow>
                      <MUI.TableRow>
                        <MUI.TableCell>Payment method</MUI.TableCell>
                        <MUI.TableCell>
                          <a target='_blank' href={license.subscriptionUpdateUrl}>
                            Change the payment method
                          </a>
                        </MUI.TableCell>
                      </MUI.TableRow>
                      <MUI.TableRow>
                        <MUI.TableCell>Cancel subscription</MUI.TableCell>
                        <MUI.TableCell>
                          <a
                            target='_blank'
                            href={license.subscriptionCancelUrl}
                            style={{ color: 'red' }}
                          >
                            Cancel the subscription
                          </a>{' '}
                          (the license will expire after{' '}
                          {new Date(license.expirationDate).toISOString().slice(0, 10)})
                        </MUI.TableCell>
                      </MUI.TableRow>
                    </MUI.TableBody>
                  </MUI.Table>
                </>
              )}
            </MUI.DialogContentText>
          </MUI.DialogContent>
          <MUI.DialogActions>
            <MUI.Button onClick={props.onClose} color='primary'>
              Close
            </MUI.Button>
          </MUI.DialogActions>
        </>
      )}
    </MUI.Dialog>
  );
}
