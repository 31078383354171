import React from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@material-ui/core';

import { NotifyFunction, parseJwt } from './Common/utils';
import RequestForm from './Resellers/RequestForm';
import ResellersPortal from './Resellers/ResellersPortal';

interface Props {
  jwt?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Resellers(props: Props) {
  const history = useHistory();
  const location = useLocation();

  // Redirect to portal form if reseller is already logged in
  React.useEffect(() => {
    if (props.jwt && location.pathname === '/resellers') {
      const jwtUserGroups = parseJwt(props.jwt).userGroups;
      if (jwtUserGroups.includes('reseller')) {
        history.push({ pathname: '/resellers/portal' });
      }
    }
  }, [props.jwt]);

  return (
    <MUI.Box flexGrow={1} minHeight='800px'>
      <Helmet>
        <title>Lindalë - Resellers</title>
      </Helmet>

      <Switch>
        <Route path='/resellers' exact>
          <RequestForm notify={props.notify} setMaintenanceMode={props.setMaintenanceMode} />
        </Route>

        <Route path='/resellers/portal'>
          <ResellersPortal
            jwt={props.jwt}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>
      </Switch>
    </MUI.Box>
  );
}
