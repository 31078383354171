import React from 'react';
import { Link as RouterLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@material-ui/core';
import { Download as DownloadIcon } from 'mdi-material-ui';

import Feature from './Common/Feature';
import Jumbotron from './Common/Jumbotron';
import ProductHeader from './Common/ProductHeader';

import BazaarLogoText from '../media/logos/3dbazaar_logo_text_filled.svg';
import JumbotronVideoMp4 from '../media/3dbazaar/bazaarjumbotron.mp4';
import JumbotronVideoWebM from '../media/3dbazaar/bazaarjumbotron.webm';
import MarketplaceImage from '../media/3dbazaar/marketplace.jpg';
import RenderersImage from '../media/3dbazaar/renderers.svg';
import PathRewriting from '../media/3dbazaar/path_rewriting.svg';
import LocalLibrary from '../media/3dbazaar/local_library.svg';

import GlobeplantsLogo from '../media/creators/globeplants.png';
import GraswaldLogo from '../media/creators/graswald.png';
import HumanoLogo from '../media/creators/humano.png';
import ModelUpLogo from '../media/creators/modelup.png';
import VizparkLogo from '../media/creators/vizpark.png';
import XfrogLogo from '../media/creators/xfrog.png';
import BazaarInstallInstructions from './Bazaar/BazaarInstallInstructions';
import { NotifyFunction, useScrollToTop } from './Common/utils';

const marketplaceAssetImages = [
  { url: 'https://files.lindale.io/marketplace/media/kx18xcqtr7i/ak40ai59gph_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/4dhgby63r83/1iwyb99f0dt_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/3niagkbyn1e/oee05wwnx59_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/o571w5fluev/tytobbvkygq_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/8o10n7nr0pm/nopd3g54sed_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/btfxf22y282/vx15qmg6akv_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/qr1f2xfsro3/c5htgjwldtq_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/92syyvcrvo8/chbvb1vo2b2_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/ypp4sm47ufw/l8uou5t9kaf_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/ec569zkbf7r/vzvtm6pj6dy_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/ysgk42hd8pq/0mrlsawagdt_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/n9km279nrk5/c8rjcrgnvmx_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/cbr0ai4pguc/5uksl8di7d2_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/v8fubhxlt2g/s4y0o94ltf5_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/nyya3lqdtp1/1hjlcg0n8gt_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/s2kgiw8n2ae/0e3t352zb5u_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/qsabv86rldp/4b9fo1v90nf_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/2615qym4nhh/pho8vq6ebxr_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/ddewptg31hi/vw3anutuwd6_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/w974a9ku4k1/i3tv7zk491b_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/0skwqxmuf2q/ntccfyr53yl_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/n1o68rv6nsl/zihggv38nq7_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/hbbg1krz7cu/uo76tdfzl7x_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/hx7hlsojeut/lk5wxvf3bw9_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/lzs79tu4qy8/auzz8ae09km_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/45trcvhaf5v/unm9dx6zduz_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/3nu7sut1zx4/csmsxn9j3k5_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/k1zh02xurhe/7uin8cez1vu_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/njkkm8kecwf/2va6ubp09xd_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/fkubq0pqbzm/y07qi9mla1t_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/5tiuku0ogui/pa1m37j0dlj_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/mjh9keolby9/h8pu2eiykw8_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/amkjszxijo0/u6omyez0s7d_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/3pd9o3kayux/5g5jhbuh7mp_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/kl6xh6g2xe2/elnemtxt6vt_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/dhaj2ga5izd/7jpe91uz4lk_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/1p1lqv5i1c6/7oshlkzn6w5_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/illxf76ijw6/12myvivigod_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/xcfnsemo179/ska58z2d59e_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/hehis2kuzqh/bldh6xcmosz_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/vl2rd3y40ba/dyz7xvvymh6_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/1kg3q9d3ve5/6wp7jtvoj5g_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/eiefkeostd5/frimamc4ria_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/q9eed5rk9sa/rovu7qx7hfg_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/r74rvd5xnb7/1r48949k608_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/adbyzho7p8k/o388jsxilx8_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/u1pzx0ab5pw/hbjjuhyfw2d_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/mi7vz8q8hgs/2idu38lrurc_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/peqog67d6fs/mzaeoaokbr6_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/mun997gdi3d/3tf392mmnk8_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/hh16ykosnzb/tn0ils2tp1p_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/fdd90xxvxjl/w0fxve2iiyt_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/fp1bmpgvppn/ikx7gk8ny3k_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/wq8m3rrotoc/qysuksz772k_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/okzhscvm1gt/s2dw0adoft6_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/59n2mbwn12v/3mymkmbdpjs_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/nepujaa3vrc/ofx66ocgirt_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/sc67p2kiys6/8hfzqf59p40_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/50gys5ztqnf/vzikyfq9c4l_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/30bs0mx5feo/gyetrxb9s9n_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/ylm3licmg74/d24qctq12ej_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/qi0ln5hg366/76qp4o3iqae_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/5ldh80m7d7e/mvj8jwb9r1t_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/qavhyq81ofp/ellh8wyat9g_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/0u9hb8ga9n5/pb4dq232eel_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/671c8fovvcc/immlz7s5vm9_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/gr5zkhufbvx/ym64pybkad4_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/7rtjr4zgz22/bm9st1fjlm3_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/u74r3cvonn7/0j6w45xd1k5_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/x24lknthcry/zjl3dwv8bs3_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/j9llmuj4y7i/qxvml64v10m_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/ao7udgq8r5e/lpci7t1han0_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/p0y6zrzpgej/xzv6l2wwg69_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/2pl28oz6qka/l05lijdkryb_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/x5fr9q26hol/eu18pmyfd2s_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/htchjdyls3a/d7586wjrb74_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/lqgekcaml12/66b7i7zolnf_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/tw5age08mu4/mkdren56tgu_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/hnz2xglrqp2/3kmrvaihe53_256.jpg' },
  { url: 'https://files.lindale.io/marketplace/media/q0gjf0n9i9q/albpygfegcj_256.jpg}' }
];

export const theme = MUI.createTheme({
  palette: {
    primary: {
      light: '#88c94f',
      main: '#6bbc24',
      dark: '#4a8319',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#88c94f',
      main: '#6bbc24',
      dark: '#4a8319',
      contrastText: '#ffffff'
    }
  }
});

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

const useStyles = MUI.makeStyles({
  creator: {
    margin: 32,
    '& img': {
      maxHeight: 30
    }
  }
});

export default function Bazaar(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  useScrollToTop(location.pathname);

  return (
    <MUI.MuiThemeProvider theme={theme}>
      <MUI.Box style={{ position: 'relative' }}>
        <Helmet>
          <title>Lindalë - 3D Bazaar</title>
        </Helmet>

        <Jumbotron
          logo={
            <img
              src={BazaarLogoText}
              alt='3D Bazaar logo'
              style={{
                maxWidth: '400px',
                maxHeight: '80px'
              }}
            />
          }
          subtitle='High-quality render-ready SketchUp content at your fingertips'
          color='#7d8285'
          subtitleFontWeight={400}
          action={
            <MUI.Button
              variant='contained'
              color='primary'
              size='large'
              component={RouterLink}
              to={`${routeMatch.path}?download`}
              startIcon={<DownloadIcon />}
            >
              Download now, it's free
            </MUI.Button>
          }
          backgroundVideoWebM={JumbotronVideoWebM}
          backgroundVideoMp4={JumbotronVideoMp4}
          backgroundOpacity={0.2}
        />

        <ProductHeader
          logo={BazaarLogoText}
          buttons={[
            {
              label: 'Overview',
              to: '/3dbazaar#overview',
              color: 'inherit'
            },
            {
              label: 'Creators',
              to: '/3dbazaar#creators',
              color: 'inherit'
            },
            {
              label: 'Help',
              to: '/support?product=3dbazaar',
              color: 'inherit'
            },
            {
              label: 'Download',
              to: '/3dbazaar?download',
              color: 'primary',
              variant: 'outlined'
            }
          ]}
        />

        <MUI.Box id='overview'>
          <Feature
            title='Extensive SketchUp models repository'
            main={[
              {
                description: (
                  <>
                    <b>The go-to Marketplace for high-quality render-ready assets for SketchUp.</b>{' '}
                    Vegetation, furniture, 3D people... you name it!
                    <br />
                    Stop spending hours online looking for the right model and setting it up for
                    SketchUp, we already did it for you.
                    <br />
                    More content is added every month, so you'll never drag behind.
                  </>
                )
              }
            ]}
            action={
              <MUI.Button
                href='https://3dbazaar.lindale.io/market'
                color='primary'
                variant='outlined'
              >
                Browse the Marketplace
              </MUI.Button>
            }
            slideshowImages={marketplaceAssetImages}
          />

          <Feature
            title='High-quality render-ready with zero setup'
            main={[
              {
                description: (
                  <>
                    Every asset in the 3D Bazaar Marketplace has been meticulously crafted to be{' '}
                    <b>render-ready* out of the box</b>. Choose if you want proxies or regular
                    geometry, click Import and you're done! No setup required.
                    <br />
                    <br />
                    <i>*Supported renderers: V-Ray, Enscape, Thea</i>
                  </>
                ),
                image: MarketplaceImage,
                imageFit: 'cover'
              }
            ]}
            reverse
          />

          <Feature
            title='One asset, multiple renderers'
            main={[
              {
                description: (
                  <>
                    Most assets in the 3D Bazaar Marketplace are compatible with several renderers.
                    With a{' '}
                    <b>
                      single import you can render these assets in V-Ray, Enscape, and Thea in
                      parallel
                    </b>{' '}
                    with no additional setup.
                  </>
                ),
                image: RenderersImage
              }
            ]}
          />

          <Feature
            title='No manual work'
            main={[
              {
                description: (
                  <>
                    The 3D Bazaar SketchUp extension takes care of{' '}
                    <b>finding and re-linking all the maps and proxies</b>, so you don't have to do
                    it manually everytime you import a new asset.
                    <br />
                    Just import your assets through the extension, and start rendering!
                    <br />
                    This works for assets downloaded from the Marketplace, as well as files you
                    already have on your machine.
                  </>
                ),
                image: PathRewriting
              }
            ]}
            action={
              <MUI.Button
                color='primary'
                variant='contained'
                component={RouterLink}
                to={`${routeMatch.path}?download`}
              >
                Download the Extension
              </MUI.Button>
            }
            reverse
          />

          <Feature
            title='Manage your local assets'
            main={[
              {
                description: (
                  <>
                    Organize your local library, and easily find the one asset you're looking for on
                    your disk, by using our powerful search engine. <br />
                    Your whole library is indexed to get instant search results on model names,
                    descriptions, and more metadata.
                  </>
                ),
                image: LocalLibrary
              }
            ]}
          />
        </MUI.Box>

        <MUI.Box
          id='creators'
          pt={12}
          pb={12}
          // bgcolor='#f7f7f6' // Uncomment if previous feature is reversed
        >
          <MUI.Container maxWidth='lg'>
            <MUI.Typography variant='h4' align='center' color='textSecondary'>
              Creators
            </MUI.Typography>
            <MUI.Box mt={2}>
              <MUI.Typography variant='body1' align='center' color='textSecondary'>
                We've teamed up with the best content creators from the industry to bring you the
                highest quality available on the market.
              </MUI.Typography>
            </MUI.Box>

            <MUI.Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexWrap='wrap'
              mt={2}
            >
              <MUI.Box className={classes.creator}>
                <img src={GlobeplantsLogo} alt='Globeplants logo' />
              </MUI.Box>
              <MUI.Box className={classes.creator}>
                <img src={GraswaldLogo} alt='Graswald logo' />
              </MUI.Box>
              <MUI.Box className={classes.creator}>
                <img src={HumanoLogo} alt='Humano logo' />
              </MUI.Box>
              <MUI.Box className={classes.creator}>
                <img src={ModelUpLogo} alt='ModelUp logo' />
              </MUI.Box>
              <MUI.Box className={classes.creator}>
                <img src={VizparkLogo} alt='Vizpark logo' />
              </MUI.Box>
              <MUI.Box className={classes.creator}>
                <img src={XfrogLogo} alt='Xfrog logo' style={{ maxHeight: 40 }} />
              </MUI.Box>
            </MUI.Box>

            <MUI.Box display='flex' justifyContent='center' flexWrap='wrap' mt={2}>
              <MUI.Button color='primary' variant='outlined' href='mailto:3dbazaar@lindale.io'>
                Want to join in? Get in touch
              </MUI.Button>
            </MUI.Box>
          </MUI.Container>
        </MUI.Box>

        {location.search === '?download' && (
          <BazaarInstallInstructions
            onClose={() => history.replace(routeMatch.path)}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
      </MUI.Box>
    </MUI.MuiThemeProvider>
  );
}
