import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as MUI from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { NotifyFunction } from '../Common/utils';
import { downloadVersion } from '../Common/InstallInstructions';

interface Props {
  trial?: boolean;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
  onClose: () => void;
}

function SkatterInstallInstructions(props: Props) {
  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='md'>
      <MUI.DialogTitle style={{ textAlign: 'center' }}>
        <p>Two ways to install Skatter {props.trial && <>Trial</>}</p>
        {props.trial && (
          <MUI.Typography variant='body1'>
            The Trial is full-featured, but limited to 15 days.
          </MUI.Typography>
        )}
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          <MUI.Box mb={3}>
            <MUI.Typography variant='caption'>
              System requirements: Windows 10 or MacOS 12
            </MUI.Typography>
          </MUI.Box>

          <MUI.Grid container spacing={6}>
            <MUI.Grid item xs={12} sm={6}>
              <MUI.Typography variant='h5' color='primary'>
                Install via the Extension Warehouse
              </MUI.Typography>
              <ol>
                <li>
                  In SketchUp, go to <b>Window &gt; Extension Warehouse</b> and sign in.
                </li>
                <li style={{ marginTop: '10px' }}>
                  Search for <b>"Skatter"</b> and click on <b>Install</b>.<br />
                  <MUI.Typography variant='caption'>
                    When SketchUp asks whether you trust the author and want to continue installing
                    the extension, you need to click <b>Yes</b> to proceed.
                  </MUI.Typography>
                </li>
              </ol>
            </MUI.Grid>

            <MUI.Grid item xs={12} sm={6}>
              <MUI.Typography variant='h5' color='primary'>
                Install manually
              </MUI.Typography>
              <MUI.Box mt='15px'>
                <MUI.Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    downloadVersion(
                      'skatter/v1/latestversion/',
                      'latestVersion',
                      props.notify,
                      props.setMaintenanceMode
                    )
                  }
                >
                  Download the Installer
                </MUI.Button>
              </MUI.Box>
              <ol>
                <li>
                  In SketchUp, go to <b>Window &gt; Extension Manager</b> and click on the{' '}
                  <b>Install Extension</b> button.
                </li>
                <li style={{ marginTop: '10px' }}>
                  Select the <b>.rbz</b> file that you just downloaded.
                </li>
              </ol>
            </MUI.Grid>
          </MUI.Grid>

          <MUI.Box mt='40px'>
            <MUI.Accordion>
              <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <MUI.Typography>Skatter v2 for SketchUp 2017-2020</MUI.Typography>
              </MUI.AccordionSummary>
              <MUI.AccordionDetails style={{ flexDirection: 'column' }}>
                <MUI.Typography>
                  Download the latest Skatter version compatible with SketchUp 2017-2020:{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() =>
                      downloadVersion(
                        'skatter/v1/version/2.2.0',
                        '2.2.0',
                        props.notify,
                        props.setMaintenanceMode
                      )
                    }
                  >
                    Download the Installer
                  </span>
                </MUI.Typography>
              </MUI.AccordionDetails>
            </MUI.Accordion>
            <MUI.Accordion>
              <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <MUI.Typography>Legacy Skatter v1 installers</MUI.Typography>
              </MUI.AccordionSummary>
              <MUI.AccordionDetails style={{ flexDirection: 'column' }}>
                <MUI.Typography>
                  Download the latest Skatter v1 version (legacy):{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() =>
                      downloadVersion(
                        'skatter/v1/latestv1version',
                        'latestVersion',
                        props.notify,
                        props.setMaintenanceMode,
                        'win'
                      )
                    }
                  >
                    Windows
                  </span>{' '}
                  -{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() =>
                      downloadVersion(
                        'skatter/v1/latestv1version',
                        'latestVersion',
                        props.notify,
                        props.setMaintenanceMode,
                        'mac'
                      )
                    }
                  >
                    MacOS
                  </span>
                </MUI.Typography>
                <MUI.Typography style={{ fontWeight: 'bold' }}>
                  Note: Skatter v1 is compatible with SketchUp 2017 to 2021. To use Skatter in
                  SketchUp 2022 and above, you will need to{' '}
                  <MUI.Link component={RouterLink} to='/skatter#buy'>
                    upgrade your v1 license to v2
                  </MUI.Link>
                  .
                </MUI.Typography>
              </MUI.AccordionDetails>
            </MUI.Accordion>
          </MUI.Box>
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(SkatterInstallInstructions);
