import * as MUI from '@material-ui/core';
import React from 'react';
import { NotifVariant, NotifyFunction, downloadUrl, webApiCall } from './utils';

export async function downloadVersion(
  endpoint: string,
  versionNumber: string,
  notify: NotifyFunction,
  setMaintenanceMode: (enable: boolean) => void,
  os?: 'win' | 'mac'
) {
  webApiCall({
    method: 'get',
    endpoint: endpoint,
    notify: notify,
    setMaintenanceMode: setMaintenanceMode,
    successCallback: (response) => {
      const version = versionNumber ? versionNumber : 'latestVersion';
      if (
        response.data.data?.[version]?.downloadLink ||
        response.data.data?.[version]?.downloadLinks
      ) {
        const url = os
          ? response.data.data[version].downloadLinks[os]
          : response.data.data[version].downloadLink;
        downloadUrl(url);
        gtag('event', 'Downloads');
      } else {
        notify('Cannot fetch version: missing download link in response', NotifVariant.ERROR);
      }
    },
    errorCallback: (response, message) => {
      notify(`Cannot fetch version: ${message}`, NotifVariant.ERROR);
    }
  });
}

type Props = {
  name: string;
  apiName: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
  onClose: () => void;
};

function InstallInstructions(props: Props) {
  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='lg'>
      <MUI.DialogTitle style={{ textAlign: 'center' }}>
        Two ways to install the {props.name} SketchUp Extension
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          <MUI.Box mb={3}>
            <MUI.Typography variant='caption'>
              System requirements: SketchUp 2020+, Windows 10 or MacOS 12
            </MUI.Typography>
          </MUI.Box>

          <MUI.Grid container spacing={6} justifyContent='center'>
            <MUI.Grid item xs={12} sm={5}>
              <MUI.Typography variant='h5' color='primary'>
                Install via the Extension Warehouse
              </MUI.Typography>
              <ol>
                <li>
                  In SketchUp, go to <b>Window &gt; Extension Warehouse</b> and sign in.
                </li>
                <li style={{ marginTop: '10px' }}>
                  Search for <b>"{props.name}"</b> and click on <b>Install</b>.<br />
                  <MUI.Typography variant='caption'>
                    When SketchUp asks whether you trust the author and want to continue installing
                    the extension, you need to click <b>Yes</b> to proceed.
                  </MUI.Typography>
                </li>
              </ol>
            </MUI.Grid>

            <MUI.Grid item container xs={12} sm={1} alignItems='center' justifyContent='center'>
              <MUI.Hidden xsDown>
                <MUI.Divider orientation='vertical' />
              </MUI.Hidden>
              <MUI.Hidden smUp>
                <MUI.Divider orientation='horizontal' style={{ width: '100%', height: '1px' }} />
              </MUI.Hidden>
            </MUI.Grid>

            <MUI.Grid item xs={12} sm={5}>
              <MUI.Typography variant='h5' color='primary'>
                Install manually
              </MUI.Typography>
              <MUI.Box mt='15px'>
                <MUI.Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    downloadVersion(
                      `${props.apiName}/v1/latestversion`,
                      'latestVersion',
                      props.notify,
                      props.setMaintenanceMode
                    )
                  }
                >
                  Download the Installer
                </MUI.Button>
              </MUI.Box>
              <ol>
                <li>
                  In SketchUp, go to <b>Window &gt; Extension Manager</b> and click on the{' '}
                  <b>Install Extension</b> button.
                </li>
                <li style={{ marginTop: '10px' }}>
                  Select the <b>.rbz</b> file that you just downloaded.
                </li>
              </ol>
            </MUI.Grid>
          </MUI.Grid>
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(InstallInstructions);
