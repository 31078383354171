import React from 'react';
import * as MUI from '@material-ui/core';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { SkatterProductId, productNames } from './SkatterPriceTable';
import { NotifyFunction, webApiCall } from '../Common/utils';

interface Props {
  productId: SkatterProductId;
  onClose: () => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function SkatterUpgradeDialog(props: Props) {
  const [v1LicenseKey, setV1LicenseKey] = React.useState('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [waiting, setWaiting] = React.useState(false);

  const submit = React.useCallback(() => {
    if (!v1LicenseKey.match(/^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}$/)) {
      setErrors({ v1LicenseKey: 'Invalid Skatter v1 license key' });
    } else {
      setWaiting(true);
      setErrors({});

      webApiCall({
        method: 'get',
        endpoint: `payments/v1/versionupgrade/skatter/${v1LicenseKey}/${props.productId}`,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          // @ts-ignore
          Paddle.Checkout.open({
            override: response.data.data.url
          });

          props.onClose();
        },
        errorCallback: (response) => {
          if (response.data && response.data.data) {
            setErrors(response.data.data);
          }
        },
        ensureCallback: () => {
          setWaiting(false);
        }
      });
    }
  }, [props.productId, v1LicenseKey]);

  return (
    <MUI.Dialog maxWidth='sm' fullWidth open onClose={props.onClose}>
      <MUI.DialogTitle>
        Upgrade your Skatter v1 License to v2 {productNames[props.productId]}
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.TextField
          label='Skatter v1 License Key'
          fullWidth
          variant='outlined'
          size='small'
          value={v1LicenseKey}
          onChange={(e) => setV1LicenseKey(e.target.value)}
          disabled={waiting}
          error={!!errors.v1LicenseKey}
          helperText={errors.v1LicenseKey}
        />
        <MUI.Box mt='24px' position='relative'>
          <MUI.Button
            fullWidth
            variant='contained'
            disableElevation
            color='primary'
            startIcon={<CartIcon />}
            onClick={() => {
              submit();
            }}
            disabled={waiting}
          >
            Upgrade
          </MUI.Button>
          {waiting && (
            <MUI.CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12
              }}
            />
          )}
        </MUI.Box>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose}>Close</MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}
