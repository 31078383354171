import React from 'react';
import * as MUI from '@material-ui/core';

interface Props {
  price: number;
  recurringPrice?: number;
  priceMultiplier: number;
  currency: string;
  quantity: number;
  seats: number;
  disabled?: boolean;
  waiting: boolean;
  submit: () => void;
}

export default function PriceDetails(props: Props) {
  const {
    price,
    recurringPrice,
    priceMultiplier,
    currency,
    quantity,
    seats,
    disabled,
    waiting,
    submit
  } = props;

  const [agreementChecked, setAgreementChecked] = React.useState(false);

  const fullLicensePriceStr = `${(seats * price).toFixed(2)} ${currency} ${
    recurringPrice
      ? recurringPrice !== price
        ? `/1st yr (then ${(seats * recurringPrice).toFixed(2)} ${currency} /yr)`
        : '/yr'
      : ''
  }`;

  const discountedLicensePriceStr = `${(seats * price * priceMultiplier).toFixed(2)} ${currency} ${
    recurringPrice
      ? recurringPrice !== price
        ? `/1st yr (then ${(seats * recurringPrice * priceMultiplier).toFixed(2)} ${currency} /yr)`
        : '/yr'
      : ''
  }`;

  const marginStr = `${(seats * (price - price * priceMultiplier)).toFixed(2)} ${currency} ${
    recurringPrice
      ? recurringPrice !== price
        ? `/1st yr (then ${(seats * (recurringPrice - recurringPrice * priceMultiplier)).toFixed(
            2
          )} ${currency} /yr)`
        : '/yr'
      : ''
  }`;

  const discountedLicenseTotalStr = `${(quantity * seats * price * priceMultiplier).toFixed(
    2
  )} ${currency} ${
    recurringPrice
      ? recurringPrice !== price
        ? `/1st yr (then ${(quantity * seats * recurringPrice * priceMultiplier).toFixed(
            2
          )} ${currency} /yr)`
        : '/yr'
      : ''
  }`;

  const marginTotalStr = `${(quantity * seats * (price - price * priceMultiplier)).toFixed(
    2
  )} ${currency} ${
    recurringPrice
      ? recurringPrice !== price
        ? `/1st yr (then ${(
            quantity *
            seats *
            (recurringPrice - recurringPrice * priceMultiplier)
          ).toFixed(2)} ${currency} /yr)`
        : '/yr'
      : ''
  }`;

  return (
    <>
      <MUI.Box mt='32px'>
        Price per license when you resell to the end-user (mandatory): <b>{fullLicensePriceStr}</b>
        <br />
        Price per license when you order from us: <b>{discountedLicensePriceStr}</b>
        <br />
        Your margin per license: <b>{marginStr}</b>
        <br />
        <br />
        Total price you pay: <b>{discountedLicenseTotalStr}</b>
        <br />
        Total margin: <b>{marginTotalStr}</b>
        <br />
      </MUI.Box>

      <MUI.Box mt='16px'>
        <MUI.FormControlLabel
          control={
            <MUI.Checkbox
              checked={agreementChecked}
              onChange={(e) => setAgreementChecked(e.target.checked)}
            />
          }
          label={
            <>
              I understand that I must sell {quantity > 1 ? 'these licenses' : 'this license'} no
              more and no less than <b>{fullLicensePriceStr}</b>{' '}
              {quantity > 1 ? 'each to the end-customers' : 'to the end-customer'}.
            </>
          }
        />
      </MUI.Box>

      <MUI.Box mt='32px' position='relative'>
        <MUI.Button
          variant='contained'
          color='primary'
          onClick={submit}
          disabled={disabled || waiting || !agreementChecked}
        >
          ORDER
        </MUI.Button>
        {waiting && (
          <MUI.CircularProgress
            size={24}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50px',
              marginTop: -12,
              marginLeft: -12
            }}
          />
        )}
      </MUI.Box>
    </>
  );
}
