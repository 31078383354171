import React from 'react';
import { useSnackbar } from 'notistack';
import * as MUI from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  NotifyFunction,
  Product,
  productNames,
  LicenseType,
  licenseTypeNames,
  LicenseStatus,
  licenseStatusNames,
  webApiCall,
  parseJwt
} from '../Common/utils';
import LicenseActivations from './LicenseActivations';
import TransmutrDownloadOverlay from '../Transmutr/TransmutrDownloadOverlay';
import TransmutrStudioUpgradeOverlay from '../Transmutr/TransmutrStudioUpgradeOverlay';
import { downloadVersion } from '../Common/InstallInstructions';
import SkatterInstallInstructions from '../Skatter/SkatterInstallInstructions';

type License = {
  key: string;
  type: LicenseType;
  product: Product;
  creationDate: string;
  expirationDate?: string;
  status: LicenseStatus;
  seats: number;
  activations?: number;
  email: string;
  edu: boolean;

  subscriptionStatus?: 'active' | 'past_due' | 'trialing' | 'deleted' | 'paused';
  subscriptionUpdateUrl?: string;
  subscriptionCancelUrl?: string;
  subscriptionLastPayment?: {
    amount: number;
    currency: string;
    date: string;
  };
  subscriptionNextPayment?: {
    amount: number;
    currency: string;
    date: string;
  };
};

const useStyles = MUI.makeStyles((theme) => ({
  row: {
    cursor: 'pointer'
  }
}));

const StudioButton = MUI.styled(MUI.Button)<MUI.ButtonProps>(({ theme }) => ({
  color: 'white',
  backgroundColor: '#ffc107',
  '&:hover': {
    backgroundColor: '#b28704'
  }
}));

interface Props {
  loading?: boolean;
  jwt?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Licenses(props: Props) {
  const theme = MUI.useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState(true);
  const [licenses, setLicenses] = React.useState<License[]>([]);
  const [activationsLicenseKey, setActivationsLicenseKey] = React.useState<string | undefined>(
    undefined
  );
  const [addedLicenseKey, setAddedLicenseKey] = React.useState<string | undefined>(undefined);

  const [skatterInstallInstructionsOpen, setSkatterInstallInstructionsOpen] = React.useState(false);
  const [transmutrDownloadOverlayOpen, setTransmutrDownloadOverlayOpen] = React.useState(false);

  const [studioUpgradeOverlayLicenseKey, setStudioUpgradeOverlayLicenseKey] = React.useState<
    string | undefined
  >(undefined);
  const [studioUpgradeOverlayOpen, setStudioUpgradeOverlayOpen] = React.useState(false);

  const fetchLicenses = React.useCallback(() => {
    if (props.jwt) {
      setLoading(true);
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `users/v1/${jwtUserId}/licenses`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setLicenses(
            response.data.data.licenses.map((l: any) => ({
              key: l.key,
              type: l.type,
              product: l.product,
              creationDate: l.creationDate,
              expirationDate: l.expirationDate,
              status: l.status,
              seats: l.seats,
              activations: l.activations,
              email: l.email,
              edu: l.edu,
              subscriptionStatus: l.subscriptionStatus,
              subscriptionUpdateUrl: l.subscriptionUpdateUrl,
              subscriptionCancelUrl: l.subscriptionCancelUrl,
              subscriptionLastPayment: l.subscriptionLastPayment,
              subscriptionNextPayment: l.subscriptionNextPayment
            }))
          );
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt]);

  React.useEffect(() => {
    fetchLicenses();
  }, [fetchLicenses]);

  const handleAddedLicenseKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddedLicenseKey(event.target.value);
  };

  const submitAddedLicenseKey = React.useCallback(() => {
    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      setLoading(true);
      webApiCall({
        method: 'post',
        endpoint: `users/v1/${jwtUserId}/licenses`,
        jwt: props.jwt,
        data: { licenseKey: addedLicenseKey },
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          fetchLicenses();
          setAddedLicenseKey(undefined);
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt, addedLicenseKey, props.notify, props.setMaintenanceMode]);

  const openStudioUpgradeOverlay = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    licenseKey: string
  ) => {
    event.stopPropagation();
    setStudioUpgradeOverlayLicenseKey(licenseKey);
    setStudioUpgradeOverlayOpen(true);
  };

  return (
    <MUI.Container maxWidth='md' style={{ height: '100%' }}>
      {activationsLicenseKey && (
        <LicenseActivations
          licenseKey={activationsLicenseKey}
          loading={loading}
          jwt={props.jwt}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
          onClose={() => setActivationsLicenseKey(undefined)}
          onActivationsRefresh={() => fetchLicenses()}
        />
      )}

      {props.loading || loading ? (
        <MUI.CircularProgress />
      ) : (
        <MUI.Box height='100%' width='100%'>
          <MUI.Box mb='32px' display='flex'>
            <MUI.Box>
              <MUI.TextField
                value={addedLicenseKey}
                onChange={handleAddedLicenseKeyChange}
                placeholder='Add License Key'
                helperText="If your license doesn't appear in the list below, it could be because you purchased it with another email address. Enter the license key to bind it to your account."
                size='small'
                margin='dense'
                variant='outlined'
                fullWidth
              ></MUI.TextField>
            </MUI.Box>
            <MUI.Box>
              <MUI.Button
                variant='contained'
                style={{ marginTop: '8px', marginLeft: '16px' }}
                onClick={() => submitAddedLicenseKey()}
                disabled={!addedLicenseKey}
              >
                Submit
              </MUI.Button>
            </MUI.Box>
          </MUI.Box>

          <MUI.Box>
            {licenses
              .sort((a, b) => {
                if (a.status === LicenseStatus.ACTIVE && b.status !== LicenseStatus.ACTIVE)
                  return -1;
                else if (a.status !== LicenseStatus.ACTIVE && b.status === LicenseStatus.ACTIVE)
                  return 1;
                else return 0;
              })
              .map((license, i) => (
                <MUI.Accordion key={i}>
                  <MUI.AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    style={
                      license.status !== LicenseStatus.ACTIVE
                        ? {
                            color: theme.palette.text.disabled,
                            fontStyle: 'italic',
                            textDecorationLine: 'line-through'
                          }
                        : {}
                    }
                  >
                    <MUI.Box display='flex' alignItems='center'>
                      <MUI.Typography style={{ marginRight: '30px' }}>
                        {productNames[license.product]}
                      </MUI.Typography>
                      <MUI.Typography variant='caption' style={{ marginRight: '20px' }}>
                        {license.key}
                      </MUI.Typography>
                      {license.product === Product.TRANSMUTR_ARTIST &&
                        license.status === LicenseStatus.ACTIVE &&
                        !license.edu && (
                          <StudioButton
                            variant='contained'
                            disableElevation
                            size='small'
                            onClick={(e) => openStudioUpgradeOverlay(e, license.key)}
                          >
                            Upgrade to Studio
                          </StudioButton>
                        )}
                    </MUI.Box>
                  </MUI.AccordionSummary>
                  <MUI.Box p={'8px 16px 16px'}>
                    <MUI.Table size='small'>
                      <MUI.TableBody>
                        <MUI.TableRow>
                          <MUI.TableCell>Status</MUI.TableCell>
                          <MUI.TableCell>
                            <b
                              style={
                                license.status === LicenseStatus.ACTIVE
                                  ? { color: 'green' }
                                  : { color: 'red' }
                              }
                            >
                              {licenseStatusNames[license.status]}
                            </b>
                          </MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Product</MUI.TableCell>
                          <MUI.TableCell>{productNames[license.product]}</MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Key</MUI.TableCell>
                          <MUI.TableCell>{license.key}</MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Type</MUI.TableCell>
                          <MUI.TableCell>{licenseTypeNames[license.type]}</MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Activations</MUI.TableCell>
                          <MUI.TableCell>
                            {license.product === Product.SKATTER1 ? (
                              <i>
                                Skatter v1 license activations are not available here. Contact us at
                                skatter@lindale.io
                              </i>
                            ) : (
                              <>
                                {license.activations} / {license.seats} |{' '}
                                <a href='#' onClick={() => setActivationsLicenseKey(license.key)}>
                                  manage activations
                                </a>
                              </>
                            )}
                          </MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Purchase Email</MUI.TableCell>
                          <MUI.TableCell>{license.email}</MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Purchase Date</MUI.TableCell>
                          <MUI.TableCell>
                            {license.creationDate &&
                              new Date(license.creationDate).toISOString().slice(0, 10)}
                          </MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Expiration Date</MUI.TableCell>
                          <MUI.TableCell>
                            {license.expirationDate && !license.expirationDate.startsWith('0000')
                              ? new Date(license.expirationDate).toISOString().slice(0, 10)
                              : '-'}
                          </MUI.TableCell>
                        </MUI.TableRow>
                        <MUI.TableRow>
                          <MUI.TableCell>Download latest version</MUI.TableCell>
                          <MUI.TableCell>
                            {license.product === Product.SKATTER2 && (
                              <span
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => setSkatterInstallInstructionsOpen(true)}
                              >
                                Download
                              </span>
                            )}
                            {license.product === Product.SKATTER1 && (
                              <>
                                <span
                                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={() =>
                                    downloadVersion(
                                      'skatter/v1/latestv1version',
                                      'latestVersion',
                                      props.notify,
                                      props.setMaintenanceMode,
                                      'win'
                                    )
                                  }
                                >
                                  Windows
                                </span>{' '}
                                -{' '}
                                <span
                                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={() =>
                                    downloadVersion(
                                      'skatter/v1/latestv1version',
                                      'latestVersion',
                                      props.notify,
                                      props.setMaintenanceMode,
                                      'mac'
                                    )
                                  }
                                >
                                  MacOS
                                </span>
                              </>
                            )}
                            {(license.product === Product.TRANSMUTR_ARTIST ||
                              license.product === Product.TRANSMUTR_STUDIO) && (
                              <span
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => setTransmutrDownloadOverlayOpen(true)}
                              >
                                Download
                              </span>
                            )}
                          </MUI.TableCell>
                        </MUI.TableRow>
                      </MUI.TableBody>
                    </MUI.Table>

                    {license.subscriptionStatus && (
                      <>
                        <MUI.Typography variant='h6' style={{ marginTop: '32px' }}>
                          Subscription
                        </MUI.Typography>
                        <MUI.Table size='small'>
                          <MUI.TableBody>
                            <MUI.TableRow>
                              <MUI.TableCell>Subscription status</MUI.TableCell>
                              <MUI.TableCell>{license.subscriptionStatus}</MUI.TableCell>
                            </MUI.TableRow>
                            <MUI.TableRow>
                              <MUI.TableCell>Last payment</MUI.TableCell>
                              <MUI.TableCell>
                                {license.subscriptionLastPayment && (
                                  <>
                                    {new Date(license.subscriptionLastPayment.date)
                                      .toISOString()
                                      .slice(0, 10)}{' '}
                                    ({license.subscriptionLastPayment.amount}{' '}
                                    {license.subscriptionLastPayment.currency})
                                  </>
                                )}
                              </MUI.TableCell>
                            </MUI.TableRow>
                            <MUI.TableRow>
                              <MUI.TableCell>Next payment</MUI.TableCell>
                              <MUI.TableCell>
                                {license.subscriptionNextPayment && (
                                  <>
                                    {new Date(license.subscriptionNextPayment.date)
                                      .toISOString()
                                      .slice(0, 10)}{' '}
                                    ({license.subscriptionNextPayment.amount}{' '}
                                    {license.subscriptionNextPayment.currency})
                                  </>
                                )}
                              </MUI.TableCell>
                            </MUI.TableRow>
                            <MUI.TableRow>
                              <MUI.TableCell>Payment method</MUI.TableCell>
                              <MUI.TableCell>
                                <a target='_blank' href={license.subscriptionUpdateUrl}>
                                  Change the payment method
                                </a>
                              </MUI.TableCell>
                            </MUI.TableRow>
                            <MUI.TableRow>
                              <MUI.TableCell>Cancel subscription</MUI.TableCell>
                              <MUI.TableCell>
                                <a
                                  target='_blank'
                                  href={license.subscriptionCancelUrl}
                                  style={{ color: 'red' }}
                                >
                                  Cancel the subscription
                                </a>{' '}
                                (the license will expire after{' '}
                                {license.expirationDate &&
                                  new Date(license.expirationDate).toISOString().slice(0, 10)}
                                )
                              </MUI.TableCell>
                            </MUI.TableRow>
                          </MUI.TableBody>
                        </MUI.Table>
                      </>
                    )}
                  </MUI.Box>
                </MUI.Accordion>
              ))}
          </MUI.Box>
        </MUI.Box>
      )}

      {skatterInstallInstructionsOpen && (
        <SkatterInstallInstructions
          onClose={() => setSkatterInstallInstructionsOpen(false)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}
      {transmutrDownloadOverlayOpen && (
        <TransmutrDownloadOverlay
          onClose={() => setTransmutrDownloadOverlayOpen(false)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}
      {studioUpgradeOverlayOpen && (
        <TransmutrStudioUpgradeOverlay
          licenseKey={studioUpgradeOverlayLicenseKey}
          onUpgradeSuccessful={() => fetchLicenses()}
          onClose={() => setStudioUpgradeOverlayOpen(false)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}
    </MUI.Container>
  );
}
