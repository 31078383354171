import React from 'react';
import * as MUI from '@material-ui/core';

import { NotifyFunction } from '../Common/utils';
import { downloadVersion } from '../Common/InstallInstructions';

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
  onClose: () => void;
}

function InstallInstructions(props: Props) {
  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='md'>
      <MUI.DialogTitle style={{ textAlign: 'center' }}>
        Two ways to install the 3D Bazaar SketchUp Extension
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          <MUI.Box mb={3}>
            <MUI.Typography variant='caption'>
              System requirements: Windows 10 or MacOS 12
            </MUI.Typography>
          </MUI.Box>

          <MUI.Grid container spacing={6}>
            <MUI.Grid item xs={12} sm={6}>
              <MUI.Typography variant='h5' color='primary'>
                Install via the Extension Warehouse
              </MUI.Typography>
              <ol>
                <li>
                  In SketchUp, go to <b>Window &gt; Extension Warehouse</b> and sign in.
                </li>
                <li style={{ marginTop: '10px' }}>
                  Search for <b>"3D Bazaar"</b> and click on <b>Install</b>.<br />
                  <MUI.Typography variant='caption'>
                    When SketchUp asks whether you trust the author and want to continue installing
                    the extension, you need to click <b>Yes</b> to proceed.
                  </MUI.Typography>
                </li>
              </ol>
            </MUI.Grid>

            <MUI.Grid item xs={12} sm={6}>
              <MUI.Typography variant='h5' color='primary'>
                Install manually
              </MUI.Typography>
              <MUI.Box mt='15px'>
                <MUI.Button
                  variant='contained'
                  color='primary'
                  onClick={() =>
                    downloadVersion(
                      'bazaarextension/v1/latestversion',
                      'latestVersion',
                      props.notify,
                      props.setMaintenanceMode
                    )
                  }
                >
                  Download the Installer
                </MUI.Button>
              </MUI.Box>
              <ol>
                <li>
                  In SketchUp, go to <b>Window &gt; Extension Manager</b> and click on the{' '}
                  <b>Install Extension</b> button.
                </li>
                <li style={{ marginTop: '10px' }}>
                  Select the <b>.rbz</b> file that you just downloaded.
                </li>
              </ol>
            </MUI.Grid>
          </MUI.Grid>
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(InstallInstructions);
