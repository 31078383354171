import React from 'react';

import * as MUI from '@material-ui/core';
import YoutubeEmbed from './YoutubeEmbed';
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
  ReactCompareSliderHandle
} from 'react-compare-slider';

interface Props {
  action?: JSX.Element;
  title?: string;
  main: {
    title?: string;
    description?: React.ReactNode;
    side?: React.ReactNode;
    image?: string;
    imageFit?: 'contain' | 'cover';
    imageCredit?: string;
    videoWebm?: string;
    videoMp4?: string;
    mediaShadow?: boolean;
    imageCompare?: { image1: string; image2: string; handleColor?: string };
  }[];
  more?: {
    title?: string;
    description?: React.ReactNode;
    side?: React.ReactNode;
    image?: string;
    imageFit?: 'contain' | 'cover';
    imageCredit?: string;
    videoWebm?: string;
    videoMp4?: string;
    mediaShadow?: boolean;
  }[];
  youtubeEmbedId?: string;
  slideshowImages?: { url: string; size?: number }[];
  reverse?: boolean;
}
const defaultProps: Partial<Props> = {
  reverse: false
};

const useStyles = MUI.makeStyles({
  slideshow: () => ({
    animation: '$slideshow 120s linear 0s infinite alternate'
  }),
  slideshowReverse: () => ({
    animation: '$slideshowReverse 120s linear 0s infinite alternate'
  }),
  '@keyframes slideshow': {
    '0%': {
      transform: 'translateX(0)'
    },
    '100%': {
      transform: (props: { slideshowImages: NonNullable<Props['slideshowImages']> }) =>
        `translateX(calc(-${(props.slideshowImages.length / 2) * 150}px + 100vw))`
    }
  },
  '@keyframes slideshowReverse': {
    '0%': {
      transform: (props: { slideshowImages: NonNullable<Props['slideshowImages']> }) =>
        `translateX(calc(-${(props.slideshowImages.length / 2) * 150}px + 100vw))`
    },
    '100%': {
      transform: 'translateX(0)'
    }
  }
});

export default function Feature(props: Props) {
  const classes = useStyles({ slideshowImages: props.slideshowImages || [] });

  const [tabIndex, setTabIndex] = React.useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
  };

  const [moreTabIndex, setMoreTabIndex] = React.useState(0);
  const handleMoreTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setMoreTabIndex(newIndex);
  };

  const [moreExpanded, setMoreExpanded] = React.useState(false);

  const hasText =
    props.main &&
    (props.main[tabIndex].title !== undefined ||
      props.main[tabIndex].description !== undefined ||
      props.action !== undefined);

  const renderSlideshow = (images: NonNullable<Props['slideshowImages']>, reverse: boolean) => {
    return (
      <MUI.Box
        display='flex'
        flexWrap='nowrap'
        className={reverse ? classes.slideshowReverse : classes.slideshow}
      >
        {images.map((image) => (
          <MUI.Box
            flexShrink={0}
            height='150px'
            width={`${150 * (image.size ?? 1)}px`}
            m={0.3}
            borderRadius='6px'
            key={image.url}
            style={{
              backgroundImage: `url("${image.url}")`,
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          />
        ))}
      </MUI.Box>
    );
  };

  const side =
    props.more && moreExpanded && props.more[moreTabIndex].side
      ? props.more[moreTabIndex].side
      : props.main?.[tabIndex].side;

  const imageUrl =
    props.more && moreExpanded && props.more[moreTabIndex].image
      ? props.more[moreTabIndex].image
      : props.main?.[tabIndex].image;
  const imageFit =
    props.more && moreExpanded && props.more[moreTabIndex].image
      ? props.more[moreTabIndex].imageFit || 'contain'
      : props.main?.[tabIndex].imageFit || 'contain';
  const imageCredit =
    props.more && moreExpanded && props.more[moreTabIndex].image
      ? props.more[moreTabIndex].imageCredit
      : props.main?.[tabIndex].imageCredit;
  const imageShadow =
    props.more && moreExpanded && props.more[moreTabIndex].image
      ? props.more[moreTabIndex].mediaShadow
      : props.main?.[tabIndex].mediaShadow;

  const image = imageUrl ? (
    imageUrl.split('.')[imageUrl.split('.').length - 1] === 'svg' ? (
      <MUI.Box width='100%' height='30vw' minHeight='400px' maxHeight='600px'>
        <object height='100%' width='100%' type='image/svg+xml' data={imageUrl}></object>
      </MUI.Box>
    ) : (
      <MUI.Box
        width='100%'
        height='30vw'
        minHeight='400px'
        maxHeight='600px'
        style={
          imageFit === 'cover'
            ? {
                backgroundImage: `url('${imageUrl}')`,
                backgroundPosition: 'center',
                backgroundSize: imageFit,
                backgroundRepeat: 'no-repeat',
                boxShadow: imageShadow ? '1px 3px 10px rgb(0 0 0 / 10%)' : '',
                borderRadius: '12px'
              }
            : {}
        }
        display='flex'
        justifyContent='center'
        alignItems='center'
        position='relative'
      >
        {imageFit === 'contain' && (
          <img
            src={imageUrl}
            alt=''
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              maxHeight: '100%',
              verticalAlign: 'middle',
              boxShadow: imageShadow ? '1px 3px 10px rgb(0 0 0 / 10%)' : '',
              borderRadius: '12px'
            }}
          />
        )}

        <MUI.Box
          position='absolute'
          bottom='12px'
          right='16px'
          color='#fff'
          style={{ opacity: 0.8, textShadow: '1px 1px 3px #000000' }}
        >
          <MUI.Typography variant='caption'>{imageCredit}</MUI.Typography>
        </MUI.Box>
      </MUI.Box>
    )
  ) : null;

  const imageCompare = props.main?.[tabIndex]?.imageCompare ? (
    <ReactCompareSlider
      handle={
        props.main?.[tabIndex]?.imageCompare?.handleColor ? (
          <ReactCompareSliderHandle
            style={{ color: props.main?.[tabIndex]?.imageCompare?.handleColor }}
          />
        ) : undefined
      }
      itemOne={<ReactCompareSliderImage src={props.main?.[tabIndex]?.imageCompare?.image1} />}
      itemTwo={<ReactCompareSliderImage src={props.main?.[tabIndex]?.imageCompare?.image2} />}
      changePositionOnHover
      style={{
        boxShadow: imageShadow ? '1px 3px 10px rgb(0 0 0 / 10%)' : '',
        borderRadius: '12px'
      }}
    />
  ) : null;

  const videoWebmUrl =
    props.more && moreExpanded && props.more[moreTabIndex].videoWebm
      ? props.more[moreTabIndex].videoWebm
      : props.main?.[tabIndex].videoWebm;
  const videoMp4Url =
    props.more && moreExpanded && props.more[moreTabIndex].videoMp4
      ? props.more[moreTabIndex].videoMp4
      : props.main?.[tabIndex].videoMp4;
  const videoShadow =
    props.more &&
    moreExpanded &&
    (props.more[moreTabIndex].videoWebm || props.more[moreTabIndex].videoMp4)
      ? props.more[moreTabIndex].mediaShadow
      : props.main?.[tabIndex].mediaShadow;

  const video =
    videoWebmUrl || videoMp4Url ? (
      <video
        width='100%'
        height='100%'
        autoPlay
        muted
        loop
        style={{
          objectFit: 'cover',
          boxShadow: videoShadow ? '1px 3px 10px rgb(0 0 0 / 10%)' : '',
          borderRadius: '12px'
        }}
      >
        {videoWebmUrl && <source src={videoWebmUrl} type='video/webm' />}
        {videoMp4Url && <source src={videoMp4Url} type='video/webm' />}
      </video>
    ) : null;

  const youtubeVideo = props.youtubeEmbedId ? (
    <MUI.Box maxWidth={hasText ? undefined : '800px'} margin={hasText ? undefined : 'auto'}>
      <YoutubeEmbed
        embedId={props.youtubeEmbedId}
        style={{
          boxShadow: imageShadow ? '1px 3px 10px rgb(0 0 0 / 10%)' : '',
          borderRadius: '12px',
          maxWidth: '100%',
          display: 'block'
        }}
      />
    </MUI.Box>
  ) : null;

  const hasMedia = side || image || imageCompare || video || youtubeVideo;

  return (
    <MUI.Box bgcolor={props.reverse ? undefined : '#f7f7f6'} pt={8} pb={8}>
      <MUI.Container maxWidth='lg'>
        <MUI.Grid
          container
          spacing={10}
          direction={props.reverse ? 'row-reverse' : 'row'}
          justify='space-between'
        >
          {hasText && (
            <MUI.Grid item xs={12} md={hasMedia ? 5 : 12}>
              <MUI.Box display='flex' alignItems='center' height='100%'>
                <MUI.Box width='100%'>
                  <MUI.Box mb={4}>
                    <MUI.Typography variant='h4' color='primary' style={{ fontWeight: 600 }}>
                      {props.title}
                    </MUI.Typography>
                  </MUI.Box>

                  {props.main.length > 1 && (
                    <MUI.Box mt={2} mb={2}>
                      <MUI.Tabs
                        value={tabIndex}
                        onChange={handleTabChange}
                        variant='scrollable'
                        TabScrollButtonProps={{ style: { width: 'initial' } }}
                      >
                        {props.main.map((tab, i) => (
                          <MUI.Tab
                            label={tab.title}
                            value={i}
                            key={i}
                            style={{ minWidth: '100px' }}
                          />
                        ))}
                      </MUI.Tabs>
                    </MUI.Box>
                  )}
                  <MUI.Box>
                    <MUI.Typography
                      variant='body1'
                      color='textSecondary'
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {props.main[tabIndex].description}
                    </MUI.Typography>
                  </MUI.Box>

                  {props.more && (
                    <MUI.Box mt={2}>
                      <MUI.Button
                        onClick={() => setMoreExpanded(!moreExpanded)}
                        variant='outlined'
                        size='small'
                      >
                        More
                      </MUI.Button>
                      <MUI.Collapse in={moreExpanded}>
                        {props.more.length > 1 && (
                          <MUI.Box mt={2}>
                            <MUI.Tabs
                              value={moreTabIndex}
                              onChange={handleMoreTabChange}
                              variant='scrollable'
                              TabScrollButtonProps={{ style: { width: 'initial' } }}
                            >
                              {props.more.map((tab, i) => (
                                <MUI.Tab
                                  label={tab.title}
                                  value={i}
                                  key={i}
                                  style={{ minWidth: '100px' }}
                                />
                              ))}
                            </MUI.Tabs>
                          </MUI.Box>
                        )}
                        <MUI.Box mt={2}>
                          <MUI.Typography
                            variant='body1'
                            color='textSecondary'
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {props.more[moreTabIndex].description}
                          </MUI.Typography>
                        </MUI.Box>
                      </MUI.Collapse>
                    </MUI.Box>
                  )}

                  {props.action && <MUI.Box mt={2}>{props.action}</MUI.Box>}
                </MUI.Box>
              </MUI.Box>
            </MUI.Grid>
          )}

          {hasMedia && (
            <MUI.Grid item xs={12} md={hasText ? 7 : 12}>
              {side}
              {image}
              {imageCompare}
              {video}
              {youtubeVideo}
            </MUI.Grid>
          )}
        </MUI.Grid>
      </MUI.Container>

      {props.slideshowImages && (
        <MUI.Box overflow='hidden' width='100%' mt={6}>
          {renderSlideshow(props.slideshowImages.slice(0, props.slideshowImages.length / 2), false)}
          {renderSlideshow(
            props.slideshowImages.slice(
              props.slideshowImages.length / 2,
              props.slideshowImages.length
            ),
            true
          )}
        </MUI.Box>
      )}
    </MUI.Box>
  );
}
Feature.defaultProps = defaultProps;
