import React from 'react';
import * as MUI from '@material-ui/core';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridSortModel
} from '@mui/x-data-grid';

import OrderDetails from './OrderDetails';
import { paddleProductIdToProductName } from './ResellersPortal';
import { Product, NotifyFunction, webApiCall, parseJwt } from '../Common/utils';

type OrderRow = {
  id: number;
  date: string;
  product: Product;
  quantity: number;
};

const useStyles = MUI.makeStyles((theme) => ({
  row: {
    cursor: 'pointer'
  }
}));

interface Props {
  loading: boolean;
  jwt: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function History(props: Props) {
  const classes = useStyles();

  const [orders, setOrders] = React.useState<OrderRow[] | undefined>(undefined);
  const [selectedOrderId, setSelectedOrderId] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'date',
      sort: 'desc'
    }
  ]);

  const columns = React.useMemo<GridColDef[]>(
    () => [
      { field: 'date', headerName: 'Date', width: 150 },
      { field: 'product', headerName: 'Product', width: 150 },
      { field: 'quantity', headerName: 'Quantity', width: 150 }
    ],
    []
  );

  function DataGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ fileName: 'orders' }} />
      </GridToolbarContainer>
    );
  }

  React.useEffect(() => {
    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `resellers/v1/byuserid/${jwtUserId}/orders`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          const orderRows: OrderRow[] = response.data.data.orders.map((order: any, id: number) => {
            return {
              id: id,
              orderId: order.id,
              date: new Date(order.date).toISOString().slice(0, 10),
              product: paddleProductIdToProductName(order.product),
              quantity: order.quantity
            };
          });

          setOrders(orderRows);
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <MUI.Container maxWidth='lg' style={{ height: '100%' }}>
      {props.loading || loading || !orders ? (
        <MUI.CircularProgress />
      ) : (
        <MUI.Box height='100%' width='100%'>
          <DataGrid
            columns={columns}
            rows={orders}
            onRowClick={(params, e) => setSelectedOrderId(params.row.orderId)}
            components={{ Toolbar: DataGridToolbar }}
            classes={{ row: classes.row }}
            density='compact'
            disableSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </MUI.Box>
      )}

      {selectedOrderId && (
        <OrderDetails
          loading={loading}
          jwt={props.jwt}
          orderId={selectedOrderId}
          onClose={() => setSelectedOrderId(undefined)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}
    </MUI.Container>
  );
}
