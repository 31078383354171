import React from 'react';
import {
  Switch,
  Route,
  NavLink as RouterNavLink,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import Joi from 'joi';
import * as MUI from '@material-ui/core';
import LicenseIcon from 'mdi-material-ui/KeyOutline';
import AccountIcon from 'mdi-material-ui/AccountCircleOutline';
import ResellerPortalIcon from 'mdi-material-ui/CartOutline';
import LogoutIcon from 'mdi-material-ui/Logout';
import DashboardIcon from 'mdi-material-ui/MonitorDashboard';

import { NotifVariant, NotifyFunction, webApiCall, parseJwt, getCookie } from '../Common/utils';
import { AccountRoute } from '../Account';
import { AccountForm } from './AccountForm';
import Licenses from './Licenses';

const useStyles = MUI.makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '240px'
  },
  activeNavLink: {
    backgroundColor: '#eee',
    '& > .MuiListItemText-root > .MuiListItemText-primary': {
      fontWeight: 'bold'
    }
  }
}));

interface Props {
  jwt?: string;
  setJwt: (jwt: string | undefined) => void;
  saveRefreshToken: (refreshToken: string | undefined) => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export function AccountDashboard(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const routeMatch = useRouteMatch();

  const [accessDenied, setAccessDenied] = React.useState(false);

  React.useEffect(() => {
    // Redirect to login form if not logged in
    if (!props.jwt && !getCookie('refreshToken')) {
      history.push({ pathname: `/account/login`, search: `?return=${window.location.href}` });
    }
  }, [props.jwt]);

  const logout = () => {
    props.setJwt(undefined);
    props.saveRefreshToken(undefined);

    history.push({ pathname: `/account/login` });
  };

  return (
    <MUI.Box display='flex'>
      <MUI.Drawer
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <MUI.List>
          <MUI.ListItem
            button
            component={RouterNavLink}
            to='/account/manage'
            exact
            activeClassName={classes.activeNavLink}
          >
            <MUI.ListItemIcon>
              <AccountIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText primary='Account' />
          </MUI.ListItem>

          <MUI.ListItem
            button
            component={RouterNavLink}
            to='/account/manage/licenses'
            exact
            activeClassName={classes.activeNavLink}
          >
            <MUI.ListItemIcon>
              <LicenseIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText primary='Licenses' />
          </MUI.ListItem>

          {props.jwt && parseJwt(props.jwt).userGroups.includes('reseller') && (
            <MUI.ListItem
              button
              component={RouterNavLink}
              to='/resellers/portal'
              exact
              activeClassName={classes.activeNavLink}
            >
              <MUI.ListItemIcon>
                <ResellerPortalIcon />
              </MUI.ListItemIcon>
              <MUI.ListItemText primary='Reseller Portal' />
            </MUI.ListItem>
          )}

          {props.jwt && parseJwt(props.jwt).userGroups.includes('marketplacevendor') && (
            <MUI.ListItem
              button
              component={RouterNavLink}
              to='/vendordashboard'
              exact
              activeClassName={classes.activeNavLink}
            >
              <MUI.ListItemIcon>
                <DashboardIcon />
              </MUI.ListItemIcon>
              <MUI.ListItemText primary='3D Bazaar Dashboard' />
            </MUI.ListItem>
          )}

          {props.jwt && parseJwt(props.jwt).userGroups.includes('roomboxvendor') && (
            <MUI.ListItem
              button
              component={RouterNavLink}
              to='/vendordashboard'
              exact
              activeClassName={classes.activeNavLink}
            >
              <MUI.ListItemIcon>
                <DashboardIcon />
              </MUI.ListItemIcon>
              <MUI.ListItemText primary='RoomBox Dashboard' />
            </MUI.ListItem>
          )}

          <MUI.ListItem button onClick={logout}>
            <MUI.ListItemIcon>
              <LogoutIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText primary='Log out' />
          </MUI.ListItem>
        </MUI.List>
      </MUI.Drawer>

      <MUI.Box flexGrow={1} minHeight='800px' p='32px'>
        <Switch>
          <Route path='/account/manage' exact>
            <AccountForm
              currentRoute={AccountRoute.MANAGE}
              jwt={props.jwt}
              setJwt={props.setJwt}
              saveRefreshToken={props.saveRefreshToken}
              notify={props.notify}
              setMaintenanceMode={props.setMaintenanceMode}
            />
          </Route>

          <Route path='/account/manage/licenses'>
            <Licenses
              jwt={props.jwt}
              notify={props.notify}
              setMaintenanceMode={props.setMaintenanceMode}
            />
          </Route>
        </Switch>
      </MUI.Box>
    </MUI.Box>
  );
}
