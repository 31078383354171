import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import * as MUI from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import lindaleLogoTextInvert from '../media/logos/lindale_logo_text_invert.svg';
import bazaarLogoSmall from '../media/logos/3dbazaar_logo_small.svg';
import skatterLogoSmall from '../media/logos/skatter_logo_32.png';
import transmutrLogoSmall from '../media/logos/transmutr_logo_small.svg';
import roomboxLogoSmall from '../media/logos/roombox_logo.svg';

interface Props {}

const useStyles = MUI.makeStyles({
  menu: {
    minWidth: '150px',
    boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.25)'
  },
  navButton: {
    marginRight: 6,
    marginLeft: 24,
    fontWeight: 400
  },
  navButtonLabel: {
    // Underline animation
    '&:after': {
      bottom: 0,
      content: '""',
      display: 'block',
      height: 2,
      backgroundColor: 'lightgrey',
      transition: 'width 0.1s ease 0s, left 0.1s ease 0s',
      position: 'relative',
      left: '50%',
      width: 0
    },
    '&.selected:after, &:hover:after': {
      left: 0,
      width: '100%'
    }
  },
  navButtonLogo: {
    backgroundColor: '#fff',
    padding: '4px',
    height: '36px',
    width: '36px',
    borderRadius: '3px'
  },
  navMenuLink: {
    color: 'inherit',
    textDecoration: 'none'
  },
  navMenuLogo: {
    backgroundColor: 'transparent',
    padding: '4px',
    height: '36px',
    width: '36px',
    marginRight: '8px'
  },
  navMenuLabel: {
    fontWeight: 400
  }
});

export default function Header(props: Props) {
  const theme = MUI.useTheme();
  const classes = useStyles();
  const location = useLocation();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

  const productButtons = (
    <>
      <MUI.Button
        classes={{ root: classes.navButton }}
        component={RouterLink}
        to='/skatter'
        color='inherit'
        startIcon={<MUI.Avatar src={skatterLogoSmall} className={classes.navButtonLogo} />}
        onClick={() => setMobileDrawerOpen(false)}
      >
        <span
          className={classnames(classes.navButtonLabel, {
            selected: location.pathname.startsWith('/skatter')
          })}
        >
          Skatter
        </span>
      </MUI.Button>

      <MUI.Button
        classes={{ root: classes.navButton }}
        component={RouterLink}
        to='/transmutr'
        color='inherit'
        startIcon={<MUI.Avatar src={transmutrLogoSmall} className={classes.navButtonLogo} />}
        onClick={() => setMobileDrawerOpen(false)}
      >
        <span
          className={classnames(classes.navButtonLabel, {
            selected: location.pathname.startsWith('/transmutr')
          })}
        >
          Transmutr
        </span>
      </MUI.Button>

      <MUI.Button
        classes={{ root: classes.navButton }}
        component={RouterLink}
        to='/roombox'
        color='inherit'
        startIcon={<MUI.Avatar src={roomboxLogoSmall} className={classes.navButtonLogo} />}
        onClick={() => setMobileDrawerOpen(false)}
      >
        <span
          className={classnames(classes.navButtonLabel, {
            selected: location.pathname.startsWith('/roombox')
          })}
        >
          RoomBox
        </span>
      </MUI.Button>

      <MUI.Button
        classes={{ root: classes.navButton }}
        component={RouterLink}
        to='/3dbazaar'
        color='inherit'
        startIcon={<MUI.Avatar src={bazaarLogoSmall} className={classes.navButtonLogo} />}
        onClick={() => setMobileDrawerOpen(false)}
      >
        <span
          className={classnames(classes.navButtonLabel, {
            selected: location.pathname.startsWith('/3dbazaar')
          })}
        >
          3D Bazaar
        </span>
      </MUI.Button>
    </>
  );

  const productMenuEntries = [
    <RouterLink to='/skatter' className={classes.navMenuLink} key='skatter'>
      <MUI.MenuItem>
        <MUI.Avatar src={skatterLogoSmall} className={classes.navMenuLogo} />
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          Skatter
        </MUI.Typography>
      </MUI.MenuItem>
    </RouterLink>,
    <RouterLink to='/transmutr' className={classes.navMenuLink} key='transmutr'>
      <MUI.MenuItem>
        <MUI.Avatar src={transmutrLogoSmall} className={classes.navMenuLogo} />
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          Transmutr
        </MUI.Typography>
      </MUI.MenuItem>
    </RouterLink>,
    <RouterLink to='/roombox' className={classes.navMenuLink} key='roombox'>
      <MUI.MenuItem>
        <MUI.Avatar src={roomboxLogoSmall} className={classes.navMenuLogo} />
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          RoomBox
        </MUI.Typography>
      </MUI.MenuItem>
    </RouterLink>,
    <RouterLink to='/3dbazaar' className={classes.navMenuLink} key='3dbazaar'>
      <MUI.MenuItem>
        <MUI.Avatar src={bazaarLogoSmall} className={classes.navMenuLogo} />
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          3D Bazaar
        </MUI.Typography>
      </MUI.MenuItem>
    </RouterLink>
  ];

  const navMenuEntries = [
    <RouterLink
      to='/support'
      className={classes.navMenuLink}
      onClick={handleMenuClose}
      key='support'
    >
      <MUI.MenuItem>
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          Help Center
        </MUI.Typography>
      </MUI.MenuItem>
    </RouterLink>,
    <MUI.Link
      href='https://forums.lindale.io'
      className={classes.navMenuLink}
      underline='none'
      key='forums'
    >
      <MUI.MenuItem>
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          Forums
        </MUI.Typography>
      </MUI.MenuItem>
    </MUI.Link>,
    <RouterLink
      to='/account/manage'
      className={classes.navMenuLink}
      onClick={handleMenuClose}
      key='account'
    >
      <MUI.MenuItem>
        <MUI.Typography variant='button' className={classes.navMenuLabel}>
          My Account
        </MUI.Typography>
      </MUI.MenuItem>
    </RouterLink>
  ];

  return (
    <MUI.AppBar
      position='static'
      style={{ color: theme.palette.primary.contrastText }}
      elevation={0}
    >
      <MUI.Toolbar>
        <MUI.Box display='flex' flex={1} alignItems='center'>
          <MUI.Box display='flex'>
            <MUI.Link component={RouterLink} to='/'>
              <img
                src={lindaleLogoTextInvert}
                alt='Lindalë logo'
                style={{ height: theme.spacing(4) }}
              />
            </MUI.Link>
          </MUI.Box>

          <MUI.Box display='flex' flex={1} justifyContent='flex-end'>
            <MUI.Hidden smDown>
              {productButtons}
              <MUI.IconButton color='inherit' onClick={handleMenuClick}>
                <MenuIcon />
              </MUI.IconButton>
              <MUI.Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleMenuClose}
                classes={{ paper: classes.menu }}
                elevation={0}
              >
                {navMenuEntries}
              </MUI.Menu>
            </MUI.Hidden>

            <MUI.Hidden mdUp>
              <MUI.IconButton color='inherit' onClick={() => setMobileDrawerOpen(true)}>
                <MenuIcon />
              </MUI.IconButton>
              <MUI.Drawer
                anchor='top'
                open={mobileDrawerOpen}
                onClose={() => setMobileDrawerOpen(false)}
              >
                {productMenuEntries}
                {navMenuEntries}
              </MUI.Drawer>
            </MUI.Hidden>
          </MUI.Box>
        </MUI.Box>
      </MUI.Toolbar>
    </MUI.AppBar>
  );
}
