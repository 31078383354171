import React from 'react';
import { Switch, Route, useRouteMatch, Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import * as MUI from '@material-ui/core';

import { NotifVariant, NotifyFunction, webApiCall, parseJwt, getCookie } from './Common/utils';
import { AccountForm } from './Account/AccountForm';
import { AccountDashboard } from './Account/AccountDashboard';

export enum AccountRoute {
  MANAGE = 'manage',
  FORGOTPASSWORD = 'forgotpassword',
  LOGIN = 'login',
  REGISTER = 'register',
  RESETPASSWORD = 'resetpassword',
  VERIFY = 'verify'
}

const useStyles = MUI.makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    height: theme.spacing(8),
    width: theme.spacing(8)
  }
}));

interface Props {
  jwt?: string;
  setJwt: (jwt: string | undefined) => void;
  saveRefreshToken: (refreshToken: string | undefined) => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Account(props: Props) {
  const classes = useStyles();
  const routeMatch = useRouteMatch();

  return (
    <MUI.Box pb={8}>
      <Switch>
        <Route path={`${routeMatch.path}/login`}>
          <Helmet>
            <title>Lindalë - Login</title>
          </Helmet>
          <AccountForm
            currentRoute={AccountRoute.LOGIN}
            jwt={props.jwt}
            setJwt={props.setJwt}
            saveRefreshToken={props.saveRefreshToken}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>

        <Route path={`${routeMatch.path}/register`}>
          <Helmet>
            <title>Lindalë - Register</title>
          </Helmet>
          <AccountForm
            currentRoute={AccountRoute.REGISTER}
            jwt={props.jwt}
            setJwt={props.setJwt}
            saveRefreshToken={props.saveRefreshToken}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>

        <Route path={`${routeMatch.path}/forgotpassword`}>
          <Helmet>
            <title>Lindalë - Forgot password</title>
          </Helmet>
          <AccountForm
            currentRoute={AccountRoute.FORGOTPASSWORD}
            jwt={props.jwt}
            setJwt={props.setJwt}
            saveRefreshToken={props.saveRefreshToken}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>

        <Route path={`${routeMatch.path}/resetpassword/:resetPasswordToken`}>
          <Helmet>
            <title>Lindalë - Reset password</title>
          </Helmet>
          <AccountForm
            currentRoute={AccountRoute.RESETPASSWORD}
            jwt={props.jwt}
            setJwt={props.setJwt}
            saveRefreshToken={props.saveRefreshToken}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>

        <Route path={`${routeMatch.path}/verify/:verificationKey`}>
          <Helmet>
            <title>Lindalë - Verify account</title>
          </Helmet>
          <AccountForm
            currentRoute={AccountRoute.VERIFY}
            jwt={props.jwt}
            setJwt={props.setJwt}
            saveRefreshToken={props.saveRefreshToken}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>

        <Route path={`${routeMatch.path}/manage`}>
          <Helmet>
            <title>Lindalë - Manage account</title>
          </Helmet>
          <AccountDashboard
            jwt={props.jwt}
            setJwt={props.setJwt}
            saveRefreshToken={props.saveRefreshToken}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </Route>
      </Switch>
    </MUI.Box>
  );
}
