import React from 'react';
import * as MUI from '@material-ui/core';

import { BillingInterval, ProductPrices } from './ResellersPortal';
import PriceDetails from './PriceDetails';
import { Product, LicenseType, webApiCall, NotifyFunction } from '../Common/utils';

const useStyles = MUI.makeStyles((theme) => ({
  select: {
    marginTop: '32px'
  }
}));

interface Props {
  loading: boolean;
  jwt: string;
  currency: string;
  prices: ProductPrices[Product.SKATTER2];
  discountList: { [key: number]: number };
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function SkatterOrderForm(props: Props) {
  const classes = useStyles();

  const [licenseType, setLicenseType] = React.useState<LicenseType>(LicenseType.NODELOCKED);
  const [billingInterval, setBillingInterval] = React.useState<BillingInterval>(
    BillingInterval.PERPETUAL
  );
  const [quantity, setQuantity] = React.useState(1);
  const [seats, setSeats] = React.useState(1);

  const [waiting, setWaiting] = React.useState(false);

  let price: number | undefined = undefined;
  if (licenseType === LicenseType.NODELOCKED) {
    if (billingInterval === BillingInterval.PERPETUAL) {
      price = props.prices[LicenseType.NODELOCKED][BillingInterval.PERPETUAL][props.currency];
    } else if (billingInterval === BillingInterval.YEARLY) {
      price = props.prices[LicenseType.NODELOCKED][BillingInterval.YEARLY][props.currency];
    }
  } else if (licenseType === LicenseType.FLOATING) {
    price = props.prices[LicenseType.FLOATING][BillingInterval.YEARLY][props.currency];
  }

  const quantityDiscount = props.discountList[quantity];
  const priceMultiplier = 1 - quantityDiscount / 100;

  React.useEffect(() => {
    if (licenseType === LicenseType.NODELOCKED) {
      setBillingInterval(BillingInterval.PERPETUAL);
    } else if (licenseType === LicenseType.FLOATING) {
      setBillingInterval(BillingInterval.YEARLY);
    }
    setQuantity(1);
    setSeats(1);
  }, [licenseType]);

  React.useEffect(() => {
    setQuantity(1);
  }, [billingInterval]);

  const submit = React.useCallback(() => {
    setWaiting(true);
    webApiCall({
      method: 'post',
      endpoint: `resellers/v1/checkoutlink/`,
      data: {
        product: Product.SKATTER2,
        licenseType: licenseType,
        billingInterval: billingInterval,
        quantity: quantity,
        seats: seats
      },
      jwt: props.jwt,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        // @ts-ignore
        Paddle.Checkout.open({
          override: response.data.data.url
        });
      },
      ensureCallback: () => {
        setWaiting(false);
      }
    });
  }, [
    props.jwt,
    props.notify,
    props.setMaintenanceMode,
    licenseType,
    billingInterval,
    quantity,
    seats
  ]);

  return (
    <MUI.Box>
      <MUI.TextField
        value={licenseType}
        onChange={(e) => setLicenseType(e.target.value as LicenseType)}
        label='License Type'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
        disabled={waiting}
      >
        <MUI.MenuItem value={LicenseType.NODELOCKED}>Fixed-seat (node-locked)</MUI.MenuItem>
        <MUI.MenuItem value={LicenseType.FLOATING}>Floating</MUI.MenuItem>
      </MUI.TextField>

      <MUI.TextField
        value={billingInterval}
        onChange={(e) => setBillingInterval(e.target.value as BillingInterval)}
        disabled={waiting || licenseType === LicenseType.FLOATING}
        label='Billing Interval'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      >
        <MUI.MenuItem value={BillingInterval.PERPETUAL}>Perpetual</MUI.MenuItem>
        <MUI.MenuItem value={BillingInterval.YEARLY}>Annual</MUI.MenuItem>
      </MUI.TextField>

      <MUI.TextField
        value={quantity}
        onChange={(e) => setQuantity(e.target.value as unknown as number)}
        disabled={
          waiting ||
          licenseType !== LicenseType.NODELOCKED ||
          billingInterval !== BillingInterval.PERPETUAL
        }
        label='Quantity'
        helperText='You get discounts by buying in bulk to constitute a stock'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      >
        <MUI.MenuItem value={1}>1</MUI.MenuItem>
        <MUI.MenuItem value={10}>10</MUI.MenuItem>
        <MUI.MenuItem value={20}>20</MUI.MenuItem>
        <MUI.MenuItem value={50}>50</MUI.MenuItem>
        <MUI.MenuItem value={100}>100</MUI.MenuItem>
      </MUI.TextField>

      <MUI.TextField
        value={seats}
        onChange={(e) => setSeats(e.target.value as unknown as number)}
        disabled={waiting || licenseType !== LicenseType.FLOATING}
        label='Seats per license'
        type='number'
        inputProps={{ min: 1 }}
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      />

      {price && (
        <PriceDetails
          price={price}
          recurringPrice={billingInterval === BillingInterval.YEARLY ? price : undefined}
          priceMultiplier={priceMultiplier}
          currency={props.currency}
          quantity={quantity}
          seats={seats}
          waiting={waiting}
          submit={submit}
        />
      )}
    </MUI.Box>
  );
}
