import Darchviz01 from '../../media/skatter/gallery/Darchviz 01.jpg';
import Darchviz01Thumb from '../../media/skatter/gallery/thumbs/Darchviz 01.jpg';

import Darchviz05 from '../../media/skatter/gallery/Darchviz 05.jpg';
import Darchviz05Thumb from '../../media/skatter/gallery/thumbs/Darchviz 05.jpg';

import Darchviz06 from '../../media/skatter/gallery/Darchviz 06.jpg';
import Darchviz06Thumb from '../../media/skatter/gallery/thumbs/Darchviz 06.jpg';

import Darchviz13 from '../../media/skatter/gallery/Darchviz 13.jpg';
import Darchviz13Thumb from '../../media/skatter/gallery/thumbs/Darchviz 13.jpg';

import Darchviz15 from '../../media/skatter/gallery/Darchviz 15.jpg';
import Darchviz15Thumb from '../../media/skatter/gallery/thumbs/Darchviz 15.jpg';

import GustavoPessini01 from '../../media/skatter/gallery/Gustavo Pessini‎ 01.jpg';
import GustavoPessini01Thumb from '../../media/skatter/gallery/thumbs/Gustavo Pessini‎ 01.jpg';

import LePhuQuoc01 from '../../media/skatter/gallery/Le Phu Quoc‎ 01.jpg';
import LePhuQuoc01Thumb from '../../media/skatter/gallery/thumbs/Le Phu Quoc‎ 01.jpg';

import MiguelHernandez01 from '../../media/skatter/gallery/Miguel Cárdenas Hernández‎ 01.jpg';
import MiguelHernandez01Thumb from '../../media/skatter/gallery/thumbs/Miguel Cárdenas Hernández‎ 01.jpg';

import ToTienVu02 from '../../media/skatter/gallery/Tô Tiến Vũ‎ 02.jpg';
import ToTienVu02Thumb from '../../media/skatter/gallery/thumbs/Tô Tiến Vũ‎ 02.jpg';

import TomBurkewitz01 from '../../media/skatter/gallery/Tom Burkewitz‎ 01.jpg';
import TomBurkewitz01Thumb from '../../media/skatter/gallery/thumbs/Tom Burkewitz‎ 01.jpg';

import TomBurkewitz02 from '../../media/skatter/gallery/Tom Burkewitz‎ 02.jpg';
import TomBurkewitz02Thumb from '../../media/skatter/gallery/thumbs/Tom Burkewitz‎ 02.jpg';

import TomaszZalewski from '../../media/skatter/gallery/Tomasz_Zalewski.jpg';
import TomaszZalewskiThumb from '../../media/skatter/gallery/thumbs/Tomasz_Zalewski.jpg';

export const GalleryImages = [
  { credit: '© Darchviz', image: Darchviz01, thumb: Darchviz01Thumb },
  { credit: '© Darchviz', image: Darchviz05, thumb: Darchviz05Thumb },
  { credit: '© Darchviz', image: Darchviz06, thumb: Darchviz06Thumb },
  { credit: '© Darchviz', image: Darchviz13, thumb: Darchviz13Thumb },
  { credit: '© Darchviz', image: Darchviz15, thumb: Darchviz15Thumb },
  { credit: '© Gustavo Pessini', image: GustavoPessini01, thumb: GustavoPessini01Thumb },
  { credit: '© Le Phu Quoc', image: LePhuQuoc01, thumb: LePhuQuoc01Thumb },
  {
    credit: '© Miguel Cárdenas Hernández',
    image: MiguelHernandez01,
    thumb: MiguelHernandez01Thumb
  },
  { credit: '© Tô Tiến Vũ', image: ToTienVu02, thumb: ToTienVu02Thumb },
  { credit: '© Tom Burkewitz', image: TomBurkewitz01, thumb: TomBurkewitz01Thumb },
  { credit: '© Tom Burkewitz', image: TomBurkewitz02, thumb: TomBurkewitz02Thumb },
  { credit: '© Tomasz Zalewski', image: TomaszZalewski, thumb: TomaszZalewskiThumb }
];
