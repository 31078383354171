import React from 'react';
import * as MUI from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { NotifyFunction, webApiCall } from '../Common/utils';

type Reseller = {
  territory: string;
  name: string;
  url: string;
  email: string;
};

interface Props {
  onClose: () => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

function SearchResellerDialog(props: Props) {
  const [resellers, setResellers] = React.useState<Reseller[]>([]);
  const [busy, setBusy] = React.useState(true);
  const [country, setCountry] = React.useState('');

  React.useEffect(() => {
    webApiCall({
      method: 'get',
      endpoint: `resellers/v1`,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        setResellers(response.data.data.resellers);
        setBusy(false);
      }
    });
  }, []);

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry(event.target.value);
  };

  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='md' fullWidth>
      <MUI.DialogTitle>Find a reseller</MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          {busy ? (
            <MUI.CircularProgress />
          ) : (
            <MUI.Box>
              <MUI.Box>
                <MUI.TextField
                  value={country}
                  placeholder='Country'
                  InputProps={{
                    startAdornment: (
                      <MUI.InputAdornment position='start'>
                        <SearchIcon />
                      </MUI.InputAdornment>
                    )
                  }}
                  variant='outlined'
                  fullWidth
                  style={{ marginBottom: '40px' }}
                  onChange={handleCountryChange}
                ></MUI.TextField>
              </MUI.Box>
              {resellers
                .filter((reseller) =>
                  reseller.territory.toLowerCase().includes(country.toLowerCase())
                )
                .map((reseller) => (
                  <MUI.Box>
                    <MUI.Typography variant='body1' style={{ fontWeight: 600 }}>
                      {reseller.name}
                    </MUI.Typography>
                    <MUI.Typography variant='body1'>{reseller.territory}</MUI.Typography>
                    <div>
                      <MUI.Link href={reseller.url}>{reseller.url}</MUI.Link>
                    </div>
                    <div>
                      <MUI.Link href={`mailto:${reseller.email}`}>{reseller.email}</MUI.Link>
                    </div>
                    <MUI.Divider style={{ marginTop: '16px', marginBottom: '16px' }} />
                  </MUI.Box>
                ))}
            </MUI.Box>
          )}
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(SearchResellerDialog);
