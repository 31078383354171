import React from 'react';
import { useSnackbar } from 'notistack';
import * as MUI from '@material-ui/core';
import OrderIcon from 'mdi-material-ui/CartOutline';

import { BillingInterval, ProductPrices } from './ResellersPortal';
import PriceDetails from './PriceDetails';
import { Product, LicenseType, webApiCall, NotifyFunction } from '../Common/utils';

const useStyles = MUI.makeStyles((theme) => ({
  select: {
    marginTop: '32px'
  }
}));

interface Props {
  loading: boolean;
  jwt: string;
  currency: string;
  prices: ProductPrices[Product.TRANSMUTR_STUDIO];
  discountList: { [key: number]: number };
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function TransmutrStudioOrderForm(props: Props) {
  const classes = useStyles();

  const [seats, setSeats] = React.useState(1);

  const [waiting, setWaiting] = React.useState(false);

  let price = props.prices[LicenseType.FLOATING][BillingInterval.YEARLY][props.currency];

  const quantityDiscount = props.discountList[1];
  const priceMultiplier = 1 - quantityDiscount / 100;

  const submit = React.useCallback(() => {
    setWaiting(true);
    webApiCall({
      method: 'post',
      endpoint: `resellers/v1/checkoutlink/`,
      data: {
        product: Product.TRANSMUTR_STUDIO,
        licenseType: LicenseType.FLOATING,
        billingInterval: BillingInterval.YEARLY,
        quantity: 1,
        seats: seats
      },
      jwt: props.jwt,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        // @ts-ignore
        Paddle.Checkout.open({
          override: response.data.data.url
        });
      },
      ensureCallback: () => {
        setWaiting(false);
      }
    });
  }, [props.jwt, props.notify, props.setMaintenanceMode, seats]);

  return (
    <MUI.Box>
      <MUI.Box mt='32px'>
        <i>
          Transmutr Studio licenses are only available as floating licenses and annual
          subscriptions.
        </i>
      </MUI.Box>

      <MUI.TextField
        value={seats}
        onChange={(e) => setSeats(e.target.value as unknown as number)}
        disabled={waiting}
        label='Seats per license'
        type='number'
        inputProps={{ min: 1 }}
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      >
        <MUI.MenuItem value={1}>1</MUI.MenuItem>
      </MUI.TextField>

      {price && (
        <PriceDetails
          price={price}
          recurringPrice={price}
          priceMultiplier={priceMultiplier}
          currency={props.currency}
          quantity={1}
          seats={seats}
          waiting={waiting}
          submit={submit}
        />
      )}
    </MUI.Box>
  );
}
