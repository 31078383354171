import React from 'react';
import {
  Switch,
  Route,
  Link as RouterLink,
  useHistory,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@material-ui/core';

import Feature from './Common/Feature';
import Jumbotron from './Common/Jumbotron';
import ProductHeader from './Common/ProductHeader';
import ColorBand from './Common/ColorBand';
import TransmutrPriceTable from './Transmutr/TransmutrPriceTable';
import TransmutrDownloadOverlay from './Transmutr/TransmutrDownloadOverlay';
import { useScrollToTop, NotifyFunction } from './Common/utils';
import { Download as DownloadIcon } from 'mdi-material-ui';

import TransmutrLogoText from '../media/logos/transmutr_logo_text_filled.svg';
import TransmutrLogoTextSmall from '../media/logos/transmutr_logo_medium_text_filled.svg';
import VrayLogo from '../media/logos/vray_logo_white.svg';
import EnscapeLogo from '../media/logos/enscape_logo_white.svg';
import TheaLogo from '../media/logos/thea_logo_white.svg';
import MaterialConversionImage from '../media/transmutr/materials_conversion.png';
import ProxiesImage from '../media/transmutr/proxies.png';
import PlaceholdersImage from '../media/transmutr/placeholders_lr.jpg';
import SimplificationImage from '../media/transmutr/simplification.gif';
import MegascansImage from '../media/transmutr/megascans.gif';
import TransformsImage from '../media/transmutr/transforms.gif';
import HierarchyImage from '../media/transmutr/hierarchy.png';
import EditComponentImage from '../media/transmutr/edit_component.gif';
import AutomationsImage from '../media/transmutr/automations.jpg';
import CliImage from '../media/transmutr/cli.gif';

export const theme = MUI.createTheme({
  palette: {
    primary: {
      light: '#74ffff',
      main: '#2dcce6',
      dark: '#009bb4',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#74ffff',
      main: '#2dcce6',
      dark: '#009bb4',
      contrastText: '#ffffff'
    }
  }
});

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

const useStyles = MUI.makeStyles({
  partnerLogo: {
    margin: 16,
    '& img': {
      height: 60
    }
  }
});

export default function Transmutr(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  useScrollToTop(location.pathname);

  return (
    <MUI.MuiThemeProvider theme={theme}>
      <MUI.Box style={{ position: 'relative' }}>
        <Helmet>
          <title>Lindalë - Transmutr</title>
        </Helmet>

        <Jumbotron
          height='medium'
          logo={
            <img
              src={TransmutrLogoText}
              alt='Transmutr logo'
              style={{
                maxWidth: '400px',
                maxHeight: '100px'
              }}
            />
          }
          subtitle='SketchUp importer on steroids'
          description={
            <>
              Convert various 3D formats to SketchUp files, with powerful features such as automated{' '}
              <b>render-ready materials and proxies, geometry simplification</b>, as well as
              essential options like <b>scaling, unit conversion, axes/origin transformations</b>{' '}
              and much more.
            </>
          }
          color='#868686'
          subtitleFontWeight={400}
          action={
            <MUI.Button
              variant='contained'
              color='primary'
              size='large'
              component={RouterLink}
              to={`${routeMatch.path}?try`}
              startIcon={<DownloadIcon />}
            >
              Download trial
            </MUI.Button>
          }
        />

        <ProductHeader
          logo={TransmutrLogoTextSmall}
          buttons={[
            {
              label: 'Overview',
              to: '/transmutr#overview',
              color: 'inherit'
            },
            {
              label: 'Studio',
              to: '/transmutr#studio',
              color: 'inherit'
            },
            {
              label: 'Help',
              to: '/support?product=transmutr',
              color: 'inherit'
            },
            {
              label: 'Try',
              to: '/transmutr?try',
              color: 'primary',
              variant: 'outlined'
            },
            {
              label: 'Buy',
              to: '/transmutr#buy',
              color: 'primary',
              variant: 'contained'
            }
          ]}
        ></ProductHeader>

        <MUI.Box id='overview'>
          <Feature
            youtubeEmbedId='LfPZUzJyoME'
            main={[
              {
                mediaShadow: true
              }
            ]}
          />

          <Feature
            title='Automated render-ready materials'
            main={[
              {
                description: (
                  <>
                    Automatically convert render-ready materials or tweak them with just a few
                    clicks.
                  </>
                ),
                image: MaterialConversionImage
              }
            ]}
            more={[
              {
                title: '',
                description: (
                  <>
                    Transmutr extracts as much data as possible from the source file to
                    automatically create render-ready materials. This saves you from tedious manual
                    work to recreate materials that were already made in the source software
                    (3dsmax, etc).
                    <br />
                    Of course, you can easily edit and tweak the materials by adding maps or
                    adjusting the main values.
                    <br />
                    <br />
                    <MUI.Link
                      href='https://help.transmutr.com/manual/materials-tab'
                      target='_blank'
                    >
                      Learn More
                    </MUI.Link>
                  </>
                )
              }
            ]}
          />

          <Feature
            title='Automated proxies'
            main={[
              {
                description: (
                  <>
                    With a single click, Transmutr can generate proxies for V-Ray, Thea and Enscape.
                  </>
                ),
                image: ProxiesImage
              }
            ]}
            more={[
              {
                title: '',
                description: (
                  <>
                    For the placeholder, you can choose between various options, like Bounding Box,
                    Face Skipping, Simplified Geometry, Billboard, etc.
                    <MUI.Box width='100%'>
                      <img width='100%' src={PlaceholdersImage}></img>
                    </MUI.Box>
                  </>
                )
              }
            ]}
            reverse
          />

          <Feature
            title='Geometry simplification'
            main={[
              {
                description: (
                  <>
                    Simplify high-poly objects with millions of faces down to a few thousands or
                    hundreds.
                  </>
                ),
                image: SimplificationImage,
                mediaShadow: true
              }
            ]}
            more={[
              {
                title: '',
                description: (
                  <>
                    As SketchUp cannot handle very high-poly objects, Transmutr can drastically
                    reduce the number of faces, while preserving the overall shape of the original
                    object.
                    <br />
                    You can fine-tune the simplification level to get exactly the result you want.
                  </>
                )
              }
            ]}
          />

          <Feature
            title='Direct link with Quixel Megascans Bridge'
            main={[
              {
                description: (
                  <>
                    With a couple of clicks, you can send Megascans assets from Quixel Bridge to
                    SketchUp.
                  </>
                ),
                image: MegascansImage,
                mediaShadow: true
              }
            ]}
            more={[
              {
                title: '',
                description: (
                  <>
                    Transmutr will make sure everything is converted correctly, you will get all the
                    materials settings set up in the Megascans asset, down to the perfect amount of
                    reflection or displacement.
                  </>
                )
              }
            ]}
            reverse
          />

          <Feature
            title='Adjust units, scale and axes'
            main={[
              {
                description: (
                  <>
                    Scale the model, adjust the origin or change the Up Axis with just a couple
                    clicks..
                  </>
                ),
                image: TransformsImage,
                mediaShadow: true
              }
            ]}
          />

          <Feature
            title='Clean model hierarchy'
            main={[
              {
                description: (
                  <>
                    Transmutr keeps the model hierarchy, while simplifying it to avoid redundent
                    nesting.
                  </>
                ),
                image: HierarchyImage
              }
            ]}
            more={[
              {
                title: '',
                description: (
                  <>
                    Transmutr supports instancing, and will generate components in this case.
                    Otherwise, it will generate groups.
                  </>
                )
              }
            ]}
            reverse
          />

          <Feature
            title='Simplify existing SketchUp objects'
            main={[
              {
                description: (
                  <>
                    You can edit an existing component or group directly from within SketchUp, for
                    instance if you want to simplify its geometry.
                  </>
                ),
                image: EditComponentImage,
                mediaShadow: true
              }
            ]}
          />
        </MUI.Box>

        <MUI.Box id='studio'>
          <ColorBand>
            <MUI.Box>
              <MUI.Typography variant='h5'>
                With the Studio version, convert hundreds of files at once by combining Material
                Automations and the Command-Line Interface
              </MUI.Typography>
            </MUI.Box>
            <MUI.Box mt={2}>
              <MUI.Link
                href='https://www.youtube.com/watch?v=kBVzmg8eNnc'
                target='_blank'
                color='inherit'
              >
                <b>Click here to learn more</b>
              </MUI.Link>
            </MUI.Box>
          </ColorBand>
        </MUI.Box>

        <MUI.Box>
          <Feature
            title='Material Automations'
            main={[
              {
                description: (
                  <>
                    <i>Studio only</i> - Automations are user-defined rules that allow you to
                    automatically and instantly edit multiple materials at once.
                    <br />
                    <br />
                    <MUI.Link
                      href='https://help.transmutr.com/manual/materials-tab/automations'
                      target='_blank'
                    >
                      Learn More
                    </MUI.Link>
                  </>
                ),
                image: AutomationsImage,
                mediaShadow: true
              }
            ]}
            reverse
          />

          <Feature
            title='Command-Line Interface'
            main={[
              {
                description: (
                  <>
                    <i>Studio only</i> - The Command-Line Interface is a very powerful tool that
                    allows users to easily process large sets of models.
                    <br />
                    <br />
                    <MUI.Link
                      href='https://help.transmutr.com/manual/command-line-interface'
                      target='_blank'
                    >
                      Learn More
                    </MUI.Link>
                  </>
                ),
                image: CliImage,
                mediaShadow: true
              }
            ]}
          />
        </MUI.Box>

        <ColorBand>
          <MUI.Box>
            <MUI.Typography variant='h5'>
              All render engines can benefit from Transmutr!
            </MUI.Typography>
          </MUI.Box>

          <MUI.Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexWrap='wrap'
            mt={2}
          >
            <MUI.Box className={classes.partnerLogo}>
              <img src={VrayLogo} alt='V-Ray logo' />
            </MUI.Box>
            <MUI.Box className={classes.partnerLogo}>
              <img src={EnscapeLogo} alt='Enscape logo' />
            </MUI.Box>
            <MUI.Box className={classes.partnerLogo}>
              <img src={TheaLogo} alt='Thea logo' />
            </MUI.Box>
          </MUI.Box>

          <MUI.Box mt={2}>
            <b>
              Transmutr generates render-ready materials and proxies for V-Ray, Thea and Enscape.
              But if you use another render engine, you can still take advantage of Transmutr's
              clean files, geometry simplification, direct link with Megascans, etc!
            </b>
          </MUI.Box>
        </ColorBand>

        <MUI.Box id='buy'>
          <MUI.Box mt={4} textAlign='center'>
            <MUI.Typography variant='h4' color='textSecondary'>
              Buy Transmutr
            </MUI.Typography>
          </MUI.Box>

          <MUI.Box mt={4} textAlign='center'>
            <MUI.Button
              variant='contained'
              color='primary'
              component={RouterLink}
              to={`${routeMatch.path}?try`}
            >
              Download 7 days trial
            </MUI.Button>
          </MUI.Box>

          <TransmutrPriceTable
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        </MUI.Box>

        {location.search === '?download' && (
          <TransmutrDownloadOverlay
            onClose={() => history.replace(routeMatch.path)}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
        {location.search === '?try' && (
          <TransmutrDownloadOverlay
            trial
            onClose={() => history.replace(routeMatch.path)}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
      </MUI.Box>
    </MUI.MuiThemeProvider>
  );
}
