import React from 'react';
import * as MUI from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { NotifyFunction, printWebApiError, webApiCall } from '../Common/utils';

interface Props {
  licenseKey?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
  onUpgradeSuccessful: () => void;
  onClose: () => void;
}

function TransmutrStudioUpgradeOverlay(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [simulationMessage, setSimulationMessage] = React.useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [upgradeSuccessful, setUpgradeSuccessful] = React.useState(false);

  const fetchSimulatedUpgrade = React.useCallback(() => {
    if (props.licenseKey) {
      setLoading(true);
      webApiCall({
        method: 'patch',
        endpoint: `payments/v1/licensetier/${props.licenseKey}/transmutrstudio`,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setSimulationMessage(response.data.data.simulationMessage);
        },
        errorCallback: (response, message) => {
          if (message) {
            setErrorMessage(message);
          }
        },
        ensureCallback: () => {
          setLoading(false);
        }
      });
    }
  }, [props.licenseKey, props.notify, props.setMaintenanceMode]);

  React.useEffect(() => {
    fetchSimulatedUpgrade();
  }, [fetchSimulatedUpgrade, props.licenseKey]);

  const confirmUpgrade = React.useCallback(() => {
    setLoading(true);
    if (props.licenseKey) {
      webApiCall({
        method: 'patch',
        endpoint: `payments/v1/licensetier/${props.licenseKey}/transmutrstudio`,
        data: { commit: true },
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          if (response.data.data?.checkoutUrl) {
            // @ts-ignore
            Paddle.Checkout.open({
              override: response.data.data.checkoutUrl,
              // @ts-ignore
              successCallback: (data) => {
                setUpgradeSuccessful(true);
                props.onUpgradeSuccessful();
                setLoading(false);
              },
              // @ts-ignore
              closeCallback: (data) => {
                setLoading(false);
              }
            });
          } else {
            setUpgradeSuccessful(true);
            props.onUpgradeSuccessful();
            setLoading(false);
          }
        },
        errorCallback: (response, message) => {
          if (message) {
            setErrorMessage(message);
          }
          setLoading(false);
        }
      });
    }
  }, [props.licenseKey]);

  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='sm' fullWidth disableEnforceFocus>
      <MUI.DialogTitle>Upgrade to Transmutr Studio</MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText component='div'>
          {loading ? (
            <MUI.Box width='100%' display='flex' justifyContent='center'>
              <MUI.CircularProgress />
            </MUI.Box>
          ) : upgradeSuccessful ? (
            <Alert severity='success'>
              Your license has been succesfully upgraded to Transmutr Studio!
              <br />
              <br />
              You can now restart Transmutr to enjoy all the great features of Studio.
              <br />
              <br />
              You will get your receipt via email.
            </Alert>
          ) : (
            <>
              {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
              <MUI.Typography style={{ whiteSpace: 'pre-wrap' }}>
                {simulationMessage}
              </MUI.Typography>
            </>
          )}
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        {upgradeSuccessful ? (
          <MUI.Button onClick={props.onClose}>Close</MUI.Button>
        ) : (
          <>
            <MUI.Button onClick={props.onClose} disabled={loading}>
              Cancel
            </MUI.Button>
            <MUI.Button
              onClick={() => confirmUpgrade()}
              variant='contained'
              disableElevation
              color='primary'
              disabled={loading || !simulationMessage}
            >
              Confirm
            </MUI.Button>
          </>
        )}
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(TransmutrStudioUpgradeOverlay);
