import React from 'react';
import * as MUI from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { NotifyFunction, webApiCall, parseJwt } from '../Common/utils';

interface Props {
  jwt?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function BazaarSales(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [earnings, setEarnings] = React.useState<any[] | undefined>(undefined);

  React.useEffect(() => {
    if (props.jwt) {
      setLoading(true);

      const jwtUserId = parseJwt(props.jwt).id;

      webApiCall({
        method: 'get',
        endpoint: `marketplace/v1/vendors/${jwtUserId}/earnings`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setEarnings(response.data.data.vendorEarnings);
        },
        ensureCallback: () => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt]);

  return (
    <>
      {loading || !earnings ? (
        <MUI.CircularProgress />
      ) : (
        <MUI.Grid container spacing={3}>
          <MUI.Grid item xs={6}>
            <MUI.Paper style={{ padding: '24px' }}>
              <MUI.Typography variant='h6' color='primary' gutterBottom>
                Total Revenue
              </MUI.Typography>
              <MUI.Typography variant='h5' color='primary' gutterBottom>
                {earnings
                  .map((row) => row.amount)
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2)}{' '}
                {earnings.length > 0 ? earnings[0].currency : ''}
              </MUI.Typography>
            </MUI.Paper>
          </MUI.Grid>

          <MUI.Grid item xs={6}>
            <MUI.Paper style={{ padding: '24px' }}>
              <MUI.Typography variant='h6' color='primary' gutterBottom>
                Transactions
              </MUI.Typography>
              <MUI.Typography variant='h5' color='primary' gutterBottom>
                {earnings.length}
              </MUI.Typography>
            </MUI.Paper>
          </MUI.Grid>

          <MUI.Grid item xs={12}>
            <MUI.Paper style={{ padding: '24px' }}>
              <MUI.Typography component='h2' variant='h6' color='primary' gutterBottom>
                Recent Sales
              </MUI.Typography>
              <MUI.Table size='small'>
                <MUI.TableHead>
                  <MUI.TableRow>
                    <MUI.TableCell>Cart ID</MUI.TableCell>
                    <MUI.TableCell>Products</MUI.TableCell>
                    <MUI.TableCell>Earnings</MUI.TableCell>
                    <MUI.TableCell>Refunded</MUI.TableCell>
                    <MUI.TableCell>Date</MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableHead>
                <MUI.TableBody>
                  {earnings.map((row) => {
                    const products = row.contents.map((product: any, index: number) => {
                      return (
                        <>
                          <a
                            href={`https://3dbazaar.lindale.io/market/?content=${product.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {product.name}
                          </a>
                          {index < row.contents.length - 1 && <span>, </span>}
                        </>
                      );
                    });

                    const date = new Date(row.date).toISOString().slice(0, 10);

                    return (
                      <MUI.TableRow key={row.cartId}>
                        <MUI.TableCell>{row.cartId}</MUI.TableCell>
                        <MUI.TableCell>{products}</MUI.TableCell>
                        <MUI.TableCell>{`${row.amount} ${row.currency}`}</MUI.TableCell>
                        <MUI.TableCell>{row.refunded ? 'true' : 'false'}</MUI.TableCell>
                        <MUI.TableCell>{date}</MUI.TableCell>
                      </MUI.TableRow>
                    );
                  })}
                </MUI.TableBody>
              </MUI.Table>

              <MUI.Box mt={3}>
                <CSVLink
                  headers={[
                    { label: 'Cart ID', key: 'cartId' },
                    { label: 'Products', key: 'products' },
                    { label: 'Currency', key: 'currency' },
                    { label: 'Earnings', key: 'amount' },
                    { label: 'Refunded', key: 'refunded' },
                    { label: 'Date', key: 'date' }
                  ]}
                  data={earnings.map((row) => ({
                    cartId: row.cartId,
                    products: row.contents.map((c: any) => c.name).join(', '),
                    currency: row.currency,
                    amount: row.amount,
                    refunded: row.refunded ? 'true' : 'false',
                    date: new Date(row.date).toISOString().slice(0, 10)
                  }))}
                  filename={'3dbazaar_sales.csv'}
                >
                  Download CSV
                </CSVLink>
              </MUI.Box>
            </MUI.Paper>
          </MUI.Grid>
        </MUI.Grid>
      )}
    </>
  );
}
