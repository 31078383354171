import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as MUI from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import { NotifyFunction, webApiCall } from '../Common/utils';
import SearchResellerDialog from './SearchResellerDialog';

import lindaleLogoCircle from '../../media/logos/lindale_logo_circle.svg';

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function RequestForm(props: Props) {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [website, setWebsite] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [waiting, setWaiting] = React.useState(false);
  const [resellerDialogOpen, setResellerDialogOpen] = React.useState(false);

  const submit = React.useCallback(() => {
    setWaiting(true);
    setErrors({});
    setSuccessMessage('');

    webApiCall({
      method: 'post',
      endpoint: 'resellers/v1/request',
      data: {
        email: email,
        name: name,
        company: company,
        country: country,
        website: website,
        description: description
      },
      suppressWebApiErrors: true,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        setSuccessMessage('Your request has successfully been sent');
      },
      errorCallback: (response, message) => {
        if (message) {
          setErrors({ message: message });
        } else if (response.data.data) {
          setErrors(response.data.data);
        }
      },
      ensureCallback: () => {
        setWaiting(false);
      }
    });
  }, [email, name, company, country, website, description, props.notify, props.setMaintenanceMode]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    const formIsSubmittable = !preventFormSubmit();
    if (e.key === 'Enter' && formIsSubmittable) {
      submit();
    }
  };

  const preventFormSubmit = () => {
    return (
      waiting ||
      email === '' ||
      name === '' ||
      company === '' ||
      country === '' ||
      website === '' ||
      description === ''
    );
  };

  return (
    <MUI.Box pb={8}>
      <MUI.Container
        maxWidth='xs'
        style={{
          marginTop: '64px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <MUI.Avatar
          src={lindaleLogoCircle}
          style={{
            margin: '8px',
            height: '64px',
            width: '64px'
          }}
        />

        <MUI.Typography component='h1' variant='h5'>
          Resellers
        </MUI.Typography>

        <MUI.Button
          variant='outlined'
          fullWidth
          onClick={() => setResellerDialogOpen(true)}
          style={{
            marginTop: '24px'
          }}
        >
          Find a reseller
        </MUI.Button>

        <MUI.Typography variant='body1' style={{ marginTop: '48px' }}>
          We have a dedicated portal for resellers, where you can order licenses, see previous
          orders, access a list of all your licenses, etc.
          <br />
          <br />
          If you are interested in becoming a reseller for our products, please fill the form below
          and we'll get back to you shortly.
          <br />
          <br />
          <b>
            <i>
              Already a reseller?{' '}
              <MUI.Link
                color='inherit'
                component={RouterLink}
                to='/resellers/portal'
                underline='always'
              >
                Click here to access the Portal
              </MUI.Link>
            </i>
          </b>
        </MUI.Typography>

        <form
          noValidate
          onKeyDown={onKeyDown}
          style={{
            marginTop: '16px',
            width: '100%' // Fix IE 11 issue.
          }}
        >
          {'message' in errors && (
            <Alert severity='error' style={{ marginBottom: '16px' }}>
              {errors.message}
            </Alert>
          )}

          {successMessage !== '' ? (
            <Alert severity='success' style={{ marginBottom: '16px' }}>
              {successMessage}
            </Alert>
          ) : (
            <>
              <MUI.TextField
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                error={'email' in errors}
                helperText={errors.email}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
              />
              <MUI.TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                error={'name' in errors}
                helperText={errors.name}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='name'
                label='Name'
                name='name'
                autoComplete='name'
              />
              <MUI.TextField
                onChange={(e) => setCompany(e.target.value)}
                value={company}
                error={'company' in errors}
                helperText={errors.company}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='company'
                label='Company'
                name='company'
                autoComplete='company'
              />
              <MUI.TextField
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                error={'country' in errors}
                helperText={errors.country}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='country'
                label='Country'
                name='country'
                autoComplete='country'
              />
              <MUI.TextField
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
                error={'website' in errors}
                helperText={errors.website}
                disabled={waiting}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='website'
                label='Website URL'
                name='website'
                autoComplete='website'
              />
              <MUI.TextField
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                error={'description' in errors}
                helperText={
                  errors.description ||
                  'Tell us more about your business (expected sale volume, etc)'
                }
                disabled={waiting}
                multiline
                minRows={4}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                size='small'
                id='description'
                label='About your business'
                name='description'
              />

              <MUI.Box margin='24px 0' position='relative'>
                <MUI.Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={() => {
                    submit();
                  }}
                  disabled={preventFormSubmit()}
                >
                  Submit
                </MUI.Button>
                {waiting && (
                  <MUI.CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12
                    }}
                  />
                )}
              </MUI.Box>
            </>
          )}
        </form>
      </MUI.Container>

      {resellerDialogOpen && (
        <SearchResellerDialog
          onClose={() => setResellerDialogOpen(false)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}
    </MUI.Box>
  );
}
