import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as MUI from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';

interface Props {}

const useStyles = MUI.makeStyles({
  title: {
    marginTop: '24px',
    marginBottom: '8px',
    color: 'grey'
  },
  divider: {
    backgroundColor: '#444',
    marginTop: '24px',
    marginBottom: '24px'
  }
});

export default function Header(props: Props) {
  const theme = MUI.useTheme();
  const classes = useStyles();

  return (
    <MUI.Box
      bgcolor={theme.palette.primary.dark}
      color={theme.palette.primary.contrastText}
      pt={2}
      pb={6}
    >
      <MUI.Container>
        <MUI.Box></MUI.Box>

        <MUI.Grid container>
          <MUI.Grid item xs={6} sm={3}>
            <MUI.Typography variant='subtitle2' className={classes.title}>
              Products
            </MUI.Typography>

            <div>
              <MUI.Link color='inherit' component={RouterLink} to='/skatter'>
                Skatter
              </MUI.Link>
            </div>

            <div>
              <MUI.Link color='inherit' component={RouterLink} to='/transmutr'>
                Transmutr
              </MUI.Link>
            </div>

            <div>
              <MUI.Link color='inherit' component={RouterLink} to='/roombox'>
                RoomBox
              </MUI.Link>
            </div>

            <div>
              <MUI.Link color='inherit' component={RouterLink} to='/3dbazaar'>
                3D Bazaar
              </MUI.Link>
            </div>
          </MUI.Grid>

          <MUI.Grid item xs={6} sm={3}>
            <MUI.Typography variant='subtitle2' className={classes.title}>
              Get Help
            </MUI.Typography>
            <div>
              <MUI.Link color='inherit' component={RouterLink} to='/support'>
                Help Center
              </MUI.Link>
            </div>
            <div>
              <MUI.Link color='inherit' href='https://forums.lindale.io'>
                Forums
              </MUI.Link>
            </div>
            <div>
              <MUI.Link color='inherit' href='https://www.facebook.com/groups/Skatter4SU'>
                Facebook Group
              </MUI.Link>
            </div>
          </MUI.Grid>

          <MUI.Grid item xs={6} sm={3}>
            <MUI.Typography variant='subtitle2' className={classes.title}>
              Company
            </MUI.Typography>
            <div>About</div>
            <div>
              <MUI.Link color='inherit' href='mailto:contact@lindale.io'>
                Contact us
              </MUI.Link>
            </div>
            <div>
              <MUI.Link color='inherit' component={RouterLink} to='/resellers'>
                Resellers
              </MUI.Link>
            </div>
          </MUI.Grid>

          <MUI.Grid item xs={6} sm={3}>
            <MUI.Typography variant='subtitle2' className={classes.title}>
              Follow us
            </MUI.Typography>
            <MUI.IconButton color='inherit' size='small' href='https://www.facebook.com/Skatter4SU'>
              <FacebookIcon />
            </MUI.IconButton>
            <MUI.IconButton
              color='inherit'
              size='small'
              href='https://www.youtube.com/channel/UC4rZ9jlY5Z00A9zKh_u5Gig'
            >
              <YoutubeIcon />
            </MUI.IconButton>
            <MUI.IconButton color='inherit' size='small' href='https://twitter.com/lindalesoftware'>
              <TwitterIcon />
            </MUI.IconButton>
            <MUI.IconButton
              color='inherit'
              size='small'
              href='https://www.instagram.com/lindalesoftware/'
            >
              <InstagramIcon />
            </MUI.IconButton>
          </MUI.Grid>
        </MUI.Grid>

        <MUI.Divider className={classes.divider} />

        <MUI.Typography variant='caption'>
          <i>
            Our order process is conducted by our online reseller Paddle.com. Paddle.com is the
            Merchant of Record for all our orders. Paddle provides all customer service inquiries
            and handles returns.
          </i>
        </MUI.Typography>

        <MUI.Divider className={classes.divider} />

        <MUI.Box display='flex' justifyContent='space-between'>
          <div>© Lindalë SARL</div>
          <MUI.Box display='flex'>
            <MUI.Box mr='16px'>
              <MUI.Link color='inherit'>Terms</MUI.Link>
            </MUI.Box>
            <div>
              <MUI.Link color='inherit'>Legal</MUI.Link>
            </div>
          </MUI.Box>
        </MUI.Box>
      </MUI.Container>
    </MUI.Box>
  );
}
