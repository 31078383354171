import React from 'react';
import { useSnackbar } from 'notistack';
import * as MUI from '@material-ui/core';
import OrderIcon from 'mdi-material-ui/CartOutline';

import { BillingInterval, ProductPrices } from './ResellersPortal';
import PriceDetails from './PriceDetails';
import { Product, LicenseType, webApiCall, NotifyFunction, NotifVariant } from '../Common/utils';

const useStyles = MUI.makeStyles((theme) => ({
  select: {
    marginTop: '32px'
  }
}));

interface Props {
  loading: boolean;
  jwt: string;
  currency: string;
  prices: ProductPrices[Product.SKATTER2_UPGRADE];
  recurringPrices: ProductPrices[Product.SKATTER2];
  discountList: { [key: number]: number };
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function SkatterUpgradeOrderForm(props: Props) {
  const classes = useStyles();

  const [licenseType, setLicenseType] = React.useState<LicenseType>(LicenseType.NODELOCKED);
  const [billingInterval, setBillingInterval] = React.useState<BillingInterval>(
    BillingInterval.PERPETUAL
  );
  const [v1LicenseKey, setV1LicenseKey] = React.useState('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [waiting, setWaiting] = React.useState(false);

  let price: number | undefined = undefined;
  if (licenseType === LicenseType.NODELOCKED) {
    if (billingInterval === BillingInterval.PERPETUAL) {
      price = props.prices[LicenseType.NODELOCKED][BillingInterval.PERPETUAL][props.currency];
    } else if (billingInterval === BillingInterval.YEARLY) {
      price = props.prices[LicenseType.NODELOCKED][BillingInterval.YEARLY][props.currency];
    }
  } else if (licenseType === LicenseType.FLOATING) {
    price = props.prices[LicenseType.FLOATING][BillingInterval.YEARLY][props.currency];
  }

  let recurringPrice: number | undefined = undefined;
  if (licenseType === LicenseType.NODELOCKED) {
    if (billingInterval === BillingInterval.YEARLY) {
      recurringPrice =
        props.recurringPrices[LicenseType.NODELOCKED][BillingInterval.YEARLY][props.currency];
    }
  } else if (licenseType === LicenseType.FLOATING) {
    recurringPrice =
      props.recurringPrices[LicenseType.FLOATING][BillingInterval.YEARLY][props.currency];
  }

  const quantityDiscount = props.discountList[1];
  const priceMultiplier = 1 - quantityDiscount / 100;

  React.useEffect(() => {
    if (licenseType === LicenseType.NODELOCKED) {
      setBillingInterval(BillingInterval.PERPETUAL);
    } else if (licenseType === LicenseType.FLOATING) {
      setBillingInterval(BillingInterval.YEARLY);
    }
  }, [licenseType]);

  const submit = React.useCallback(() => {
    if (!v1LicenseKey.match(/^[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}-[a-zA-Z0-9]{5}$/)) {
      setErrors({ v1LicenseKey: 'Invalid Skatter v1 license key' });
    } else {
      setWaiting(true);
      webApiCall({
        method: 'post',
        endpoint: `resellers/v1/checkoutlink/`,
        data: {
          product: Product.SKATTER2_UPGRADE,
          licenseType: licenseType,
          billingInterval: billingInterval,
          quantity: 1,
          seats: 1,
          skatterV1LicenseKey: v1LicenseKey
        },
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          // @ts-ignore
          Paddle.Checkout.open({
            override: response.data.data.url
          });
        },
        errorCallback: (response) => {
          if (response.data && response.data.data) {
            setErrors(response.data.data);
          }
        },
        ensureCallback: () => {
          setWaiting(false);
        }
      });
    }
  }, [
    props.jwt,
    props.notify,
    props.setMaintenanceMode,
    licenseType,
    billingInterval,
    v1LicenseKey
  ]);

  return (
    <MUI.Box>
      <MUI.TextField
        value={licenseType}
        onChange={(e) => setLicenseType(e.target.value as LicenseType)}
        label='License Type'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
        disabled={waiting}
      >
        <MUI.MenuItem value={LicenseType.NODELOCKED}>Fixed-seat (node-locked)</MUI.MenuItem>
        <MUI.MenuItem value={LicenseType.FLOATING}>Floating</MUI.MenuItem>
      </MUI.TextField>

      <MUI.TextField
        value={billingInterval}
        onChange={(e) => setBillingInterval(e.target.value as BillingInterval)}
        disabled={waiting || licenseType === LicenseType.FLOATING}
        label='Billing Interval'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      >
        <MUI.MenuItem value={BillingInterval.PERPETUAL}>Perpetual</MUI.MenuItem>
        <MUI.MenuItem value={BillingInterval.YEARLY}>Annual</MUI.MenuItem>
      </MUI.TextField>

      <MUI.TextField
        value={v1LicenseKey}
        onChange={(e) => setV1LicenseKey(e.target.value)}
        disabled={waiting}
        label='Skatter v1 License Key'
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
        error={!!errors.v1LicenseKey}
        helperText={errors.v1LicenseKey}
      />

      {price && (
        <PriceDetails
          price={price}
          recurringPrice={recurringPrice}
          priceMultiplier={priceMultiplier}
          currency={props.currency}
          quantity={1}
          seats={1}
          disabled={!v1LicenseKey || v1LicenseKey === ''}
          waiting={waiting}
          submit={submit}
        />
      )}
    </MUI.Box>
  );
}
