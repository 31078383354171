import React from 'react';
import { useSnackbar } from 'notistack';
import * as MUI from '@material-ui/core';
import OrderIcon from 'mdi-material-ui/CartOutline';

import { BillingInterval, ProductPrices } from './ResellersPortal';
import PriceDetails from './PriceDetails';
import { Product, LicenseType, webApiCall, NotifyFunction } from '../Common/utils';

const useStyles = MUI.makeStyles((theme) => ({
  select: {
    marginTop: '32px'
  }
}));

interface Props {
  loading: boolean;
  jwt: string;
  currency: string;
  prices: ProductPrices[Product.TRANSMUTR_ARTIST];
  discountList: { [key: number]: number };
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function TransmutrArtistOrderForm(props: Props) {
  const classes = useStyles();

  const [billingInterval, setBillingInterval] = React.useState<BillingInterval>(
    BillingInterval.PERPETUAL
  );
  const [quantity, setQuantity] = React.useState(1);

  const [waiting, setWaiting] = React.useState(false);

  let price: number | undefined = undefined;
  if (billingInterval === BillingInterval.PERPETUAL) {
    price = props.prices[LicenseType.NODELOCKED][BillingInterval.PERPETUAL][props.currency];
  } else if (billingInterval === BillingInterval.YEARLY) {
    price = props.prices[LicenseType.NODELOCKED][BillingInterval.YEARLY][props.currency];
  }

  const quantityDiscount = props.discountList[quantity];
  const priceMultiplier = 1 - quantityDiscount / 100;

  React.useEffect(() => {
    setQuantity(1);
  }, [billingInterval]);

  const submit = React.useCallback(() => {
    setWaiting(true);
    webApiCall({
      method: 'post',
      endpoint: `resellers/v1/checkoutlink/`,
      data: {
        product: Product.TRANSMUTR_ARTIST,
        licenseType: LicenseType.NODELOCKED,
        billingInterval: billingInterval,
        quantity: quantity,
        seats: 1
      },
      jwt: props.jwt,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        // @ts-ignore
        Paddle.Checkout.open({
          override: response.data.data.url
        });
      },
      ensureCallback: () => {
        setWaiting(false);
      }
    });
  }, [props.jwt, props.notify, props.setMaintenanceMode, billingInterval, quantity]);

  return (
    <MUI.Box>
      <MUI.Box mt='32px'>
        <i>Transmutr Artist licenses are only available as fixed-seats licenses.</i>
      </MUI.Box>

      <MUI.TextField
        value={billingInterval}
        onChange={(e) => setBillingInterval(e.target.value as BillingInterval)}
        disabled={waiting}
        label='Billing Interval'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      >
        <MUI.MenuItem value={BillingInterval.PERPETUAL}>Perpetual</MUI.MenuItem>
        <MUI.MenuItem value={BillingInterval.YEARLY}>Annual</MUI.MenuItem>
      </MUI.TextField>

      <MUI.TextField
        value={quantity}
        onChange={(e) => setQuantity(e.target.value as unknown as number)}
        disabled={waiting || billingInterval !== BillingInterval.PERPETUAL}
        label='Quantity'
        helperText='You get discounts by buying in bulk to constitute a stock'
        select
        variant='outlined'
        size='small'
        fullWidth
        className={classes.select}
      >
        <MUI.MenuItem value={1}>1</MUI.MenuItem>
        <MUI.MenuItem value={10}>10</MUI.MenuItem>
        <MUI.MenuItem value={20}>20</MUI.MenuItem>
        <MUI.MenuItem value={50}>50</MUI.MenuItem>
        <MUI.MenuItem value={100}>100</MUI.MenuItem>
      </MUI.TextField>

      {price && (
        <PriceDetails
          price={price}
          recurringPrice={billingInterval === BillingInterval.YEARLY ? price : undefined}
          priceMultiplier={priceMultiplier}
          currency={props.currency}
          quantity={quantity}
          seats={1}
          waiting={waiting}
          submit={submit}
        />
      )}
    </MUI.Box>
  );
}
