import React from 'react';
import * as MUI from '@material-ui/core';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridColDef,
  GridSortModel
} from '@mui/x-data-grid';

import LicenseDetails from './LicenseDetails';
import { NotifyFunction, Product, LicenseType, webApiCall, parseJwt } from '../Common/utils';

type LicenseRow = {
  id: number;
  key: string;
  product: Product;
  type: LicenseType;
  date: string;
  email: string;
};

const useStyles = MUI.makeStyles((theme) => ({
  row: {
    cursor: 'pointer'
  }
}));

interface Props {
  loading: boolean;
  jwt: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Licenses(props: Props) {
  const classes = useStyles();

  const [licenses, setLicenses] = React.useState<LicenseRow[] | undefined>(undefined);
  const [selectedLicenseKey, setSelectedLicenseKey] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'date',
      sort: 'desc'
    }
  ]);

  const columns = React.useMemo<GridColDef[]>(
    () => [
      { field: 'key', headerName: 'Key', width: 400 },
      { field: 'product', headerName: 'Product', width: 150 },
      { field: 'type', headerName: 'Type', width: 150 },
      { field: 'date', headerName: 'Date', width: 150 },
      { field: 'email', headerName: 'Email', width: 150 }
    ],
    []
  );

  function DataGridToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={{ fileName: 'licenses' }} />
      </GridToolbarContainer>
    );
  }

  React.useEffect(() => {
    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `resellers/v1/byuserid/${jwtUserId}/licenses`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          const licenseRows: LicenseRow[] = response.data.data.licenses.map(
            (license: any, id: number) => {
              return {
                id: id,
                key: license.key,
                product: license.product,
                type: license.type,
                date: new Date(license.creationDate).toISOString().slice(0, 10),
                email: license.email
              };
            }
          );

          setLicenses(licenseRows);
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <MUI.Container maxWidth='lg' style={{ height: '100%' }}>
      {props.loading || loading || !licenses ? (
        <MUI.CircularProgress />
      ) : (
        <MUI.Box height='100%' width='100%'>
          <DataGrid
            columns={columns}
            rows={licenses}
            onRowClick={(params, e) => setSelectedLicenseKey(params.row.key)}
            components={{ Toolbar: DataGridToolbar }}
            classes={{ row: classes.row }}
            density='compact'
            disableSelectionOnClick
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
          />
        </MUI.Box>
      )}

      {selectedLicenseKey && (
        <LicenseDetails
          loading={loading}
          jwt={props.jwt}
          licenseKey={selectedLicenseKey}
          onClose={() => setSelectedLicenseKey(undefined)}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}
    </MUI.Container>
  );
}
