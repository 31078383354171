import React from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@material-ui/core';

import lindaleLogoCircle from '../media/logos/lindale_logo_circle.svg';

interface Props {}

export default function Feedback(props: Props) {
  return (
    <MUI.Box flexGrow={1} minHeight='800px'>
      <Helmet>
        <title>Lindalë - Feedback</title>
      </Helmet>

      <MUI.Box pb={8}>
        <MUI.Container
          maxWidth='xs'
          style={{
            marginTop: '64px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <MUI.Avatar
            src={lindaleLogoCircle}
            style={{
              margin: '8px',
              height: '64px',
              width: '64px'
            }}
          />

          <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
            Thank you for your feedback!
          </MUI.Typography>

          <Route path='/feedback/skatter/alreadybought'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              Oops, sorry for the useless email! Our database has been updated ;)
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/needmoretime'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that you didn't have time to actually try Skatter out and see if it is
              something that can help you out in your workflow. So we are willing to provide you
              with an additional 15 days to try out Skatter.
              <br />
              <br />
              If this is something that you are interested in, then simply send an email to{' '}
              <a href='mailto:skatter@lindale.io'>skatter@lindale.io</a> and we'll set it up as soon
              as possible.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/educationalpricing'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We offer educational licenses for a very low price to students (5€), or for free to
              educators and institutions.{' '}
              <a href='https://licensing.lindale.io/academic/skatter/'>Click here to apply</a>
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/tooexpensive'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that the price for Skatter was a bit too high for you and that was the
              main reason you didn't buy it.
              <br />
              We would greatly appreciate if you could send an us email at{' '}
              <a href='mailto:skatter@lindale.io'>skatter@lindale.io</a> to tell us what would have
              been a reasonable price in your opinion.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/didntwork'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that Skatter didn't work as expected for you. Please send more
              information on the bugs or issues you encountered to{' '}
              <a href='mailto:skatter@lindale.io'>skatter@lindale.io</a> so we can assist you or fix
              the issues.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/toocomplicated'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that you had a hard time figuring out how to use Skatter. Make sure to
              check out the <a href='https://skatter.help.lindale.io'>online help resources</a>, and
              feel free to ask any question to{' '}
              <a href='mailto:skatter@lindale.io'>skatter@lindale.io</a>.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/renderengine'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              It is the Render Engine that needs to support Skatter, not the other way around. So
              this needs to come from the Render Engine's developer. Make sure to ask them to do so
              by sending them{' '}
              <a href='https://skatter.help.lindale.io/faq/im-a-render-engine-developer-how-can-i-support-skatter'>
                this article
              </a>{' '}
              and having them contact us at{' '}
              <a href='mailto:skatter@lindale.io'>skatter@lindale.io</a>
            </MUI.Typography>
          </Route>
          <Route path='/feedback/skatter/other'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              Please tell us the reason that stopped you from buying Skatter:{' '}
              <a href='mailto:skatter@lindale.io'>skatter@lindale.io</a>. Thanks!
            </MUI.Typography>
          </Route>

          <Route path='/feedback/transmutr/alreadybought'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              Oops, sorry for the useless email! Our database has been updated ;)
            </MUI.Typography>
          </Route>
          <Route path='/feedback/transmutr/needmoretime'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that you didn't have time to actually try Transmutr out and see if it is
              something that can help you out in your workflow. So we are willing to provide you
              with an additional 7 days to try out Transmutr.
              <br />
              <br />
              If this is something that you are interested in, then simply send an email to{' '}
              <a href='mailto:transmutr@lindale.io'>transmutr@lindale.io</a> and we'll set it up as
              soon as possible.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/transmutr/educationalpricing'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We offer educational licenses for a very low price to students (5€), or for free to
              educators and institutions.{' '}
              <a href='https://licensing.lindale.io/academic/transmutr/'>Click here to apply</a>
            </MUI.Typography>
          </Route>
          <Route path='/feedback/transmutr/tooexpensive'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that the price for Transmutr was a bit too high for you and that was the
              main reason you didn't buy it.
              <br />
              We would greatly appreciate if you could send an us email at{' '}
              <a href='mailto:transmutr@lindale.io'>transmutr@lindale.io</a> to tell us what would
              have been a reasonable price in your opinion.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/transmutr/didntwork'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that Transmutr didn't work as expected for you. Please send more
              information on the bugs or issues you encountered to{' '}
              <a href='mailto:transmutr@lindale.io'>transmutr@lindale.io</a> so we can assist you or
              fix the issues.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/transmutr/toocomplicated'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              We understand that you had a hard time figuring out how to use Transmutr. Make sure to
              check out the <a href='https://help.transmutr.com'>online help resources</a>, and feel
              free to ask any question to{' '}
              <a href='mailto:transmutr@lindale.io'>transmutr@lindale.io</a>.
            </MUI.Typography>
          </Route>
          <Route path='/feedback/transmutr/other'>
            <MUI.Typography variant='body1' style={{ marginTop: '16px' }}>
              Please tell us the reason that stopped you from buying Transmutr:{' '}
              <a href='mailto:transmutr@lindale.io'>transmutr@lindale.io</a>. Thanks!
            </MUI.Typography>
          </Route>
        </MUI.Container>
      </MUI.Box>
    </MUI.Box>
  );
}
