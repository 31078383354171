import React from 'react';
import * as MUI from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { NotifyFunction, webApiCall, parseJwt, downloadUrl, toISODate } from '../Common/utils';

type Payout = {
  id: string;
  vendor: { id: string; name: string };
  amount: number;
  currency: string;
  method: string;
  paypalAddress: string;
  payoneerAddress: string;
  status: string;
  payoutType: string;
  invoiceId: string;
  date: string;
};

interface Props {
  jwt?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Payouts(props: Props) {
  const [balanceLoading, setBalanceLoading] = React.useState(true);
  const [payoutsLoading, setPayoutsLoading] = React.useState(true);
  const [payouts, setPayouts] = React.useState<Payout[]>([]);
  const [balance, setBalance] = React.useState<{
    [key: string]: number;
  }>({});

  React.useEffect(() => {
    if (props.jwt) {
      setBalanceLoading(true);
      setBalance({});
      setPayoutsLoading(true);
      setPayouts([]);

      const jwtUserId = parseJwt(props.jwt).id;

      webApiCall({
        method: 'get',
        endpoint: `vendors/v1/${jwtUserId}/balance`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setBalance(response.data.data.balance);
        },
        ensureCallback: () => {
          setBalanceLoading(false);
        }
      });

      webApiCall({
        method: 'get',
        endpoint: `vendors/v1/payouts`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setPayouts(response.data.data.payouts);
        },
        ensureCallback: () => {
          setPayoutsLoading(false);
        }
      });
    }
  }, [props.jwt]);

  const handleDownloadInvoice = (payoutId: string) => {
    webApiCall({
      method: 'get',
      endpoint: `vendors/v1/payouts/${payoutId}/invoice`,
      jwt: props.jwt,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        downloadUrl(response.data.data.url);
      }
    });
  };

  return (
    <MUI.Grid container spacing={3}>
      <MUI.Grid item xs={12}>
        <MUI.Paper style={{ padding: '24px' }}>
          <MUI.Typography component='h2' variant='h6' color='primary' gutterBottom>
            Balance
          </MUI.Typography>

          {balanceLoading || !balance ? (
            <MUI.CircularProgress />
          ) : Object.keys(balance).length ? (
            Object.keys(balance).map((currency) => (
              <MUI.Typography variant='h5' color='primary'>
                {balance[currency]} {currency}
              </MUI.Typography>
            ))
          ) : (
            <MUI.Typography variant='h5' color='primary'>
              0
            </MUI.Typography>
          )}
        </MUI.Paper>
      </MUI.Grid>

      <MUI.Grid item xs={12}>
        <MUI.Paper style={{ padding: '24px' }}>
          <MUI.Typography component='h2' variant='h6' color='primary' gutterBottom>
            Payouts
          </MUI.Typography>

          {payoutsLoading || !payouts ? (
            <MUI.CircularProgress />
          ) : (
            <MUI.Table size='small'>
              <MUI.TableHead>
                <MUI.TableRow>
                  <MUI.TableCell>Payout ID</MUI.TableCell>
                  <MUI.TableCell>Amount</MUI.TableCell>
                  <MUI.TableCell>Method</MUI.TableCell>
                  <MUI.TableCell>Status</MUI.TableCell>
                  <MUI.TableCell>Date</MUI.TableCell>
                  <MUI.TableCell>Invoice</MUI.TableCell>
                </MUI.TableRow>
              </MUI.TableHead>
              <MUI.TableBody>
                {payouts.map((payout, index) => (
                  <MUI.TableRow key={index}>
                    <MUI.TableCell>{payout.id}</MUI.TableCell>
                    <MUI.TableCell>{`${payout.amount} ${payout.currency}`}</MUI.TableCell>
                    <MUI.TableCell>{payout.method}</MUI.TableCell>
                    <MUI.TableCell>{payout.status}</MUI.TableCell>
                    <MUI.TableCell>{toISODate(payout.date)}</MUI.TableCell>
                    <MUI.TableCell>
                      <MUI.IconButton
                        style={{ padding: '4px' }}
                        onClick={() => handleDownloadInvoice(payout.id)}
                        disabled={!payout.invoiceId}
                      >
                        <DownloadIcon style={{ fontSize: 16 }} />
                      </MUI.IconButton>
                    </MUI.TableCell>
                  </MUI.TableRow>
                ))}
              </MUI.TableBody>
            </MUI.Table>
          )}
        </MUI.Paper>
      </MUI.Grid>
    </MUI.Grid>
  );
}
