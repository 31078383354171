import React from 'react';

import * as MUI from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  entries: { title: string; description: React.ReactNode }[];
}

export default function Faq(props: Props) {
  return (
    <MUI.Box>
      {props.entries.map((entry, index) => (
        <MUI.Accordion key={index} defaultExpanded={index === 0}>
          <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <MUI.Typography variant='body2'>{entry.title}</MUI.Typography>
          </MUI.AccordionSummary>
          <MUI.AccordionDetails>
            <MUI.Typography variant='body2'>{entry.description}</MUI.Typography>
          </MUI.AccordionDetails>
        </MUI.Accordion>
      ))}
    </MUI.Box>
  );
}
