import React from 'react';
import * as MUI from '@material-ui/core';
import { CSVLink } from 'react-csv';

import { paddleProductIdToProductName } from './ResellersPortal';
import { NotifyFunction, webApiCall, parseJwt } from '../Common/utils';

interface Props {
  loading: boolean;
  jwt: string;
  orderId: string;
  onClose: () => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function OrderDetails(props: Props) {
  const [order, setOrder] = React.useState<any | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `resellers/v1/byuserid/${jwtUserId}/orders/${props.orderId}`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setOrder(response.data.data.order);
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <MUI.Dialog maxWidth='lg' fullWidth open onClose={props.onClose}>
      {props.loading || loading || !order ? (
        <MUI.CircularProgress />
      ) : (
        <>
          <MUI.DialogTitle>Order details</MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.DialogContentText>
              <MUI.Table size='small'>
                <MUI.TableBody>
                  <MUI.TableRow>
                    <MUI.TableCell>Date</MUI.TableCell>
                    <MUI.TableCell>{new Date(order.date).toISOString().slice(0, 10)}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Product</MUI.TableCell>
                    <MUI.TableCell>{paddleProductIdToProductName(order.product)}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>Quantity</MUI.TableCell>
                    <MUI.TableCell>{order.quantity}</MUI.TableCell>
                  </MUI.TableRow>
                  <MUI.TableRow>
                    <MUI.TableCell>License keys</MUI.TableCell>
                    <MUI.TableCell>
                      <CSVLink
                        data={order.licenseKeys.map((k: string) => [k])}
                        filename={`order_${paddleProductIdToProductName(order.product)}_${new Date(
                          order.date
                        )
                          .toISOString()
                          .slice(0, 10)}.csv`}
                      >
                        Download CSV
                      </CSVLink>
                    </MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableBody>
              </MUI.Table>

              <MUI.Typography variant='h6' style={{ marginTop: '32px' }}>
                Payments
              </MUI.Typography>

              <MUI.Table size='small'>
                <MUI.TableHead>
                  <MUI.TableRow>
                    <MUI.TableCell>Total Price</MUI.TableCell>
                    <MUI.TableCell>Receipt</MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableHead>
                <MUI.TableBody>
                  {order.oneOffPayment && (
                    <MUI.TableRow>
                      <MUI.TableCell>
                        {order.oneOffPayment.pricePaid} {order.oneOffPayment.currency}
                      </MUI.TableCell>
                      <MUI.TableCell>
                        <a target='_blank' href={order.oneOffPayment.receiptUrl}>
                          receipt
                        </a>
                      </MUI.TableCell>
                    </MUI.TableRow>
                  )}
                  {order.subscriptionPayments && order.subscriptionPayments.length > 0 && (
                    <>
                      {order.subscriptionPayments.map((p: any) => (
                        <MUI.TableRow key={p.id}>
                          <MUI.TableCell>
                            {p.pricePaid} {p.currency}
                          </MUI.TableCell>
                          <MUI.TableCell>
                            <a target='_blank' href={p.receiptUrl}>
                              receipt
                            </a>
                          </MUI.TableCell>
                        </MUI.TableRow>
                      ))}
                    </>
                  )}
                </MUI.TableBody>
              </MUI.Table>
            </MUI.DialogContentText>
          </MUI.DialogContent>
          <MUI.DialogActions>
            <MUI.Button onClick={props.onClose} color='primary'>
              Close
            </MUI.Button>
          </MUI.DialogActions>
        </>
      )}
    </MUI.Dialog>
  );
}
