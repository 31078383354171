import React from 'react';
import { Switch, Route, NavLink as RouterNavLink, useHistory } from 'react-router-dom';
import Joi from 'joi';
import * as MUI from '@material-ui/core';
import OrderIcon from 'mdi-material-ui/CartOutline';
import HistoryIcon from 'mdi-material-ui/History';
import LicenseIcon from 'mdi-material-ui/KeyOutline';
import AccountIcon from 'mdi-material-ui/AccountCircleOutline';

import {
  Product,
  LicenseType,
  NotifVariant,
  NotifyFunction,
  webApiCall,
  parseJwt,
  getCookie
} from '../Common/utils';
import Order from './Order';
import History from './History';
import Licenses from './Licenses';

export enum BillingInterval {
  PERPETUAL = 'perpetual',
  YEARLY = 'yearly'
}

export type CurrencyPrices = { EUR: number; [key: string]: number };
export type ProductPrices = {
  [Product.SKATTER2]: {
    [LicenseType.NODELOCKED]: {
      [BillingInterval.PERPETUAL]: CurrencyPrices;
      [BillingInterval.YEARLY]: CurrencyPrices;
    };
    [LicenseType.FLOATING]: {
      [BillingInterval.YEARLY]: CurrencyPrices;
    };
  };
  [Product.SKATTER2_UPGRADE]: {
    [LicenseType.NODELOCKED]: {
      [BillingInterval.PERPETUAL]: CurrencyPrices;
      [BillingInterval.YEARLY]: CurrencyPrices;
    };
    [LicenseType.FLOATING]: {
      [BillingInterval.YEARLY]: CurrencyPrices;
    };
  };
  [Product.TRANSMUTR_ARTIST]: {
    [LicenseType.NODELOCKED]: {
      [BillingInterval.PERPETUAL]: CurrencyPrices;
      [BillingInterval.YEARLY]: CurrencyPrices;
    };
  };
  [Product.TRANSMUTR_STUDIO]: {
    [LicenseType.FLOATING]: {
      [BillingInterval.YEARLY]: CurrencyPrices;
    };
  };
};

const currencyPricesSchema = Joi.object({ EUR: Joi.number() }).pattern(
  Joi.string().length(3),
  Joi.number()
);
const productPricesSchema = Joi.object({
  [Product.SKATTER2]: Joi.object({
    [LicenseType.NODELOCKED]: Joi.object({
      [BillingInterval.PERPETUAL]: currencyPricesSchema,
      [BillingInterval.YEARLY]: currencyPricesSchema
    }),
    [LicenseType.FLOATING]: Joi.object({
      [BillingInterval.YEARLY]: currencyPricesSchema
    })
  }),
  [Product.SKATTER2_UPGRADE]: Joi.object({
    [LicenseType.NODELOCKED]: Joi.object({
      [BillingInterval.PERPETUAL]: currencyPricesSchema,
      [BillingInterval.YEARLY]: currencyPricesSchema
    }),
    [LicenseType.FLOATING]: Joi.object({
      [BillingInterval.YEARLY]: currencyPricesSchema
    })
  }),
  [Product.TRANSMUTR_ARTIST]: Joi.object({
    [LicenseType.NODELOCKED]: Joi.object({
      [BillingInterval.PERPETUAL]: currencyPricesSchema,
      [BillingInterval.YEARLY]: currencyPricesSchema
    })
  }),
  [Product.TRANSMUTR_STUDIO]: Joi.object({
    [LicenseType.FLOATING]: Joi.object({
      [BillingInterval.YEARLY]: currencyPricesSchema
    })
  })
});

export const paddleProductIdToProductName = (paddleProductId: string) => {
  switch (paddleProductId) {
    case '666407':
    case '666357':
    case '666359':
      return 'Skatter';

    case '666549':
    case '666363':
      return 'Transmutr Artist';

    case '555805':
      return 'Transmutr Studio';
  }
};

const useStyles = MUI.makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '240px'
  },
  activeNavLink: {
    backgroundColor: '#eee',
    '& > .MuiListItemText-root > .MuiListItemText-primary': {
      fontWeight: 'bold'
    }
  }
}));

interface Props {
  jwt?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Resellers(props: Props) {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);
  const [accessDenied, setAccessDenied] = React.useState(false);

  const [territory, setTerritory] = React.useState<string | undefined>(undefined);
  const [currency, setCurrency] = React.useState<string | undefined>(undefined);
  const [productPrices, setProductPrices] = React.useState<ProductPrices | undefined>(undefined);
  const [discountList, setDiscountList] = React.useState<{ [key: number]: number } | undefined>(
    undefined
  );

  React.useEffect(() => {
    // Redirect to login form if not logged in
    if (!props.jwt && !getCookie('refreshToken')) {
      history.push({ pathname: `/account/login`, search: `?return=${window.location.href}` });
    }

    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      const jwtUserGroups = jwtProps.userGroups;

      if (jwtUserGroups.includes('reseller')) {
        webApiCall({
          method: 'get',
          endpoint: `resellers/v1/byuserid/${jwtUserId}`,
          jwt: props.jwt,
          notify: props.notify,
          setMaintenanceMode: props.setMaintenanceMode,
          successCallback: (response) => {
            setTerritory(response.data.data.reseller.territory);
            setCurrency(response.data.data.reseller.currency);

            const productPrices = response.data.data.reseller.productPrices;
            const productPricesValidation = productPricesSchema.validate(productPrices);
            if (productPricesValidation.error) {
              props.notify(
                `Product Prices validation failed: ${productPricesValidation.error}`,
                NotifVariant.ERROR
              );
            } else {
              setProductPrices(productPrices);
            }

            const discountList = response.data.data.reseller.discountList;
            const discountListValidation = Joi.object({})
              .pattern(Joi.number(), Joi.number())
              .validate(discountList);
            if (discountListValidation.error) {
              props.notify(
                `Discount List validation failed: ${discountListValidation.error}`,
                NotifVariant.ERROR
              );
            } else {
              setDiscountList(discountList);
            }

            setLoading(false);
          }
        });
      } else {
        setLoading(false);
        setAccessDenied(true);
      }
    }
  }, [props.jwt]);

  return (
    <MUI.Box display='flex'>
      {loading ? (
        <MUI.Container maxWidth='xs'>
          <MUI.Box display='flex' minHeight='200px' justifyContent='center' alignItems='center'>
            <MUI.CircularProgress />
          </MUI.Box>
        </MUI.Container>
      ) : accessDenied ? (
        <MUI.Container maxWidth='sm'>
          <MUI.Box mt='64px' textAlign='center'>
            <MUI.Typography variant='h4'>Access Denied</MUI.Typography>
            <MUI.Typography variant='body1'>
              You are not allowed to access the Resellers Portal. If you think this is a mistake,
              please contact us at contact@lindale.io
            </MUI.Typography>
          </MUI.Box>
        </MUI.Container>
      ) : (
        <>
          <MUI.Drawer
            variant='permanent'
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <MUI.List>
              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/resellers/portal'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <OrderIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Order' />
              </MUI.ListItem>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/resellers/portal/history'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <HistoryIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Order History' />
              </MUI.ListItem>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/resellers/portal/licenses'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <LicenseIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Licenses' />
              </MUI.ListItem>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/account/manage'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <AccountIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Account' />
              </MUI.ListItem>
            </MUI.List>
          </MUI.Drawer>

          <MUI.Box flexGrow={1} minHeight='800px' p='32px'>
            <Switch>
              {props.jwt && territory && currency && productPrices && discountList && (
                <>
                  <Route path='/resellers/portal' exact>
                    <Order
                      loading={loading}
                      jwt={props.jwt}
                      territory={territory}
                      currency={currency}
                      productPrices={productPrices}
                      discountList={discountList}
                      notify={props.notify}
                      setMaintenanceMode={props.setMaintenanceMode}
                    />
                  </Route>

                  <Route path='/resellers/portal/history'>
                    <History
                      loading={loading}
                      jwt={props.jwt}
                      notify={props.notify}
                      setMaintenanceMode={props.setMaintenanceMode}
                    />
                  </Route>

                  <Route path='/resellers/portal/licenses'>
                    <Licenses
                      loading={loading}
                      jwt={props.jwt}
                      notify={props.notify}
                      setMaintenanceMode={props.setMaintenanceMode}
                    />
                  </Route>
                </>
              )}
            </Switch>
          </MUI.Box>
        </>
      )}
    </MUI.Box>
  );
}
