import React from 'react';

import * as MUI from '@material-ui/core';

interface Props {
  embedId: string;
  style?: React.CSSProperties;
}

export default function YoutubeEmbed(props: Props) {
  return (
    <MUI.Box overflow='hidden' pb='56.25%' position='relative' height={0} style={props.style}>
      <iframe
        width='1280'
        height='720'
        src={`https://www.youtube.com/embed/${props.embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
        style={{
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
          position: 'absolute'
        }}
      />
    </MUI.Box>
  );
}
