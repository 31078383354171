import React from 'react';
import * as MUI from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { NotifyFunction, webApiCall, parseJwt } from '../Common/utils';

export enum PayoutMethod {
  PAYPAL = 'paypal',
  PAYONEER = 'payoneer'
}

interface Props {
  jwt?: string;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function PayoutSettings(props: Props) {
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [loading, setLoading] = React.useState(true);
  const [company, setCompany] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [vatNumber, setVatNumber] = React.useState('');
  const [payoutMethod, setPayoutMethod] = React.useState<PayoutMethod | undefined>();
  const [payoutPaypalAddress, setPayoutPaypalAddress] = React.useState('');
  const [payoutPayoneerAddress, setPayoutPayoneerAddress] = React.useState('');
  const [payoutThreshold, setPayoutThreshold] = React.useState<number | undefined>();

  React.useEffect(() => {
    if (props.jwt) {
      setErrors({});
      setLoading(true);
      setCompany('');
      setAddress('');
      setCountry('');
      setVatNumber('');
      setPayoutMethod(undefined);
      setPayoutPaypalAddress('');
      setPayoutPayoneerAddress('');
      setPayoutThreshold(undefined);

      const jwtUserId = parseJwt(props.jwt).id;

      webApiCall({
        method: 'get',
        endpoint: `vendors/v1/${jwtUserId}/payoutsettings`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setCompany(response.data.data.company);
          setAddress(response.data.data.address);
          setCountry(response.data.data.country);
          setVatNumber(response.data.data.vatNumber);
          setPayoutMethod(response.data.data.payoutMethod);
          setPayoutPaypalAddress(response.data.data.payoutPaypalAddress);
          setPayoutPayoneerAddress(response.data.data.payoutPayoneerAddress);
          setPayoutThreshold(response.data.data.payoutThreshold);
        },
        ensureCallback: () => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt, props.notify, props.setMaintenanceMode]);

  const submit = React.useCallback(() => {
    if (props.jwt) {
      setErrors({});
      setLoading(true);
      const jwtUserId = parseJwt(props.jwt).id;

      webApiCall({
        method: 'put',
        endpoint: `vendors/v1/${jwtUserId}/payoutsettings`,
        jwt: props.jwt,
        data: {
          company: company,
          address: address,
          country: country,
          vatNumber: vatNumber,
          payoutMethod: payoutMethod,
          payoutPaypalAddress: payoutPaypalAddress,
          payoutPayoneerAddress: payoutPayoneerAddress,
          payoutThreshold: payoutThreshold
        },
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setCompany(response.data.data.company);
          setAddress(response.data.data.address);
          setCountry(response.data.data.country);
          setVatNumber(response.data.data.vatNumber);
          setPayoutMethod(response.data.data.payoutMethod);
          setPayoutPaypalAddress(response.data.data.payoutPaypalAddress);
          setPayoutPayoneerAddress(response.data.data.payoutPayoneerAddress);
          setPayoutThreshold(response.data.data.payoutThreshold);
        },
        errorCallback: (response) => {
          if (response.data?.data) {
            setErrors(response.data.data);
          }
        },
        ensureCallback: () => {
          setLoading(false);
        }
      });
    }
  }, [
    props.notify,
    props.setMaintenanceMode,
    props.jwt,
    company,
    address,
    country,
    vatNumber,
    payoutMethod,
    payoutPaypalAddress,
    payoutPayoneerAddress,
    payoutThreshold
  ]);

  const preventFormSubmit =
    loading ||
    !company ||
    !address ||
    !country ||
    !vatNumber ||
    !payoutMethod ||
    (payoutMethod === PayoutMethod.PAYPAL && !payoutPaypalAddress) ||
    (payoutMethod === PayoutMethod.PAYONEER && !payoutPayoneerAddress) ||
    !payoutThreshold;

  return (
    <MUI.Box display='flex'>
      <MUI.Box flexGrow={1} minHeight='800px' p='32px'>
        <MUI.Container
          maxWidth='xs'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <MUI.Typography component='h1' variant='h5'>
            Payout Settings
          </MUI.Typography>

          {loading ? (
            <MUI.CircularProgress />
          ) : (
            <>
              <MUI.TextField
                onChange={(e) => setCompany(e.target.value)}
                value={company}
                error={'company' in errors}
                helperText={errors.company}
                disabled={loading}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='company'
                label='Company Legal Name'
                name='company'
                autoComplete='company'
              />

              <MUI.TextField
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                error={'address' in errors}
                helperText={errors.address}
                disabled={loading}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                multiline
                rows={3}
                id='address'
                label='Address'
                name='address'
                autoComplete='address'
              />

              <MUI.TextField
                onChange={(e) => setCountry(e.target.value)}
                value={country}
                error={'country' in errors}
                helperText={errors.country}
                disabled={loading}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='country'
                label='Country'
                name='country'
                autoComplete='country'
              />

              <MUI.TextField
                onChange={(e) => setVatNumber(e.target.value)}
                value={vatNumber}
                error={'vatNumber' in errors}
                helperText={errors.vatNumber}
                disabled={loading}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='vatNumber'
                label='VAT Number'
                name='vatNumber'
                autoComplete='vatNumber'
              />

              <MUI.FormControl
                error={'payoutMethod' in errors}
                variant='outlined'
                margin='normal'
                required
                fullWidth
              >
                <MUI.InputLabel id='payoutMethod-label'>Transfer Method</MUI.InputLabel>
                <MUI.Select
                  onChange={(e) => setPayoutMethod(e.target.value as PayoutMethod)}
                  value={payoutMethod || ''}
                  disabled={loading}
                  id='payoutMethod'
                  labelId='payoutMethod-label'
                  label='Payout Method'
                  name='payoutMethod'
                  autoComplete='payoutMethod'
                >
                  <MUI.MenuItem value={PayoutMethod.PAYPAL}>PayPal</MUI.MenuItem>
                  <MUI.MenuItem value={PayoutMethod.PAYONEER}>Payoneer</MUI.MenuItem>
                </MUI.Select>
                {errors.payoutMethod && <MUI.FormHelperText>Error</MUI.FormHelperText>}
              </MUI.FormControl>

              {payoutMethod === PayoutMethod.PAYPAL && (
                <MUI.TextField
                  onChange={(e) => setPayoutPaypalAddress(e.target.value)}
                  value={payoutPaypalAddress}
                  error={'payoutPaypalAddress' in errors}
                  helperText={errors.payoutPaypalAddress}
                  disabled={loading}
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='payoutPaypalAddress'
                  label='PayPal Address'
                  name='payoutPaypalAddress'
                  autoComplete='payoutPaypalAddress'
                />
              )}

              {payoutMethod === PayoutMethod.PAYONEER && (
                <MUI.TextField
                  onChange={(e) => setPayoutPayoneerAddress(e.target.value)}
                  value={payoutPayoneerAddress}
                  error={'payoutPayoneerAddress' in errors}
                  helperText={errors.payoutPayoneerAddress}
                  disabled={loading}
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='payoutPayoneerAddress'
                  label='Payoneer Address'
                  name='payoutPayoneerAddress'
                  autoComplete='payoutPayoneerAddress'
                />
              )}

              <MUI.TextField
                onChange={(e) => setPayoutThreshold(parseInt(e.target.value))}
                value={payoutThreshold}
                error={'payoutThreshold' in errors}
                helperText={errors.payoutThreshold}
                disabled={loading}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                type='number'
                id='payoutThreshold'
                label='Minimum Threshold (EUR)'
                name='payoutThreshold'
                autoComplete='payoutThreshold'
                InputProps={{
                  endAdornment: (
                    <MUI.InputAdornment position='end'>
                      <MUI.Tooltip title='This minimum threshold avoids excessively small payouts, which could still incur the same fixed fees from PayPal/Payoneer or your bank, meaning only a very small amount would actually reach your bank account. If your balance falls below the minimum threshold amount, it will be carried over onto the following month, up until the minimum threshold is met.'>
                        <InfoOutlinedIcon />
                      </MUI.Tooltip>
                    </MUI.InputAdornment>
                  )
                }}
              />

              <MUI.Button
                variant='contained'
                color='primary'
                fullWidth
                onClick={() => {
                  submit();
                }}
                disabled={preventFormSubmit}
                style={{
                  marginTop: 24
                }}
              >
                Submit
              </MUI.Button>
            </>
          )}
        </MUI.Container>
      </MUI.Box>
    </MUI.Box>
  );
}
