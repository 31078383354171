import React from 'react';
import { Link as RouterLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@material-ui/core';
import * as MUIIcons from '@material-ui/icons';
import { Download as DownloadIcon } from 'mdi-material-ui';

import Feature from './Common/Feature';
import Jumbotron from './Common/Jumbotron';
import ProductHeader from './Common/ProductHeader';

import RoomBoxLogoText from '../media/logos/roombox_logo_text.svg';
import JumbotronVideoMp4 from '../media/roombox/jumbotron.mp4';
import JumbotronVideoWebM from '../media/roombox/jumbotron.webm';
import CreateRoomVideoMp4 from '../media/roombox/creating_room.mp4';
import CreateRoomVideoWebM from '../media/roombox/creating_room.webm';
import CompareWireframeImage from '../media/roombox/compare_wireframe.png';
import CompareRenderImage from '../media/roombox/compare_render.jpg';
import CustomMapVideoMp4 from '../media/roombox/custom_map.mp4';
import CustomMapVideoWebM from '../media/roombox/custom_map.webm';
import TryNowImage from '../media/roombox/try_now.jpg';

import WParallaxLogo from '../media/creators/wparallax.png';
import ShadersBoxLogo from '../media/creators/shadersbox.png';
import EvermotionLogo from '../media/creators/evermotion.png';

import { NotifyFunction, useScrollToTop } from './Common/utils';
import InstallInstructions from './Common/InstallInstructions';

export const theme = MUI.createTheme({
  palette: {
    primary: {
      main: '#8a00ff',
      light: '#c34eff',
      dark: '#4d00ca',
      contrastText: '#fff'
    },
    secondary: {
      main: '#8a00ff',
      light: '#c34eff',
      dark: '#4d00ca',
      contrastText: '#fff'
    }
  }
});

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

const useStyles = MUI.makeStyles({
  creator: {
    margin: 32,
    '& img': {
      maxHeight: 60
    }
  }
});

export default function RoomBox(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  useScrollToTop(location.pathname);

  return (
    <MUI.MuiThemeProvider theme={theme}>
      <MUI.Box style={{ position: 'relative' }}>
        <Helmet>
          <title>Lindalë - RoomBox</title>
        </Helmet>

        <Jumbotron
          logo={
            <img
              src={RoomBoxLogoText}
              alt='RoomBox logo'
              style={{
                maxWidth: '400px',
                maxHeight: '80px'
              }}
            />
          }
          subtitle='Render-ready interiors in one click'
          description='Instill life into your SketchUp models by quickly and easily populating facades with render-ready rooms'
          color='#7d8285'
          subtitleFontWeight={400}
          action={
            <MUI.Button
              variant='contained'
              color='primary'
              size='large'
              component={RouterLink}
              to={`${routeMatch.path}?download`}
              startIcon={<DownloadIcon />}
            >
              Download now, it's free
            </MUI.Button>
          }
          backgroundVideoWebM={JumbotronVideoWebM}
          backgroundVideoMp4={JumbotronVideoMp4}
          backgroundOpacity={0.1}
        />

        <ProductHeader
          logo={RoomBoxLogoText}
          buttons={[
            {
              label: 'Overview',
              to: '/roombox#overview',
              color: 'inherit'
            },
            {
              label: 'Creators',
              to: '/roombox#creators',
              color: 'inherit'
            },
            {
              label: 'Help',
              to: '/support?product=roombox',
              color: 'inherit'
            },
            {
              label: 'Download',
              to: '/roombox?download',
              color: 'primary',
              variant: 'outlined'
            }
          ]}
        />

        <MUI.Box id='overview'>
          {/* TODO roombox trailer */}
          <Feature
            youtubeEmbedId='MnuLQM_-xLE'
            main={[
              {
                mediaShadow: true
              }
            ]}
          />

          <Feature
            title='Render-ready interiors for all your needs'
            main={[
              {
                description: (
                  <>
                    <b>The largest collection of rooms ready to be inserted into your models.</b>{' '}
                    Shops, office spaces, living rooms, bedrooms, kitchens... You name it!
                    <br />
                    <br />
                    Every room comes with both <b>night and day versions</b> as well as{' '}
                    <b>low and high resolution</b> variants.
                  </>
                )
              }
            ]}
            slideshowImages={roomImages}
          />

          <Feature
            title='Instantly create and edit rooms'
            main={[
              {
                description: (
                  <>
                    Adding a room to your models is a breeze:{' '}
                    <b>select it in the library, click on a surface in your model</b>, and you're
                    done.
                    <br />
                    <br />
                    Once a room has been created,{' '}
                    <b>
                      use our <i>Room editor</i> to modify its contents
                    </b>
                    . There's no need to manually fiddle with interior maps in an external image
                    editor to get what you want.
                    <br />
                    <br />
                    It supports <b>V-Ray, Enscape, and Thea</b> out-of-the-box, as well as{' '}
                    <MUI.Link href='https://roombox.help.lindale.io/faq/can-i-use-roombox-with-lumion'>
                      Lumion
                    </MUI.Link>
                    ,{' '}
                    <MUI.Link href='https://roombox.help.lindale.io/faq/can-i-use-roombox-with-twinmotion'>
                      Twinmotion
                    </MUI.Link>
                    ,{' '}
                    <MUI.Link href='https://roombox.help.lindale.io/faq/can-i-use-roombox-with-d5'>
                      D5
                    </MUI.Link>{' '}
                    and{' '}
                    <MUI.Link href='https://roombox.help.lindale.io/faq/can-i-use-roombox-with-another-rendering-engine'>
                      any other rendering engine
                    </MUI.Link>{' '}
                    with minimal manual tweaking of the materials.
                  </>
                ),
                videoWebm: CreateRoomVideoWebM,
                videoMp4: CreateRoomVideoMp4,
                mediaShadow: true
              }
            ]}
            reverse
          />

          <Feature
            title='Keep your model lightweight'
            main={[
              {
                description: (
                  <>
                    A room is made of just a handful of faces, but from a distance it looks like a
                    fully furnished, textured, and lit interior.
                    <br />
                    This <b>keeps your model lightweight</b> even with dozens or hundreds of rooms.
                  </>
                ),
                imageCompare: {
                  image1: CompareWireframeImage,
                  image2: CompareRenderImage,
                  handleColor: theme.palette.primary.main
                },
                mediaShadow: true
              }
            ]}
          />

          <Feature
            title='Import custom rooms with RoomBox Pro'
            main={[
              {
                description: (
                  <>
                    For unlimited possibilities, you can even create custom rooms by{' '}
                    <b>importing your own interior maps</b>.<br />
                    <br />
                    <MUI.Grid
                      item
                      container
                      direction='row'
                      spacing={2}
                      alignItems='center'
                      wrap='nowrap'
                    >
                      <MUI.Grid item>
                        <MUIIcons.CheckCircleOutline color='primary' />
                      </MUI.Grid>
                      <MUI.Grid item>
                        Unlock RoomBox Pro by purchasing any room pack from the library.
                      </MUI.Grid>
                    </MUI.Grid>
                  </>
                ),
                videoWebm: CustomMapVideoWebM,
                videoMp4: CustomMapVideoMp4,
                mediaShadow: true
              }
            ]}
            reverse
          />
        </MUI.Box>

        <Feature
          title='Try the free samples'
          main={[
            {
              description: (
                <>
                  Get started right now by downloading RoomBox and trying the free rooms.
                  <br />
                  <br />
                  <MUI.Button
                    variant='contained'
                    color='primary'
                    size='large'
                    component={RouterLink}
                    to={`${routeMatch.path}?download`}
                    startIcon={<DownloadIcon />}
                  >
                    Download
                  </MUI.Button>
                </>
              ),
              image: TryNowImage
            }
          ]}
        />

        <MUI.Box id='creators' pt={12} pb={12}>
          <MUI.Container maxWidth='lg'>
            <MUI.Typography variant='h4' align='center' color='textSecondary'>
              Creators
            </MUI.Typography>

            <MUI.Box mt={2}>
              <MUI.Typography variant='body1' align='center' color='textSecondary'>
                We've teamed up with the best content creators from the industry to bring you the
                highest quality available on the market.
              </MUI.Typography>
            </MUI.Box>

            <MUI.Box display='flex' justifyContent='center' alignItems='center' flexWrap='wrap'>
              <MUI.Box className={classes.creator}>
                <img src={WParallaxLogo} alt='WParallax logo' />
              </MUI.Box>

              <MUI.Box className={classes.creator}>
                <img src={ShadersBoxLogo} alt='ShadersBox logo' />
              </MUI.Box>

              <MUI.Box className={classes.creator}>
                <img src={EvermotionLogo} alt='Evermotion logo' />
              </MUI.Box>
            </MUI.Box>
          </MUI.Container>
        </MUI.Box>

        {location.search === '?download' && (
          <InstallInstructions
            name='RoomBox'
            apiName='roombox'
            onClose={() => history.replace(routeMatch.path)}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
      </MUI.Box>
    </MUI.MuiThemeProvider>
  );
}

const roomImages = [
  {
    url: 'https://files.lindale.io/roombox/rooms/ss7zxqczld8/media/lpx2le5n2v6_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/9x7q2f7ro69/media/e1mjrhy0pv6_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/mp6nah7o17a/media/p940a5b86gv_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/e36snzjscm9/media/7ud4xq8icll_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/hvu973gs42u/media/my3axvpz5xs_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/shlpsbftg84/media/p9oidiauqlq_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/hr9k04mrbzc/media/h2nbzwfvoh9_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/r7o93tgzaqx/media/4whi8drtw4d_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/v0lehxycnmw/media/gpi3k4rq1x9_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/k81ksepdelu/media/7wkfm29fbki_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/m3iaxyfmfj9/media/0vwwitgcuji_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/rb866q51m47/media/cntp543h485_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/7dubue0sf6k/media/ww53mpuyno5_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/jh23i3sswv5/media/s1zbx4lczzp_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/ll5iqevjm96/media/94l8l52a74u_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/ume07pwb1h3/media/j8cfu5eqyii_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/naj5ti5tzje/media/uicrv03mw02_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/gxpqirt9mrs/media/4xcxdvneuc0_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/c89g24wd6x8/media/qnabhhrcahw_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/ukwuk3ccfqz/media/lr78r8uragf_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/vzshksz3jex/media/vvk5nn3ve1g_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/wkkiunttt8p/media/mobz2hfbkjq_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/z9f1a40gpkx/media/rx0pbe97az0_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/syf2v2p4vk7/media/q1cd1cmevuw_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/4by5btj1ybe/media/if5t3ofcvgz_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/h1bm30osvik/media/w79oueh0xhx_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/nt16byihdyu/media/0jr0dyok9n6_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/1d558n4nt8g/media/cu7vf9p7cdv_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/y8yx1ovu9e7/media/g3ey7g9f7a7_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/cptc4pn2g37/media/9acjx15940t_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/oxvxjs3pgta/media/dnmetv4qwte_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/ksmakppa7lb/media/8sz3sgnxgb2_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/cfx613rgosq/media/8y9g1mgge4f_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/36r967twevi/media/wb2gla9i371_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/arv6whuzcjw/media/gy5hponkequ_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/j2wbkdxdwr6/media/y91kiie1no7_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/k6ze9dt7rq0/media/60hn9dqxjt5_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/w2ux139skc5/media/fhglwidbrc3_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/yyn9ipeetqp/media/7yvjqw3d416_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/59b4seracra/media/a1kembj6hxl_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/wy18pmyeaz9/media/hbo8n0l9xu7_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/vswtryyfp1l/media/h7tsvzkchh1_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/41qftore4sx/media/2ok67vxitn8_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/9u9fg0w1es2/media/z47v77sk5l5_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/udwew0u25tq/media/v8fx7vxwp38_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/fl870dshl55/media/zvka8cgxs2h_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/u9jj8s99a16/media/cgsrxyfdj2i_512.jpg',
    size: 2
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/ltvxik9kqb6/media/9r7q7dk1wr2_256.jpg',
    size: 1
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/os64gv4qqzc/media/k54r90kxgzv_512.jpg',
    size: 3
  },
  {
    url: 'https://files.lindale.io/roombox/rooms/dniax4qk6al/media/phualg8u3q5_512.jpg',
    size: 2
  }
];

// Useful script to get a random set of room images for the carousel
/*function pickRoomImages(roomMetadata, count) {
  const rooms = new Set();

  while (rooms.size < count) {
    const room = roomMetadata[Math.floor(Math.random() * roomMetadata.length)];
    rooms.add(room);
  }

  const images = [...rooms].map((room) => {
    const res = room.width === 1 ? '256' : '512';
    return { url: room.images[0].urls['512'], size: room.width };
  });

  return images;
}*/
