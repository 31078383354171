import React from 'react';
import { Switch, Route, NavLink as RouterNavLink, useHistory } from 'react-router-dom';
import * as MUI from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalanceOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

import Payouts from './VendorDashboard/Payouts';
import PayoutSettings from './VendorDashboard/PayoutSettings';
import RoomBoxSales from './VendorDashboard/RoomBoxSales';
import BazaarSales from './VendorDashboard/BazaarSales';
import Terms from './VendorDashboard/Terms';
import { getCookie, NotifyFunction, parseJwt } from './Common/utils';

export enum MarketplaceName {
  ROOMBOX = 'roombox',
  BAZAAR = 'bazaar'
}

const drawerWidth = 240;
const useStyles = MUI.makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    minHeight: '800px'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  activeNavLink: {
    backgroundColor: '#eee',
    '& > .MuiListItemText-root > .MuiListItemText-primary': {
      fontWeight: 'bold'
    }
  }
}));

interface Props {
  jwt?: string;
  refreshJwt: (
    isRefreshingJwt: boolean,
    refreshSuccessCallback?: ((jwt: string) => void) | undefined
  ) => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function VendorDashboard(props: Props) {
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    // Redirect to login form if not logged in
    if (!props.jwt && !getCookie('refreshToken')) {
      history.push({ pathname: `/account/login`, search: `?return=${window.location.href}` });
    }
  }, [props.jwt]);

  return (
    <div className={classes.root}>
      <MUI.Drawer
        variant='permanent'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <MUI.List>
          <MUI.ListItem
            button
            component={RouterNavLink}
            to='/vendordashboard/payouts'
            exact
            activeClassName={classes.activeNavLink}
          >
            <MUI.ListItemIcon>
              <AccountBalanceIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText primary='Payouts' />
          </MUI.ListItem>

          <MUI.ListItem
            button
            component={RouterNavLink}
            to='/vendordashboard/payoutsettings'
            exact
            activeClassName={classes.activeNavLink}
          >
            <MUI.ListItemIcon>
              <SettingsIcon />
            </MUI.ListItemIcon>
            <MUI.ListItemText primary='Payout Settings' />
          </MUI.ListItem>

          {props.jwt && parseJwt(props.jwt).userGroups.includes('roomboxvendor') && (
            <>
              <MUI.ListSubheader>RoomBox</MUI.ListSubheader>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/vendordashboard/roombox/sales'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <MonetizationOnIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Sales' />
              </MUI.ListItem>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/vendordashboard/roombox/terms'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <DescriptionIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Terms of use' />
              </MUI.ListItem>
            </>
          )}

          {props.jwt && parseJwt(props.jwt).userGroups.includes('marketplacevendor') && (
            <>
              <MUI.ListSubheader>3D Bazaar</MUI.ListSubheader>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/vendordashboard/3dbazaar/sales'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <MonetizationOnIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Sales' />
              </MUI.ListItem>

              <MUI.ListItem
                button
                component={RouterNavLink}
                to='/vendordashboard/3dbazaar/terms'
                exact
                activeClassName={classes.activeNavLink}
              >
                <MUI.ListItemIcon>
                  <DescriptionIcon />
                </MUI.ListItemIcon>
                <MUI.ListItemText primary='Terms of use' />
              </MUI.ListItem>
            </>
          )}
        </MUI.List>
      </MUI.Drawer>

      <main className={classes.content}>
        <MUI.Container maxWidth='lg' className={classes.container}>
          <Switch>
            <Route path={['/vendordashboard', '/vendordashboard/payouts']} exact>
              <Payouts
                jwt={props.jwt}
                notify={props.notify}
                setMaintenanceMode={props.setMaintenanceMode}
              />
            </Route>

            <Route path='/vendordashboard/payoutsettings' exact>
              <PayoutSettings
                jwt={props.jwt}
                notify={props.notify}
                setMaintenanceMode={props.setMaintenanceMode}
              />
            </Route>

            <Route path='/vendordashboard/roombox/sales' exact>
              <RoomBoxSales
                jwt={props.jwt}
                notify={props.notify}
                setMaintenanceMode={props.setMaintenanceMode}
              />
            </Route>

            <Route path='/vendordashboard/roombox/terms' exact>
              <Terms
                jwt={props.jwt}
                refreshJwt={props.refreshJwt}
                notify={props.notify}
                setMaintenanceMode={props.setMaintenanceMode}
                marketplaceName={MarketplaceName.ROOMBOX}
              />
            </Route>

            <Route path='/vendordashboard/3dbazaar/sales' exact>
              <BazaarSales
                jwt={props.jwt}
                notify={props.notify}
                setMaintenanceMode={props.setMaintenanceMode}
              />
            </Route>

            <Route path='/vendordashboard/3dbazaar/terms' exact>
              <Terms
                jwt={props.jwt}
                refreshJwt={props.refreshJwt}
                notify={props.notify}
                setMaintenanceMode={props.setMaintenanceMode}
                marketplaceName={MarketplaceName.BAZAAR}
              />
            </Route>
          </Switch>
        </MUI.Container>
      </main>
    </div>
  );
}
