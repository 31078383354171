import React from 'react';

import * as MUI from '@material-ui/core';

interface Props {
  height?: 'tall' | 'medium' | 'small';
  logo?: JSX.Element;
  title?: string;
  subtitle?: string;
  titleFontWeight?: number | 'bold' | 'inherit' | 'initial' | 'normal' | 'bolder' | 'lighter';
  subtitleFontWeight?: number | 'bold' | 'inherit' | 'initial' | 'normal' | 'bolder' | 'lighter';
  description?: React.ReactNode;
  textShadow?: boolean;
  color?: string;
  action?: JSX.Element;
  backgroundOpacity?: number;
  backgroundImage?: string;
  backgroundImagePosition?: string;
  backgroundImageCredit?: string;
  backgroundVideoWebM?: string;
  backgroundVideoMp4?: string;
}
const defaultProps: Partial<Props> = {
  height: 'tall',
  textShadow: false,
  titleFontWeight: 800,
  subtitleFontWeight: 600,
  backgroundImagePosition: 'center',
  backgroundOpacity: 1
};

export default function Jumbotron(props: Props) {
  const theme = MUI.useTheme();

  let height;
  switch (props.height) {
    case 'tall':
      height = '40vw';
      break;
    case 'medium':
      height = '30vw';
      break;
    case 'small':
      height = '20vw';
      break;
  }

  return (
    <MUI.Box
      height={height}
      minHeight='400px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      position='relative'
      color={props.color}
    >
      <MUI.Box
        position='absolute'
        top='0'
        bottom='0'
        right='0'
        left='0'
        style={{
          opacity: props.backgroundOpacity,
          overflow: 'hidden',
          backgroundImage: props.backgroundImage ? `url('${props.backgroundImage}')` : undefined,
          backgroundPosition: props.backgroundImage ? props.backgroundImagePosition : undefined,
          backgroundSize: props.backgroundImage ? 'cover' : undefined
        }}
      >
        {(props.backgroundVideoWebM || props.backgroundVideoMp4) && (
          <video
            width='100%'
            height='100%'
            autoPlay
            muted
            loop
            style={{
              height: '100%',
              width: '177.77777778vh',
              minWidth: '100%',
              minHeight: '56.25vw',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            {props.backgroundVideoWebM && (
              <source src={props.backgroundVideoWebM} type='video/webm' />
            )}
            {props.backgroundVideoMp4 && (
              <source src={props.backgroundVideoMp4} type='video/webm' />
            )}
          </video>
        )}
      </MUI.Box>

      <MUI.Container maxWidth='lg' style={{ zIndex: 1 }}>
        <MUI.Box
          textAlign='center'
          style={{
            textShadow: props.textShadow ? '0 0 15px rgba(0,0,0,0.5)' : undefined
          }}
        >
          <MUI.Box display='flex' justifyContent='center' mb={3}>
            {props.logo}
          </MUI.Box>

          <MUI.Typography
            variant='h3'
            style={{
              fontWeight: props.titleFontWeight,
              marginBottom: theme.spacing(2)
            }}
          >
            {props.title}
          </MUI.Typography>

          <MUI.Typography
            variant='h4'
            style={{
              fontWeight: props.subtitleFontWeight
            }}
          >
            {props.subtitle}
          </MUI.Typography>

          <MUI.Typography
            variant='h6'
            style={{
              marginTop: theme.spacing(2)
            }}
          >
            {props.description}
          </MUI.Typography>

          <MUI.Box display='flex' justifyContent='center' mt={4}>
            {props.action}
          </MUI.Box>
        </MUI.Box>
      </MUI.Container>

      <MUI.Box position='absolute' bottom='12px' right='36px' style={{ opacity: 0.7 }}>
        <MUI.Typography variant='caption'>{props.backgroundImageCredit}</MUI.Typography>
      </MUI.Box>
    </MUI.Box>
  );
}
Jumbotron.defaultProps = defaultProps;
