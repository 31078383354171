import React from 'react';
import * as MUI from '@material-ui/core';

import { NotifyFunction, webApiCall, parseJwt, NotifVariant } from '../Common/utils';

type Activation = {
  id: string;
  activationDate: string;
  lastSyncDate: string;
  offline: boolean;
  os: string;
  computerName: string;
  country: string;
};

interface Props {
  loading?: boolean;
  jwt?: string;
  licenseKey: string;
  onActivationsRefresh: () => void;
  onClose: () => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function LicenseActivations(props: Props) {
  const [activations, setActivations] = React.useState<Activation[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [offlineActivationDialogOpen, setOfflineActivationDialogOpen] = React.useState(false);

  const fetchActivations = React.useCallback(() => {
    if (props.jwt) {
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'get',
        endpoint: `users/v1/${jwtUserId}/licenses/${props.licenseKey}/activations`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setActivations(response.data.data.activations);
          setLoading(false);
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt, props.licenseKey, props.notify, props.setMaintenanceMode]);

  const handleDeactivation = (activationId: string) => {
    if (props.jwt) {
      setLoading(true);
      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;
      webApiCall({
        method: 'delete',
        endpoint: `users/v1/${jwtUserId}/licenses/${props.licenseKey}/activations/${activationId}`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          fetchActivations();
          props.onActivationsRefresh();
        },
        errorCallback: (err) => {
          setLoading(false);
        }
      });
    }
  };

  React.useEffect(() => {
    fetchActivations();
  }, [fetchActivations]);

  return (
    <MUI.Dialog maxWidth='lg' fullWidth open onClose={props.onClose}>
      {offlineActivationDialogOpen && (
        <OfflineActivation
          licenseKey={props.licenseKey}
          jwt={props.jwt}
          loading={props.loading || loading}
          onClose={() => setOfflineActivationDialogOpen(false)}
          onActivationsRefresh={props.onActivationsRefresh}
          notify={props.notify}
          setMaintenanceMode={props.setMaintenanceMode}
        />
      )}

      {props.loading || loading ? (
        <MUI.CircularProgress />
      ) : (
        <>
          <MUI.DialogTitle>License activations</MUI.DialogTitle>
          <MUI.DialogContent>
            <MUI.DialogContentText>
              <MUI.Table size='small'>
                <MUI.TableHead>
                  <MUI.TableRow>
                    <MUI.TableCell></MUI.TableCell>
                    <MUI.TableCell>Computer name</MUI.TableCell>
                    <MUI.TableCell>OS</MUI.TableCell>
                    <MUI.TableCell>Country</MUI.TableCell>
                    <MUI.TableCell>Last Sync Date</MUI.TableCell>
                    <MUI.TableCell>Activation Date</MUI.TableCell>
                    <MUI.TableCell>Offline</MUI.TableCell>
                  </MUI.TableRow>
                </MUI.TableHead>

                <MUI.TableBody>
                  {activations.map((activation, i) => (
                    <MUI.TableRow key={i}>
                      <MUI.TableCell>
                        <MUI.Tooltip
                          title={
                            activation.offline
                              ? 'Offline activations cannot be deleted from here. You need to follow the offline deactivation procedure in the software itself.'
                              : ''
                          }
                        >
                          <div // This enables tooltips on disabled buttons
                          >
                            <MUI.Button
                              variant='outlined'
                              size='small'
                              onClick={() => handleDeactivation(activation.id)}
                              disabled={activation.offline}
                            >
                              Deactivate
                            </MUI.Button>
                          </div>
                        </MUI.Tooltip>
                      </MUI.TableCell>
                      <MUI.TableCell>{activation.computerName}</MUI.TableCell>
                      <MUI.TableCell>{activation.os}</MUI.TableCell>
                      <MUI.TableCell>{activation.country}</MUI.TableCell>
                      <MUI.TableCell>
                        {activation.lastSyncDate &&
                          new Date(activation.lastSyncDate).toISOString().slice(0, 10)}
                      </MUI.TableCell>
                      <MUI.TableCell>
                        {activation.activationDate &&
                          new Date(activation.activationDate).toISOString().slice(0, 10)}
                      </MUI.TableCell>
                      <MUI.TableCell>{activation.offline ? 'Yes' : 'No'}</MUI.TableCell>
                    </MUI.TableRow>
                  ))}
                </MUI.TableBody>
              </MUI.Table>
              <MUI.Button
                size='small'
                variant='outlined'
                onClick={() => setOfflineActivationDialogOpen(true)}
                style={{ marginTop: '16px' }}
              >
                Offline activation/deactivation
              </MUI.Button>
            </MUI.DialogContentText>
          </MUI.DialogContent>
          <MUI.DialogActions>
            <MUI.Button onClick={props.onClose} color='primary'>
              Close
            </MUI.Button>
          </MUI.DialogActions>
        </>
      )}
    </MUI.Dialog>
  );
}

interface OfflineActivationProps {
  jwt?: string;
  loading?: boolean;
  licenseKey: string;
  onActivationsRefresh: () => void;
  onClose: () => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

function OfflineActivation(props: OfflineActivationProps) {
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

  const [tab, setTab] = React.useState<'activate' | 'deactivate'>('activate');

  const [requestCode, setRequestCode] = React.useState('');
  const [activationData, setActivationData] = React.useState<string | undefined>();
  const [deactivationSuccess, setDeactivationSuccess] = React.useState(false);

  const submit = React.useCallback(() => {
    if (props.jwt) {
      setLoading(true);
      setErrors({});
      setActivationData(undefined);
      setDeactivationSuccess(false);

      const jwtProps = parseJwt(props.jwt);
      const jwtUserId = jwtProps.id;

      webApiCall({
        method: 'post',
        endpoint: `users/v1/${jwtUserId}/licenses/${props.licenseKey}/${
          tab === 'activate' ? 'activateoffline' : 'deactivateoffline'
        }`,
        jwt: props.jwt,
        data: { requestCode: requestCode },
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          if (tab === 'activate') {
            setActivationData(response.data.data.activationData);
          } else {
            setDeactivationSuccess(true);
          }
        },
        errorCallback: (response) => {
          if (response.data.data) setErrors(response.data.data);
        },
        ensureCallback: () => {
          setLoading(false);
        }
      });
    }
  }, [props.notify, props.setMaintenanceMode, props.jwt, props.licenseKey, tab, requestCode]);

  const downloadActivationFile = React.useCallback(() => {
    if (activationData) {
      const element = document.createElement('a');
      const file = new Blob([activationData], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      element.download = `activation_file_${props.licenseKey}.dat`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    } else {
      props.notify('Error: no activation data', NotifVariant.ERROR);
    }
  }, [props.notify, props.licenseKey, activationData]);

  const handleTabChange = React.useCallback(
    (event: React.ChangeEvent<{}>, newTab: 'activate' | 'deactivate') => {
      setRequestCode('');
      setTab(newTab);
      setErrors({});
      setActivationData(undefined);
      setDeactivationSuccess(false);
    },
    [setRequestCode, setTab]
  );

  return (
    <MUI.Dialog maxWidth='lg' fullWidth open onClose={props.onClose}>
      <MUI.DialogTitle>Offline activation</MUI.DialogTitle>
      <MUI.DialogContent>
        <MUI.DialogContentText>
          {errors.message && (
            <MUI.Box mt={1} mb={1}>
              <MUI.Typography variant='body1' color='error' style={{ fontWeight: 'bold' }}>
                {errors.message}
              </MUI.Typography>
            </MUI.Box>
          )}

          <MUI.Tabs value={tab} onChange={handleTabChange} style={{ marginBottom: '16px' }}>
            <MUI.Tab label='Activation' value='activate' />
            <MUI.Tab label='Deactivation' value='deactivate' />
          </MUI.Tabs>
          {tab === 'activate' && activationData ? (
            <MUI.Button
              variant='contained'
              disableElevation
              color='primary'
              fullWidth
              onClick={() => downloadActivationFile()}
            >
              Download Activation File
            </MUI.Button>
          ) : tab === 'deactivate' && deactivationSuccess ? (
            <MUI.Typography variant='body1' style={{ fontWeight: 'bold' }}>
              Deactivation successful!
            </MUI.Typography>
          ) : (
            <>
              <MUI.Typography variant='body2'>
                {tab === 'activate'
                  ? `Paste the Offline Activation Request Code that you got in the application, to generate the Offline Activation File.
                Then load the Offline Activation File in the application to activate the license
                without an internet connection.`
                  : `Paste the Offline Deactivation Request Code that you got in the application, to deactivate the license. This will free an activation slot.`}
              </MUI.Typography>
              <MUI.TextField
                value={requestCode}
                onChange={(e) => setRequestCode(e.target.value)}
                label={
                  tab === 'activate'
                    ? 'Offline Activation Request Code'
                    : 'Offline Deactivation Request Code'
                }
                multiline
                variant='outlined'
                margin='normal'
                fullWidth
                error={'requestCode' in errors}
                helperText={errors.requestCode}
                disabled={props.loading || loading}
              ></MUI.TextField>
              <MUI.Button
                variant='contained'
                disableElevation
                color='primary'
                size='small'
                fullWidth
                onClick={submit}
                disabled={!requestCode || requestCode.length === 0 || props.loading || loading}
              >
                {tab === 'activate' ? 'Activate' : 'Deactivate'}
              </MUI.Button>
            </>
          )}
        </MUI.DialogContentText>
      </MUI.DialogContent>
      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}
