import React from 'react';
import { Link as RouterLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as MUI from '@material-ui/core';
import { Download as DownloadIcon } from 'mdi-material-ui';
import { SRLWrapper } from 'simple-react-lightbox';

import Feature from './Common/Feature';
import Jumbotron from './Common/Jumbotron';
import ProductHeader from './Common/ProductHeader';
import ColorBand from './Common/ColorBand';
import SkatterPriceTable from './Skatter/SkatterPriceTable';
import SkatterInstallInstructions from './Skatter/SkatterInstallInstructions';
import { NotifyFunction, useScrollToTop } from './Common/utils';

import SkatterLogoText from '../media/logos/skatter_logo_text.svg';
import SkatterLogoTextMixed from '../media/logos/skatter_logo_text_mixed.svg';
import JumbotronImage from '../media/skatter/jumbotron.jpg';
import LifeImage from '../media/skatter/life.jpg';
import RenderOnlyImage from '../media/skatter/render_only.jpg';
import CameraClippingImage from '../media/skatter/camera_clipping.svg';
import LodImage from '../media/skatter/lod.svg';
import NatureImage from '../media/skatter/nature.jpg';
import SpeedometerImage from '../media/skatter/speedometer.svg';
import UiImage from '../media/skatter/ui.png';
import ZonesImage from '../media/skatter/zones.jpg';
import DistributionImage from '../media/skatter/distribution.jpg';
import TransformsImage from '../media/skatter/transforms.jpg';
import RandomMaterialsImage from '../media/skatter/random_materials.jpg';
import BoundariesImage from '../media/skatter/boundaries.jpg';
import AltitudeSlopeImage from '../media/skatter/altitude_slope.jpg';
import LookAtImage from '../media/skatter/look_at.jpg';
import BazaarImage from '../media/skatter/3dbazaar.jpg';
import PaintMaskImage from '../media/skatter/paint_mask.gif';
import ImageMaskImage from '../media/skatter/image_mask.jpg';
import NewPaintMaskImage from '../media/skatter/new_paint_mask.gif';
import PathMaskImage from '../media/skatter/path_mask.jpg';
import CompositionMaskImage from '../media/skatter/composition_mask.jpg';
import DistributionModesImage from '../media/skatter/distribution_modes.jpg';
import CustomFalloffsWebmVideo from '../media/skatter/custom_falloffs.webm';
import CustomFalloffsMp4Video from '../media/skatter/custom_falloffs.mp4';
import ConvertToSkatterImage from '../media/skatter/convert_to_composition.jpg';
import UsabilityWebmVideo from '../media/skatter/usability.webm';
import UsabilityMp4Video from '../media/skatter/usability.mp4';
import HolesImage from '../media/skatter/holes.png';
import UniformImage from '../media/skatter/uniform_v1_vs_v2.png';

import { GalleryImages } from './Skatter/GalleryImages';

export const theme = MUI.createTheme({
  palette: {
    primary: {
      light: '#f59894',
      main: '#eb3128',
      dark: '#dd130f',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#f59894',
      main: '#eb3128',
      dark: '#dd130f',
      contrastText: '#ffffff'
    }
  }
});

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Skatter(props: Props) {
  const history = useHistory();
  const location = useLocation();
  const routeMatch = useRouteMatch();

  useScrollToTop(location.pathname);

  const [whatsNewExpanded, setWhatsNewExpanded] = React.useState(false);
  React.useEffect(() => {
    if (location.hash === '#whatsnew') {
      setWhatsNewExpanded(true);
    }
  }, [location.hash]);

  return (
    <MUI.MuiThemeProvider theme={theme}>
      <MUI.Box style={{ position: 'relative' }}>
        <Helmet>
          <title>Lindalë - Skatter</title>
        </Helmet>

        <Jumbotron
          height='tall'
          logo={
            <img
              src={SkatterLogoTextMixed}
              alt='Skatter logo'
              style={{
                maxWidth: '400px',
                maxHeight: '100px',
                filter: 'drop-shadow( 0 0 15px rgba(0, 0, 0, .3))'
              }}
            />
          }
          subtitle='The most powerful Scattering Extension for SketchUp.'
          description={
            <>
              Render huge amounts of vegetation, quickly populate city blocks, create parametric
              assemblies, crowds, carpets, you name it...
              <br />
              All right inside SketchUp!
            </>
          }
          // color='#868686'
          color='#fff'
          subtitleFontWeight={400}
          textShadow
          action={
            <MUI.Button
              variant='contained'
              color='primary'
              size='large'
              component={RouterLink}
              to={`${routeMatch.path}?try`}
              startIcon={<DownloadIcon />}
            >
              Download trial
            </MUI.Button>
          }
          backgroundImage={JumbotronImage}
          backgroundImagePosition='center bottom'
          backgroundImageCredit='© Tô Tiến Vũ‎‎'
          backgroundOpacity={0.95}
        />

        <ProductHeader
          logo={SkatterLogoText}
          buttons={[
            {
              label: 'Overview',
              to: '/skatter#overview',
              color: 'inherit'
            },
            {
              label: `What's new`,
              to: '/skatter#whatsnew',
              color: 'inherit'
            },
            {
              label: 'Help',
              to: '/support?product=skatter',
              color: 'inherit'
            },
            {
              label: 'Try',
              to: '/skatter?try',
              color: 'primary',
              variant: 'outlined'
            },
            {
              label: 'Buy',
              to: '/skatter#buy',
              color: 'primary',
              variant: 'contained'
            }
          ]}
        ></ProductHeader>

        <MUI.Box id='overview'>
          <Feature
            title='Bring your models to life'
            main={[
              {
                description: (
                  <>
                    Cover your terrains with vegetation presets with a couple of clicks, or bring
                    your own assets to quickly populate your scenes with people, rocks, cars,
                    buildings, furniture...
                  </>
                ),
                image: LifeImage,
                imageFit: 'cover',
                imageCredit: '© Darchviz'
              }
            ]}
          />

          <Feature
            title='Render without restrictions'
            main={[
              {
                description: (
                  <>
                    Through its “<b>Render only”</b> feature, Skatter sends all the scattering
                    information directly to the render engines, bypassing Sketchup.
                    <br />
                    That way, you keep a <b>lightweight and responsive</b> SketchUp model, while
                    still being able to <b>render huge amounts of objects</b>.
                  </>
                ),
                image: RenderOnlyImage,
                imageFit: 'cover',
                imageCredit: '© Phan Thức'
              }
            ]}
            more={[
              {
                title: 'Camera clipping',
                description: (
                  <>
                    Save on memory and compute time by hiding all the objects that are not visible
                    to the camera.
                  </>
                ),
                image: CameraClippingImage
              },
              {
                title: 'LOD',
                description: (
                  <>
                    Replace objects with variants as they get farther away from the camera. For
                    instance, inject lower-resolution versions of your objects in order to boost
                    performances in your model.
                  </>
                ),
                image: LodImage
              }
            ]}
            reverse
          />

          <Feature
            title={`Mimic Nature's organic appearance`}
            main={[
              {
                description: (
                  <>
                    Nature is both random and organized. Skatter has many features to simulate both
                    and fine-tune the asset distribution and placement.
                  </>
                ),
                image: NatureImage,
                imageFit: 'cover',
                imageCredit: '© ORBRS'
              }
            ]}
            more={[
              {
                title: 'Zones',
                description: (
                  <>
                    Zones let you group similar objects together, like patches of flowers in a field
                    or lawn.
                  </>
                ),
                image: ZonesImage,
                imageFit: 'contain'
              },
              {
                title: 'Distribution',
                description: (
                  <>
                    Distribute assets randomly using the Random or Uniform distribution. Or go for a
                    more structured look with Grid, Faces, and Vertices distributions.
                  </>
                ),
                image: DistributionImage,
                imageFit: 'contain'
              },
              {
                title: 'Transforms',
                description: (
                  <>
                    Break repetition and randomize the objects placement by using random
                    translation, rotation, scale, and mirroring.
                  </>
                ),
                image: TransformsImage,
                imageFit: 'contain'
              },
              {
                title: 'Materials',
                description: (
                  <>
                    Randomly assign different materials to the objects to introduce subtle or
                    dramatic variations.
                  </>
                ),
                image: RandomMaterialsImage,
                imageFit: 'contain'
              },
              {
                title: 'Boundaries',
                description: <>Avoid harsh boundaries by gradually transitionning between areas.</>,
                image: BoundariesImage,
                imageFit: 'contain'
              },
              {
                title: 'Altitude & Slope',
                description: (
                  <>
                    Limit the distribution of plants based on altitude and terrain slope parameters.
                  </>
                ),
                image: AltitudeSlopeImage,
                imageFit: 'contain'
              },
              {
                title: 'Look At',
                description: (
                  <>
                    Make all the objects point to a target position. It's also possible to aim at
                    the sun's position as defined by SketchUp, which can come in very handy for
                    plants.
                  </>
                ),
                image: LookAtImage,
                imageFit: 'contain'
              }
            ]}
          />

          <Feature
            title='Content Library'
            main={[
              {
                description: (
                  <>
                    Skatter comes bundled with{' '}
                    <MUI.Link component={RouterLink} to='/3dbazaar'>
                      3D Bazaar
                    </MUI.Link>
                    , an <b>extensive repository of high-quality render-ready assets</b>.<br />
                    Many vegetation assets come with <b>ready-to-use Skatter compositions</b>, so
                    you can instantly apply them in your scene without spending time finding the
                    right parameters.
                  </>
                ),
                image: BazaarImage,
                imageFit: 'contain',
                mediaShadow: true
              }
            ]}
            reverse
          />

          <Feature
            title='You are always in control'
            main={[
              {
                description: (
                  <>
                    <b>Manually or parametrically fine-tune</b> the distribution of objects using
                    masks and other advanced features.
                  </>
                ),
                image: PaintMaskImage,
                imageFit: 'cover',
                mediaShadow: true
              }
            ]}
          />

          <Feature
            title='Speed up your workflow'
            main={[
              {
                description: (
                  <>
                    The clean and intuitive User Interface will <b>get you started in no time</b>,
                    while allowing you to precisely fine-tune advanced parameters.
                    <br />
                    <br />
                    Skatter is also <b>non-destructive</b>: you can go back and{' '}
                    <b>edit your work at any time</b>. Every option and parameter is saved, so there
                    is no need to delete everything and restart from scratch.
                  </>
                ),
                image: UiImage
              }
            ]}
            reverse
          />
        </MUI.Box>

        <ColorBand>
          <MUI.Box>
            <MUI.Typography variant='h5'>
              <i>
                "Game Changer: Skatter Could Be the Most Valuable SketchUp Plugin Yet for
                Architects"
              </i>
            </MUI.Typography>
          </MUI.Box>

          <MUI.Box mt={2}>- Architizer</MUI.Box>
        </ColorBand>

        <MUI.Box id='whatsnew' position='relative'>
          <MUI.Collapse in={whatsNewExpanded} collapsedSize={500} timeout={600}>
            <MUI.Box mt={4} textAlign='center'>
              <MUI.Typography variant='h4' color='textSecondary'>
                {' '}
                What's new in Skatter v2
              </MUI.Typography>
            </MUI.Box>

            <Feature
              youtubeEmbedId='DhjhbSPgrDQ'
              main={[
                {
                  mediaShadow: true
                }
              ]}
            />

            <Feature
              title='Up to 20x faster'
              main={[
                {
                  description: (
                    <>
                      A new scattering engine that we rebuilt from the ground up in order to boost
                      performances and to support new ambitious features.
                    </>
                  ),
                  image: SpeedometerImage
                }
              ]}
            />

            <Feature
              title='Zones'
              main={[
                {
                  description: (
                    <>
                      Zones let you group similar objects together, like patches of flowers in a
                      field or lawn.
                    </>
                  ),
                  image: ZonesImage,
                  imageFit: 'cover'
                }
              ]}
              reverse
            />

            <Feature
              title='New Masks'
              main={[
                {
                  title: 'Image mask',
                  description: (
                    <>
                      Use external images to filter instances and create complex patterns over
                      surfaces.
                    </>
                  ),
                  imageFit: 'contain',
                  image: ImageMaskImage
                },
                {
                  title: '3D Paint mask',
                  description: (
                    <>The Paint mask has been rebuilt to be more versatile and efficient.</>
                  ),
                  imageFit: 'contain',
                  image: NewPaintMaskImage
                },
                {
                  title: 'Path mask',
                  description: (
                    <>
                      Filter instances along curves with a controllable thickness, perfect for paths
                      and tire tracks.
                    </>
                  ),
                  imageFit: 'contain',
                  image: PathMaskImage
                },
                {
                  title: 'Composition mask',
                  description: (
                    <>
                      Spawns circular masks around each instance generated by another composition.
                      For instance, if there's a "Tree" composition then another "Dead leaves"
                      composition can use it as an inclusive mask so that leaves only appear around
                      the trunks of the trees.
                    </>
                  ),
                  image: CompositionMaskImage,
                  imageFit: 'contain'
                }
              ]}
            />

            <Feature
              title='Random Materials'
              main={[
                {
                  description: (
                    <>
                      Randomly assign different materials to the objects to introduce subtle or
                      dramatic variations.
                    </>
                  ),
                  image: RandomMaterialsImage
                }
              ]}
              reverse
            />

            <Feature
              title='Scatter in any direction'
              main={[
                {
                  title: 'Face-space projection',
                  description: (
                    <>
                      Select this new mode to wrap instances all around your hosts with <i>Grid</i>,{' '}
                      <i>Uniform</i>, or <i>Random</i> distributions. This replaces the{' '}
                      <i>Wrap (UV)</i> mode from Skatter 1, which was only compatible with{' '}
                      <i>Random</i> distributions.
                    </>
                  ),
                  image: DistributionModesImage,
                  imageFit: 'cover'
                },
                {
                  title: 'Object-space projection',
                  description: (
                    <>
                      Select this mode to project scattered instances along the local axis of a
                      surface host in order to obtain a consistent result regardless of its rotation
                      in space. This is especially useful for non-horizontal hosts.
                    </>
                  ),
                  image: DistributionModesImage,
                  imageFit: 'cover'
                }
              ]}
            />

            <Feature
              title='Brand-new UI'
              main={[
                {
                  title: 'Compositions Manager',
                  description: (
                    <>
                      A dedicated Compositions Manager replaces the old Render List, which allows
                      you to manage all of your compositions: In-Model and Render-Only.
                    </>
                  ),
                  image: UiImage
                },
                {
                  title: '3D Bazaar',
                  description: (
                    <>
                      Skatter now comes bundled with 3D Bazaar, which replaces the old Skatter v1
                      Library. It's an extensive repository of high-quality render-ready assets.
                      Many vegetation assets come with ready-to-use Skatter compositions, so you can
                      instantly apply them in your scene without spending time finding the right
                      parameters.
                    </>
                  ),
                  image: BazaarImage,
                  imageFit: 'contain',
                  mediaShadow: true
                },
                {
                  title: 'Custom falloffs',
                  description: (
                    <>A new curve editor allows you to finely control density and scale falloffs.</>
                  ),
                  videoWebm: CustomFalloffsWebmVideo,
                  videoMp4: CustomFalloffsMp4Video,
                  mediaShadow: true
                },
                {
                  title: 'Convert to Skatter',
                  description: (
                    <>
                      It is now possible to convert a set of existing components or groups to a
                      Skatter composition, to make use of its powerful features like Render-Only or
                      Random Transformations.
                    </>
                  ),
                  image: ConvertToSkatterImage
                },
                {
                  title: 'Usability',
                  description: (
                    <>
                      The new user interface offers many small usability improvements, like{' '}
                      <b>undo/redo</b>, <b>improved number inputs</b>, <b>mask footprints</b>,{' '}
                      <b>colored preview</b>, <b>previewing other compositions</b>,{' '}
                      <b>Custom Projection Axis gizmo</b>, etc.
                    </>
                  ),
                  videoWebm: UsabilityWebmVideo,
                  videoMp4: UsabilityMp4Video,
                  mediaShadow: true
                }
              ]}
              reverse
            />

            <Feature
              title='Many more improvements'
              main={[
                {
                  title: 'LODs',
                  description: (
                    <>
                      Replace objects with variants as they get farther away from the camera. For
                      instance, inject lower-resolution versions of your objects in order to boost
                      performances in your model.
                    </>
                  ),
                  image: LodImage
                },
                {
                  title: 'Look-At',
                  description: (
                    <>
                      Make all your instances point to a target position. It's also possible to aim
                      at the sun's position as defined by SketchUp, which can come in very handy for
                      plants.
                    </>
                  ),
                  image: LookAtImage,
                  imageFit: 'contain'
                },
                {
                  title: 'Holes',
                  description: (
                    <>
                      Parameters to generate holes in regular distributions like Grid, Vertices, and
                      Face Centers, as well as empty zones.
                    </>
                  ),
                  image: HolesImage
                },
                {
                  title: 'New Uniform',
                  description: (
                    <>
                      A new Uniform distribution type to generate natural-looking, yet random,
                      compositions. The old Uniform distribution from v1 has been merged with the
                      Grid distribution.
                    </>
                  ),
                  image: UniformImage
                }
              ]}
            />
          </MUI.Collapse>

          {!whatsNewExpanded && (
            <MUI.Box
              position='absolute'
              top='0'
              bottom='0'
              left='0'
              right='0'
              style={{
                background:
                  'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0) 100%)'
              }}
            >
              <MUI.Box position='absolute' top='50%' width='100%' textAlign='center'>
                <MUI.Typography variant='body1'>Already using Skatter v1?</MUI.Typography>
                <MUI.Box mt={2}>
                  <MUI.Button
                    variant='contained'
                    disableElevation
                    color='primary'
                    onClick={() => setWhatsNewExpanded(true)}
                  >
                    Discover What's New in Version 2
                  </MUI.Button>
                </MUI.Box>
              </MUI.Box>
            </MUI.Box>
          )}
        </MUI.Box>

        <MUI.Box pt={8} pb={8}>
          <MUI.Container maxWidth='lg'>
            <SRLWrapper
              options={{
                buttons: {
                  showAutoplayButton: false,
                  showDownloadButton: false,
                  showFullscreenButton: false,
                  showThumbnailsButton: false
                }
              }}
            >
              <MUI.Grid container spacing={1}>
                {Array(12)
                  .fill(0)
                  .map((x, i) => (
                    <MUI.Grid item xs={6} sm={4} md={3} style={{ lineHeight: 0 }} key={i}>
                      <a href={GalleryImages[i].image}>
                        <img
                          src={GalleryImages[i].thumb}
                          alt={GalleryImages[i].credit}
                          style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                      </a>
                    </MUI.Grid>
                  ))}
              </MUI.Grid>
            </SRLWrapper>
          </MUI.Container>
        </MUI.Box>

        <MUI.Box id='buy'>
          <MUI.Box mt={4} textAlign='center'>
            <MUI.Typography variant='h4' color='textSecondary'>
              Buy Skatter
            </MUI.Typography>
          </MUI.Box>

          <MUI.Box mt={4} textAlign='center'>
            <MUI.Button
              variant='contained'
              color='primary'
              component={RouterLink}
              to={`${routeMatch.path}?try`}
            >
              Download 15 days trial
            </MUI.Button>
          </MUI.Box>

          <SkatterPriceTable notify={props.notify} setMaintenanceMode={props.setMaintenanceMode} />
        </MUI.Box>

        {location.search === '?download' && (
          <SkatterInstallInstructions
            onClose={() => history.replace(routeMatch.path)}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
        {location.search === '?try' && (
          <SkatterInstallInstructions
            trial
            onClose={() => history.replace(routeMatch.path)}
            notify={props.notify}
            setMaintenanceMode={props.setMaintenanceMode}
          />
        )}
      </MUI.Box>
    </MUI.MuiThemeProvider>
  );
}
