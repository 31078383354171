import React from 'react';

import * as MUI from '@material-ui/core';

interface Props {
  children: React.ReactElement | React.ReactElement[];
}
const defaultProps: Partial<Props> = {};

export default function ColorBand(props: Props) {
  const theme = MUI.useTheme();

  return (
    <MUI.Box pt={4} pb={4}>
      <MUI.Box
        bgcolor={theme.palette.primary.main}
        color={theme.palette.primary.contrastText}
        pt={4}
        pb={4}
        textAlign='center'
      >
        <MUI.Container maxWidth='lg'>{props.children}</MUI.Container>
      </MUI.Box>
    </MUI.Box>
  );
}
ColorBand.defaultProps = defaultProps;
