import React from 'react';
import * as MUI from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Base64 } from 'js-base64';
import { NotifyFunction, webApiCall, parseJwt } from '../Common/utils';
import { MarketplaceName } from '../VendorDashboard';
import { useLocation } from 'react-router';

interface Props {
  marketplaceName: MarketplaceName;
  jwt?: string;
  refreshJwt: (
    isRefreshingJwt: boolean,
    refreshSuccessCallback?: ((jwt: string) => void) | undefined
  ) => void;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Terms(props: Props) {
  const [loading, setLoading] = React.useState(true);
  const [terms, setTerms] = React.useState<any | undefined>(undefined);

  const location = useLocation();

  let apiEndpoint = '';
  switch (props.marketplaceName) {
    case MarketplaceName.ROOMBOX:
      apiEndpoint = 'roombox';
      break;
    case MarketplaceName.BAZAAR:
      apiEndpoint = 'marketplace';
      break;

    default:
      break;
  }

  React.useEffect(() => {
    if (props.jwt) {
      setLoading(true);
      webApiCall({
        method: 'get',
        endpoint: `terms/v1/get/${apiEndpoint}`,
        jwt: props.jwt,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setTerms(response.data.data.terms);
        },
        ensureCallback: () => {
          setLoading(false);
        }
      });
    }
  }, [props.jwt, location]);

  const termsId = terms ? terms.id : undefined;
  let termsAccepted = false;
  if (props.jwt && termsId) {
    const termsAcceptances = JSON.parse(Base64.decode(props.jwt.split('.')[1])).termsAcceptances;
    termsAccepted = termsAcceptances?.includes(termsId) ?? false;
  }

  const handleAcceptTerms = React.useCallback(() => {
    setLoading(true);
    webApiCall({
      method: 'post',
      endpoint: `terms/v1/accept/${termsId}`,
      jwt: props.jwt,
      notify: props.notify,
      setMaintenanceMode: props.setMaintenanceMode,
      successCallback: (response) => {
        setTerms(response.data.data.terms);
        props.refreshJwt(false); // Will load the new acceptance and set setLoadingTerms(false)
      }
    });
  }, [props.jwt, termsId]);

  return (
    <>
      {loading || !terms ? (
        <MUI.CircularProgress />
      ) : (
        <>
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            // renderers={{
            //   paragraph: (props) => <React.Fragment {...props} />
            // }}
          >
            {terms.body}
          </ReactMarkdown>
          <p>Last updated: {terms.date}</p>
          <MUI.Tooltip title={termsAccepted ? 'You already accepted those terms of use' : ''}>
            <MUI.Box marginY={6}>
              {' '}
              {/* for disabled tooltip to work */}
              <MUI.Button
                fullWidth
                color='primary'
                variant='contained'
                disabled={termsAccepted}
                onClick={handleAcceptTerms}
                className='accept-terms-button'
              >
                I Accept
              </MUI.Button>
            </MUI.Box>
          </MUI.Tooltip>
        </>
      )}
    </>
  );
}
