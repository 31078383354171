import React from 'react';
import * as MUI from '@material-ui/core';

import { Download, MicrosoftWindows, Apple } from 'mdi-material-ui';

import { NotifyFunction } from '../Common/utils';
import { downloadVersion } from '../Common/InstallInstructions';

interface Props {
  trial?: boolean;
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
  onClose: () => void;
}

function TransmutrDownloadOverlay(props: Props) {
  return (
    <MUI.Dialog open onClose={props.onClose} maxWidth='sm' fullWidth>
      <MUI.DialogTitle>
        <MUI.Box display='flex' alignItems='center' justifyContent='center' mt={2}>
          <Download style={{ marginRight: '8px' }} /> Download Transmutr {props.trial && 'Trial'}
        </MUI.Box>
      </MUI.DialogTitle>

      <MUI.DialogContent>
        <MUI.DialogContentText>
          <MUI.Container maxWidth='xs'>
            {props.trial && (
              <MUI.Typography align='center'>
                The Trial is full-featured, but limited to 7 days.
              </MUI.Typography>
            )}

            <MUI.Box mt={4}>
              <MUI.Grid container spacing={2}>
                <MUI.Grid item xs={6}>
                  <MUI.Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    size='large'
                    startIcon={<MicrosoftWindows />}
                    onClick={() =>
                      downloadVersion(
                        'transmutr/v1/latestversion',
                        'latestVersion',
                        props.notify,
                        props.setMaintenanceMode,
                        'win'
                      )
                    }
                  >
                    Windows
                  </MUI.Button>
                </MUI.Grid>
                <MUI.Grid item xs={6}>
                  <MUI.Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    size='large'
                    startIcon={<Apple />}
                    onClick={() =>
                      downloadVersion(
                        'transmutr/v1/latestversion',
                        'latestVersion',
                        props.notify,
                        props.setMaintenanceMode,
                        'mac'
                      )
                    }
                  >
                    MacOS
                  </MUI.Button>
                </MUI.Grid>
              </MUI.Grid>
            </MUI.Box>

            <MUI.Box
              mt={4}
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <MUI.Link href='https://help.transmutr.com' variant='body2'>
                Learning Resources
              </MUI.Link>
              <MUI.Link href='https://help.transmutr.com/manual/release-notes' variant='body2'>
                Release Notes
              </MUI.Link>
            </MUI.Box>
          </MUI.Container>
        </MUI.DialogContentText>
      </MUI.DialogContent>

      <MUI.DialogActions>
        <MUI.Button onClick={props.onClose} color='primary'>
          Close
        </MUI.Button>
      </MUI.DialogActions>
    </MUI.Dialog>
  );
}

export default React.memo(TransmutrDownloadOverlay);
