import React, { useCallback } from 'react';
import { Link as RouterLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import classnames from 'classnames';
import _ from 'lodash';

import * as MUI from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import OtherIcon from '@material-ui/icons/MoreHoriz';
import HelpIcon from '@material-ui/icons/HelpOutline';
import DocsIcon from '@material-ui/icons/DescriptionOutlined';
import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import DownloadIcon from '@material-ui/icons/GetAppOutlined';
import ForumIcon from '@material-ui/icons/ForumOutlined';

import { webApiCall, NotifyFunction } from './Common/utils';
import SkatterLogo from '../media/logos/skatter_logo_128.png';
import TransmutrLogo from '../media/logos/transmutr_logo_big.svg';
import RoomboxLogo from '../media/logos/roombox_logo.svg';
import BazaarLogo from '../media/logos/3dbazaar_logo_big.svg';

enum AnswerSource {
  DOCS = 'docs',
  FORUMS = 'forums'
}

type Answer = {
  source: AnswerSource;
  title: string;
  path: string;
  url: string;
};

const defaultAnswers = {
  skatter: [
    {
      source: AnswerSource.DOCS,
      title: "I have lost or didn't receive my license key",
      path: '/faq/i-have-lost-or-didnt-receive-my-license-key',
      url: 'https://skatter.help.lindale.io/faq/i-have-lost-or-didnt-receive-my-license-key'
    },
    {
      source: AnswerSource.DOCS,
      title: 'How can I reset my license activations?',
      path: '/faq/how-can-i-reset-my-license-activations',
      url: 'https://skatter.help.lindale.io/faq/how-can-i-reset-my-license-activations'
    },
    {
      source: AnswerSource.DOCS,
      title: 'Getting started with Skatter',
      path: '/getting-started',
      url: 'https://skatter.help.lindale.io/getting-started'
    }
  ],
  transmutr: [
    {
      source: AnswerSource.DOCS,
      title: "I have lost or didn't receive my license key",
      path: '/faq/i-have-lost-or-didnt-receive-my-license-key',
      url: 'https://help.transmutr.com/faq/i-have-lost-or-didnt-receive-my-license-key'
    },
    {
      source: AnswerSource.DOCS,
      title: 'How can I reset my license activations?',
      path: '/faq/how-can-i-reset-my-license-activations',
      url: 'https://help.transmutr.com/faq/how-can-i-reset-my-license-activations'
    },
    {
      source: AnswerSource.DOCS,
      title: 'Getting started with Transmutr',
      path: '/getting-started',
      url: 'https://help.transmutr.com/getting-started'
    }
  ],
  roombox: [
    {
      source: AnswerSource.DOCS,
      title: 'How to unlock the Pro version of RoomBox?',
      path: '/faq/how-to-unlock-the-pro-version-of-roombox',
      url: 'https://roombox.help.lindale.io/faq/how-to-unlock-the-pro-version-of-roombox'
    },
    {
      source: AnswerSource.DOCS,
      title: 'Importing custom maps',
      path: '/manual/importing-custom-maps',
      url: 'https://roombox.help.lindale.io/manual/importing-custom-maps'
    },
    {
      source: AnswerSource.DOCS,
      title: 'Getting started with RoomBox',
      path: '/getting-started',
      url: 'https://roombox.help.lindale.io/getting-started'
    }
  ],
  other: [
    {
      source: AnswerSource.DOCS,
      title: "I have lost or didn't receive my license key",
      path: '/faq/i-have-lost-or-didnt-receive-my-license-key',
      url: 'https://skatter.help.lindale.io/faq/i-have-lost-or-didnt-receive-my-license-key'
    },
    {
      source: AnswerSource.DOCS,
      title: 'How can I reset my license activations?',
      path: '/faq/how-can-i-reset-my-license-activations',
      url: 'https://skatter.help.lindale.io/faq/how-can-i-reset-my-license-activations'
    }
  ]
};

const useStyles = MUI.makeStyles((theme: MUI.Theme) =>
  MUI.createStyles({
    clickableItem: {
      width: '100%',
      padding: '16px',
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${MUI.alpha(theme.palette.primary.main, 0.2)}`,
      '&:hover, &.selected': {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: MUI.alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity)
      },
      '&.faded': {
        color: theme.palette.text.disabled,
        border: `1px solid ${MUI.alpha(theme.palette.primary.main, 0.1)}`
      },
      transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
        duration: theme.transitions.duration.short
      })
    },
    productLogo: {
      height: '48px',
      width: '48px',
      backgroundColor: 'transparent',
      '.faded &': {
        opacity: 0.5
      }
    },
    productName: {
      fontWeight: 600,
      marginTop: '8px',
      whiteSpace: 'nowrap'
    },
    productIcon: {
      fontSize: '48px',
      color: theme.palette.text.disabled
    },
    sectionIcon: {
      fontSize: '32px',
      color: theme.palette.text.disabled
    }
  })
);

function ProductButton({
  name,
  title,
  icon
}: {
  name: string;
  title: string;
  icon: React.ReactElement;
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const locationParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <MUI.Grid item xs={6} sm={4} md>
      <MUI.ButtonBase
        onClick={() => {
          history.push({
            pathname: location.pathname,
            search: '?product=' + name
          });
        }}
        className={classnames(classes.clickableItem, {
          selected: locationParams.product === name,
          faded:
            locationParams.product !== undefined &&
            locationParams.product !== '' &&
            locationParams.product !== name
        })}
      >
        <MUI.Box display='flex' flexDirection='column' alignItems='center'>
          {icon}
          <MUI.Typography variant='h6' className={classes.productName}>
            {title}
          </MUI.Typography>
        </MUI.Box>
      </MUI.ButtonBase>
    </MUI.Grid>
  );
}

function LinkButton({
  title,
  icon,
  routerLink,
  to,
  xs = 6,
  sm = 4
}: {
  title: string;
  icon: React.ReactElement;
  routerLink?: boolean;
  to: string;
  xs?: MUI.GridSize;
  sm?: MUI.GridSize;
}) {
  const classes = useStyles();

  const buttonContent = (
    <MUI.Box display='flex' flexDirection='column' alignItems='center'>
      {icon}
      <MUI.Typography variant='h6' className={classes.productName}>
        {title}
      </MUI.Typography>
    </MUI.Box>
  );

  return (
    <MUI.Grid item xs={xs} sm={sm} md>
      <RouterLink to=''></RouterLink>
      {routerLink ? (
        <MUI.ButtonBase className={classes.clickableItem} component={RouterLink} to={to}>
          {buttonContent}
        </MUI.ButtonBase>
      ) : (
        <MUI.ButtonBase className={classes.clickableItem} href={to}>
          {buttonContent}
        </MUI.ButtonBase>
      )}
    </MUI.Grid>
  );
}

interface Props {
  notify: NotifyFunction;
  setMaintenanceMode: (enable: boolean) => void;
}

export default function Support(props: Props) {
  const classes = useStyles();

  const location = useLocation();
  const locationParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [answers, setAnswers] = React.useState<Answer[]>([]);
  const [searchStr, setSearchStr] = React.useState('');
  const [searching, setSearching] = React.useState(false);

  const setDefaultAnswers = useCallback(() => {
    setAnswers([]);
    if (locationParams.product === 'skatter') {
      setAnswers(defaultAnswers.skatter);
    }
    if (locationParams.product === 'transmutr') {
      setAnswers(defaultAnswers.transmutr);
    }
    if (locationParams.product === 'roombox') {
      setAnswers(defaultAnswers.roombox);
    }
    if (locationParams.product === 'other') {
      setAnswers(defaultAnswers.other);
    }
  }, [locationParams.product]);

  React.useEffect(() => {
    setDefaultAnswers();
    setSearchStr('');
  }, [locationParams.product, setDefaultAnswers]);

  const fetchSearchResults = (product: string, query: string) => {
    if (query.length < 4) {
      setDefaultAnswers();
    } else {
      setSearching(true);
      webApiCall({
        method: 'get',
        endpoint: `support/v1/search/?product=${product}&query=${query}`,
        notify: props.notify,
        setMaintenanceMode: props.setMaintenanceMode,
        successCallback: (response) => {
          setAnswers(response.data.data.answers);
          setSearching(false);
        }
      });
    }
  };

  const debouncedfetchSearchResults = React.useCallback(_.debounce(fetchSearchResults, 300), []);

  const handleSearchStrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target.value);
    if (locationParams.product) {
      debouncedfetchSearchResults(locationParams.product as string, event.target.value);
    }
  };

  let contactEmail = 'contact@lindale.io';
  switch (locationParams.product) {
    case 'skatter':
      contactEmail = 'skatter@lindale.io';
      break;
    case 'transmutr':
      contactEmail = 'transmutr@lindale.io';
      break;
  }

  return (
    <MUI.Box style={{ position: 'relative' }} pt={12} pb={12}>
      <Helmet>
        <title>Lindalë - Support</title>
      </Helmet>
      <MUI.Typography variant='h3' align='center'>
        What do you need help with?
      </MUI.Typography>
      <MUI.Container maxWidth='md' style={{ minHeight: '65vh' }}>
        <MUI.Box display='flex' flexDirection='column' alignItems='center'>
          <MUI.Grid container spacing={2} style={{ marginTop: '32px' }}>
            <ProductButton
              name='skatter'
              title='Skatter'
              icon={<MUI.Avatar src={SkatterLogo} className={classes.productLogo} />}
            />
            <ProductButton
              name='transmutr'
              title='Transmutr'
              icon={<MUI.Avatar src={TransmutrLogo} className={classes.productLogo} />}
            />
            <ProductButton
              name='roombox'
              title='RoomBox'
              icon={<MUI.Avatar src={RoomboxLogo} className={classes.productLogo} />}
            />
            <ProductButton
              name='3dbazaar'
              title='3D Bazaar'
              icon={<MUI.Avatar src={BazaarLogo} className={classes.productLogo} />}
            />
            <ProductButton
              name='other'
              title='Other'
              icon={<OtherIcon className={classes.productIcon} />}
            />
          </MUI.Grid>

          {locationParams.product && (
            <>
              <MUI.Grid container spacing={2} style={{ marginTop: '32px' }}>
                {locationParams.product === 'skatter' && (
                  <>
                    <LinkButton
                      title='Help Docs'
                      icon={<DocsIcon className={classes.sectionIcon} />}
                      to='https://skatter.help.lindale.io/'
                    />
                    <LinkButton
                      title='Tutorials'
                      icon={<SchoolIcon className={classes.sectionIcon} />}
                      to='https://skatter.help.lindale.io/tutorials'
                    />
                    <LinkButton
                      title='FAQ'
                      icon={<HelpIcon className={classes.sectionIcon} />}
                      to='https://skatter.help.lindale.io/faq'
                    />
                    <LinkButton
                      title='Download'
                      icon={<DownloadIcon className={classes.sectionIcon} />}
                      routerLink
                      to='/skatter?download'
                    />
                    <LinkButton
                      title='Forums'
                      icon={<ForumIcon className={classes.sectionIcon} />}
                      to='https://forums.lindale.io/c/skatter/16'
                    />
                  </>
                )}
                {locationParams.product === 'transmutr' && (
                  <>
                    <LinkButton
                      title='Help Docs'
                      icon={<DocsIcon className={classes.sectionIcon} />}
                      to='https://help.transmutr.com/'
                    />
                    <LinkButton
                      title='Tutorials'
                      icon={<SchoolIcon className={classes.sectionIcon} />}
                      to='https://help.transmutr.com/tutorials'
                    />
                    <LinkButton
                      title='FAQ'
                      icon={<HelpIcon className={classes.sectionIcon} />}
                      to='https://help.transmutr.com/faq'
                    />
                    <LinkButton
                      title='Download'
                      icon={<DownloadIcon className={classes.sectionIcon} />}
                      routerLink
                      to='/transmutr?download'
                    />
                    <LinkButton
                      title='Forums'
                      icon={<ForumIcon className={classes.sectionIcon} />}
                      to='https://forums.lindale.io/c/transmutr/15'
                    />
                  </>
                )}
                {locationParams.product === 'roombox' && (
                  <>
                    <LinkButton
                      title='Help Docs'
                      icon={<DocsIcon className={classes.sectionIcon} />}
                      to='https://roombox.help.lindale.io/'
                    />
                    {/* <LinkButton
                      title='Tutorials'
                      icon={<SchoolIcon className={classes.sectionIcon} />}
                      to='https://roombox.help.lindale.io/tutorials'
                    /> */}
                    <LinkButton
                      title='FAQ'
                      icon={<HelpIcon className={classes.sectionIcon} />}
                      to='https://roombox.help.lindale.io/faq'
                    />
                    <LinkButton
                      title='Download'
                      icon={<DownloadIcon className={classes.sectionIcon} />}
                      routerLink
                      to='/roombox?download'
                    />
                    <LinkButton
                      title='Forums'
                      icon={<ForumIcon className={classes.sectionIcon} />}
                      to='https://forums.lindale.io/c/roombox/27'
                    />
                  </>
                )}
                {locationParams.product === '3dbazaar' && (
                  <>
                    <LinkButton
                      title='Tutorials'
                      icon={<SchoolIcon className={classes.sectionIcon} />}
                      to='https://www.youtube.com/channel/UC4rZ9jlY5Z00A9zKh_u5Gig/videos'
                    />
                    <LinkButton
                      title='Download'
                      icon={<DownloadIcon className={classes.sectionIcon} />}
                      routerLink
                      to='/3dbazaar?download'
                    />
                    <LinkButton
                      title='Forums'
                      icon={<ForumIcon className={classes.sectionIcon} />}
                      to='https://forums.lindale.io/c/3d-bazaar/21'
                    />
                  </>
                )}
                {locationParams.product === 'other' && (
                  <>
                    <LinkButton
                      title='Tutorials'
                      icon={<SchoolIcon className={classes.sectionIcon} />}
                      to='https://www.youtube.com/channel/UC4rZ9jlY5Z00A9zKh_u5Gig/videos'
                      sm={6}
                    />
                    <LinkButton
                      title='Forums'
                      icon={<ForumIcon className={classes.sectionIcon} />}
                      to='https://forums.lindale.io/c/transmutr/15'
                      sm={6}
                    />
                  </>
                )}
              </MUI.Grid>

              <MUI.TextField
                value={searchStr}
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <MUI.InputAdornment position='start'>
                      <SearchIcon />
                    </MUI.InputAdornment>
                  )
                }}
                variant='outlined'
                fullWidth
                style={{ marginTop: '40px' }}
                onChange={handleSearchStrChange}
              />

              {!searching && (
                <>
                  {answers.map((answer, i) => {
                    return (
                      <MUI.ButtonBase
                        className={classes.clickableItem}
                        style={{
                          width: '100%',
                          justifyContent: 'start',
                          padding: '12px',
                          marginTop: '8px'
                        }}
                        href={answer.url}
                        key={i}
                      >
                        <MUI.Box display='flex' textAlign='initial' alignItems='center'>
                          <MUI.Box width='40px' mr={1} textAlign='center'>
                            {answer.source === AnswerSource.DOCS ? (
                              <DocsIcon className={classes.sectionIcon} />
                            ) : (
                              <ForumIcon className={classes.sectionIcon} />
                            )}
                          </MUI.Box>
                          <MUI.Box>
                            <MUI.Typography variant='body1' style={{ fontWeight: 500 }}>
                              {answer.title}
                            </MUI.Typography>
                            <MUI.Typography variant='caption' color='textSecondary'>
                              {answer.path}
                            </MUI.Typography>
                          </MUI.Box>
                        </MUI.Box>
                      </MUI.ButtonBase>
                    );
                  })}
                  {!searching && answers.length > 0 && (
                    <MUI.Box mt={2} textAlign='center'>
                      {(locationParams.product === 'skatter' ||
                        locationParams.product === 'transmutr') && (
                        <MUI.Button
                          variant='outlined'
                          href={`https://${
                            locationParams.product === 'skatter'
                              ? 'skatter.help.lindale.io'
                              : 'help.transmutr.com'
                          }/?q=${searchStr}`}
                          style={{ marginRight: '16px' }}
                        >
                          Find more in the Help Docs
                        </MUI.Button>
                      )}
                      <MUI.Button
                        variant='outlined'
                        href={`https://forums.lindale.io/search?q=${encodeURI(searchStr)}%20%23${
                          locationParams.product
                        }`}
                      >
                        Find more in the Forums
                      </MUI.Button>
                    </MUI.Box>
                  )}
                </>
              )}

              {!searching && answers.length === 0 && (
                <MUI.Box mt={3}>
                  <MUI.Typography variant='caption' style={{ fontStyle: 'italic' }}>
                    No search results
                  </MUI.Typography>
                </MUI.Box>
              )}

              {searching && (
                <MUI.Box mt={3} display='flex' justifyContent='center'>
                  <MUI.CircularProgress />
                </MUI.Box>
              )}

              <MUI.Typography variant='h5' align='center' style={{ marginTop: '64px' }}>
                Still cannot find what you are looking for?
              </MUI.Typography>
              <MUI.Typography variant='body1' align='center' style={{ marginTop: '16px' }}>
                Ask your question{' '}
                <MUI.Link
                  color='inherit'
                  href={`https://forums.lindale.io/`}
                  style={{ textDecoration: 'underline' }}
                >
                  in the forums
                </MUI.Link>
                , or send us an email at{' '}
                <MUI.Link
                  color='inherit'
                  href={`mailto:${contactEmail}`}
                  style={{ textDecoration: 'underline' }}
                >
                  {contactEmail}
                </MUI.Link>
              </MUI.Typography>
            </>
          )}
        </MUI.Box>
      </MUI.Container>
    </MUI.Box>
  );
}
