import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as MUI from '@material-ui/core';

interface Props {
  logo?: string;
  titleLogo?: string;
  title?: string;
  subtitle?: string;
  color?: string;
  action?: JSX.Element;
  image?: string;
  imageCredit?: string;
  href?: string;
  to?: string;
  productColor: string;
}

const useStyles = MUI.makeStyles({
  wrapper: {
    '& $image': {
      boxShadow: 'inset 0px 0px 500px rgba(0, 0, 0, 0.5)' // Vignette effect
    },
    '&:hover $image': {
      transform: 'scale(1.1)',
      boxShadow: 'none'
    },
    padding: '16px',
    textShadow: '0 0 20px rgb(0, 0, 0, 1)'
  },
  image: {
    transition: 'all .5s'
  },
  logo: {
    height: '96px',
    width: '96px',
    backgroundColor: '#fff',
    padding: '8px',
    boxShadow: '0 0 20px rgb(0, 0, 0, 0.2)',
    marginBottom: '16px'
  },
  productName: {
    borderRadius: '4px',
    boxShadow: '0 0 20px black',
    textShadow: 'none',
    letterSpacing: '0.1rem',
    padding: '0.15rem 0.75rem',
    marginBottom: '1rem'
  },
  productDescription: {
    textShadow: '3px 3px 2px black'
  }
});

export default function ProductCard(props: Props) {
  const classes = useStyles();

  return (
    <MUI.Grid item xs={12} sm={6} lg={3}>
      <MUI.Link href={props.href} component={props.to ? RouterLink : 'a'} to={props.to}>
        <MUI.Box position='relative' color={props.color}>
          <MUI.Box pt='100%' />

          <MUI.Box
            className={classes.wrapper}
            position='absolute'
            top='4px'
            bottom='4px'
            left='4px'
            right='4px'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
            overflow='hidden'
            textAlign='center'
          >
            <MUI.Box
              className={classes.image}
              position='absolute'
              top='0'
              bottom='0'
              left='0'
              right='0'
              style={{
                backgroundImage: `url("${props.image}")`,
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }}
            />

            <MUI.Avatar src={props.logo} className={classes.logo} />
            <MUI.Typography
              variant='h4'
              style={{ zIndex: 10, fontWeight: 600, backgroundColor: props.productColor }}
              className={classes.productName}
            >
              {props.title}
            </MUI.Typography>
            <MUI.Typography
              variant='subtitle1'
              style={{ zIndex: 10, fontWeight: 400 }}
              className={classes.productDescription}
            >
              {props.subtitle}
            </MUI.Typography>
          </MUI.Box>

          <MUI.Box position='absolute' bottom='12px' right='16px' style={{ opacity: 0.7 }}>
            <MUI.Typography variant='caption'>{props.imageCredit}</MUI.Typography>
          </MUI.Box>
        </MUI.Box>
      </MUI.Link>
    </MUI.Grid>
  );
}
