import React from 'react';
import { useLocation } from 'react-router';
import * as MUI from '@material-ui/core';

import Jumbotron from './Common/Jumbotron';
import ProductCard from './Home/ProductCard';

import lindaleLogoCircleInvert from '../media/logos/lindale_logo_circle_invert.svg';
import JumbotronImage from '../media/home_hero.jpg';
import BazaarLogo from '../media/logos/3dbazaar_logo_big.svg';
import BazaarImage from '../media/3dbazaar.jpg';
import SkatterLogo from '../media/logos/skatter_logo_128.png';
import SkatterImage from '../media/skatter.jpg';
import TransmutrLogo from '../media/logos/transmutr_logo_big.svg';
import TransmutrImage from '../media/transmutr.jpg';
import RoomboxLogo from '../media/logos/roombox_logo.svg';
import RoomboxImage from '../media/roombox.jpg';

import LegoLogo from '../media/clients/lego.png';
import ValveLogo from '../media/clients/valve.png';
import VolvoLogo from '../media/clients/volvo.png';
import RocheBoboisLogo from '../media/clients/rochebobois.png';
import SmithGroupLogo from '../media/clients/smithgroup.png';
import CallisonRTKLLogo from '../media/clients/callisonrtkl.png';
import { useScrollToTop } from './Common/utils';

interface Props {}

const useStyles = MUI.makeStyles({
  client: {
    margin: '32px',
    '& img': {
      maxWidth: '128px'
    }
  },
  clientsTitle: {
    fontSize: '0.8rem',
    textTransform: 'uppercase',
    letterSpacing: '0.2rem',
    color: 'grey'
  }
});

export default function Home(props: Props) {
  const theme = MUI.useTheme();
  const classes = useStyles();
  const location = useLocation();

  useScrollToTop(location.pathname);

  return (
    <MUI.Box bgcolor={theme.palette.primary.main}>
      <Jumbotron
        logo={
          <MUI.Avatar
            src={lindaleLogoCircleInvert}
            style={{
              height: theme.spacing(8),
              width: theme.spacing(8)
            }}
          />
        }
        title='Lindalë'
        subtitle="Let's create stunning SketchUp worlds"
        textShadow
        color={theme.palette.primary.contrastText}
        backgroundImage={JumbotronImage}
        backgroundImageCredit='© ORBRS'
      />

      <MUI.Box marginTop='4px'>
        <MUI.Grid container spacing={0}>
          <ProductCard
            logo={SkatterLogo}
            image={SkatterImage}
            imageCredit='© ORBRS'
            productColor='#ed3128'
            title='Skatter'
            subtitle='The most powerful Scattering Extension for SketchUp'
            to='/skatter'
            color={theme.palette.primary.contrastText}
          />
          <ProductCard
            logo={TransmutrLogo}
            image={TransmutrImage}
            imageCredit='© Darchviz'
            productColor='#2dcce6'
            title='Transmutr'
            subtitle='SketchUp importer on steroids'
            to='/transmutr'
            color={theme.palette.primary.contrastText}
          />
          <ProductCard
            logo={RoomboxLogo}
            image={RoomboxImage}
            productColor='#c34eff'
            title='RoomBox'
            subtitle='Ready-to-render interiors in one click'
            to='/roombox'
            color={theme.palette.primary.contrastText}
          />
          <ProductCard
            logo={BazaarLogo}
            image={BazaarImage}
            imageCredit='© Dieter Dewulf'
            productColor='#6bbc24'
            title='3D Bazaar'
            subtitle='High-quality render-ready SketchUp content at your fingertips'
            to='/3dbazaar'
            color={theme.palette.primary.contrastText}
          />
        </MUI.Grid>
      </MUI.Box>

      <MUI.Box pt={12} pb={12} color={theme.palette.primary.contrastText}>
        <MUI.Container maxWidth='lg'>
          <MUI.Typography
            variant='h6'
            align='center'
            color='inherit'
            className={classes.clientsTitle}
          >
            Trusted by industry leaders
          </MUI.Typography>

          <MUI.Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexWrap='wrap'
            mt={2}
          >
            <MUI.Box className={classes.client}>
              <img src={LegoLogo} alt='Lego logo' />
            </MUI.Box>
            <MUI.Box className={classes.client}>
              <img src={ValveLogo} alt='Valve logo' />
            </MUI.Box>
            <MUI.Box className={classes.client}>
              <img src={VolvoLogo} alt='Volvo logo' />
            </MUI.Box>
            <MUI.Box className={classes.client}>
              <img src={RocheBoboisLogo} alt='Roche Bobois logo' />
            </MUI.Box>
            <MUI.Box className={classes.client}>
              <img src={SmithGroupLogo} alt='SmithGroup logo' />
            </MUI.Box>
            <MUI.Box className={classes.client}>
              <img src={CallisonRTKLLogo} alt='CallisonRTKL logo' />
            </MUI.Box>
          </MUI.Box>
        </MUI.Container>
      </MUI.Box>
    </MUI.Box>
  );
}
